import { Button, Input, Space, Table, Tag, Modal, message, Typography } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from 'moment';
import 'moment/locale/zh-cn';
import AgentRate from "./AgentRate";
import Recharge from "./Recharge";
import { ExclamationCircleFilled } from '@ant-design/icons';
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
const { confirm } = Modal;

const AgentSetting = () => {
    const [userInfo, setUserInfo] = useState<any>(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isShowRate, setIsShowRate] = useState(false);
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [currentMember, setCurrentMember] = useState<any>(null);

    const columns: any[] = [{ title: '会员账号', dataIndex: 'account', fixed: 'left', width: 150 }
        , { title: '会员名称', dataIndex: 'name', fixed: 'left', width: 150 }
        , { title: '手机号', dataIndex: 'tel', width: 150 }
        , {
        title: '会员级别', dataIndex: 'memberType', width: 150
        , render: (text: any) => (
            <Tag color="green">{text}</Tag>
        )
    }
        , {
        title: '注册时间', dataIndex: 'createTime', width: 150
        , render: (text: any) => (
            moment(text).format('YYYY-MM-DD hh:mm:ss')
        )
    }
        , { title: '上级代理', dataIndex: 'superiorMember', width: 150 }
        , { title: '所属组织', dataIndex: 'organizationName', width: 150 }
        , {
        title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
        render(value: any, record: any, index: any) {
            var configs: IButtonConfig[] = [
                {
                    name: '绑定会员', onClick: () => {
                        onBind(record);
                    },
                    hidden: record.organizationName != ''
                },
                {
                    name: '解绑会员', onClick: () => {
                        onUnBind(record)
                    },
                    danger: true,
                    hidden: record.organizationName != userInfo?.organizationName
                },
                {
                    name: '设置费率', onClick: () => {
                        onUnBind(record)
                    },
                    hidden: record.organizationName != userInfo?.organizationName
                },
                {
                    name: '充值', onClick: () => {
                        onRecharge(record)
                    },
                    danger: true,
                    hidden: record.organizationName != userInfo?.organizationName
                },
            ];
            return EUtils.InitButtons(configs);
        }
    }];

    const [scrollY, setScrollY] = useState("")
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        setScrollY(EUtils.getTableScroll(82, ''))
    }, [dataSource])

    useEffect(() => {
        var userInfo = localStorage.getItem('EKnown.UserInfo');
        if (userInfo) {
            setUserInfo(JSON.parse(userInfo));
        }
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Agent.GetAgentList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }

    const onBind = (record: any) => {
        var params = { memberID: record.memberID };
        Server.Post(URL.Agent.BindAgent, params).then((response: any) => {
            if (response.isSuccess) {
                loadData();
                message.success('绑定成功')
            }
        });
    }

    const onUnBind = (record: any) => {
        confirm({
            title: '解除绑定',
            icon: <ExclamationCircleFilled />,
            content: '解除绑定会员[' + record.account + ']，解除绑定将清除费率设置信息，确认要解除绑定？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var params = { memberID: record.memberID };
                Server.Post(URL.Agent.UnBindAgent, params).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('解绑成功')
                    }
                });
            },
            onCancel() {
            },
        });
    }
    const onSetRate = (record: any) => {
        setCurrentMember({ memberID: record.memberID, account: record.account })
        setIsShowRate(true);
    }
    const onRecharge = (record: any) => {
        setCurrentMember({ memberID: record.memberID, account: record.account })
        setIsShowRecharge(true);
    }

    return <div>
        <div>
            <Input.Search allowClear style={{ width: '420px', marginRight: '10px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入公司编码、公司名称、联系人、电话、地址" />
            <span style={{ fontWeight: 'bold', color: 'red', margin: '5px' }}>Tips:默认（无条件）查询所有已绑定会员列表，输入条件精准查询未绑定的会员进行绑定</span>
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
            </div>
            <Table size="small" bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={6} align={'center'}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align={'center'}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
        <AgentRate isOpen={isShowRate} member={currentMember} setIsOpen={(value: any) => setIsShowRate(value)} />
        <Recharge isOpen={isShowRecharge} member={currentMember} setIsOpen={(value: any) => setIsShowRecharge(value)} />
    </div>
}
export default AgentSetting;