import { Button, Checkbox, Divider, message, Space, Table } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { ColumnType } from "antd/es/table";
import { Key, useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils from "../../../utils/EUtils";

const RoleFunctionItem = (props: any) => {
    const [dataSource, setDataSource] = useState<any>([])
    const [allKeys, setAllKeys] = useState<any>([])
    const [selectedRowKeys, setSelectRowKeys] = useState<any>([])
    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
    const [readOnly, setReadOnly] = useState(true);

    const columns: ColumnType<any>[] = [
        {
            title: <> <span>功能名称</span>
                <span style={{ float: 'right' }}><Checkbox
                    disabled={readOnly}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectRowKeys(allKeys);
                        } else {
                            setSelectRowKeys([]);
                        }
                    }}
                    checked={allKeys.length > 0 && allKeys.length == selectedRowKeys.length}
                    indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length != allKeys.length}
                    value={0}
                >全选</Checkbox></span>
            </>,
            dataIndex: 'name', key: 'remark',
            render(value, record, index) {
                return <>
                    <span>{value}</span>
                    <Space size={'small'} style={{ float: 'right' }}>
                        <Checkbox disabled={readOnly} onChange={(e) => {
                            onChange(e, record);
                        }}
                            checked={getChecked(record)}
                            indeterminate={getIndeterminate(record)} value={record.functionalityID}>全选</Checkbox>
                    </Space>
                    {!record.children && record.action ? <div>
                        <Divider style={{ margin: '12px', padding: '0' }} />
                        <Space wrap style={{ float: 'right' }}>
                            {record.action.map((btn: any) => {
                                return <Checkbox disabled={readOnly} onChange={(e) => {
                                    var values: any = [e.target.value, record];
                                    if (e.target.checked) {
                                        selectedRowKeys.push(e.target.value)
                                        setSelectRowKeys([...selectedRowKeys])
                                    } else {
                                        var filter = selectedRowKeys.filter((e: any) => !values.includes(e))
                                        setSelectRowKeys(filter)
                                    }
                                }}
                                    value={btn.functionalityID}
                                    checked={selectedRowKeys.includes(btn.functionalityID)} >{btn.name}</Checkbox>;
                            })}
                        </Space>
                    </div> : ''}
                </>;
            },
        },
    ]

    const onChange = (e: any, record: any) => {
        var values = [...record.actionIDs ?? []]
        if (e.target.checked) {
            values.map((value: any) => {
                selectedRowKeys.push(value)
            })
            setSelectRowKeys([...selectedRowKeys])
        } else {
            var filter = selectedRowKeys.filter((e: any) => !values.includes(e))
            setSelectRowKeys(filter)
        }
    }

    const getChecked = (record: any) => {
        if (record.actionIDs && record.actionIDs.length > 0) {
            var filter = selectedRowKeys.filter((item: any) => record.actionIDs.includes(item))
            if (filter && filter?.length == record.actionIDs?.length) {
                return true;
            }
        }
        return false;
    }

    const getIndeterminate = (record: any) => {
        var filter = selectedRowKeys.filter((key: any) => (record.actionIDs ?? []).includes(key))
        if (filter && filter?.length > 0 && filter?.length < record.actionIDs?.length)
            return true;
        return false;
    }

    useEffect(() => {
        if (props.roleID) {
            var request = {
                roleID: props.roleID
            };
            Server.Post(URL.Role.GetRoleFunctionalityList, request).then((response: any) => {
                if (response.isSuccess) {
                    setDataSource(response.data);
                    setAllKeys(response.allKeys ?? []);
                    setSelectRowKeys(response.selectedKeys ?? []);
                }
            });
        }
    }, [props.roleID])


    const [scrollY, setScrollY] = useState("")
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        setScrollY(EUtils.getTableScroll(25, ''))
    }, [props.roleID])

    const onExpandedRowsChange = (expandedRows: any) => {
        setExpandedRowKeys(expandedRows);
    }

    const onOK = () => {
        var request = {
            tenantAppID: props.tenantAppID,
            roleID: props.roleID,
            functionalityIDs: selectedRowKeys,
        };
        Server.Post(URL.Role.SaveRoleFunctionality, request).then((response: any) => {
            if (response.isSuccess) {
                setReadOnly(true)
                message.success('保存成功');
            } else {
                message.warning(response.returnMsg);
            }
        })
    }

    return <div style={{ height: '100%' }}>
        <div>
            <Space size={'small'} style={{ float: 'right', padding: '5px' }}>
                {readOnly ? <Button type={'primary'} onClick={() => setReadOnly(false)}>编辑</Button> : <></>}
                {readOnly ? <></> : <Button type={'primary'} onClick={onOK}>保存</Button>}
                {readOnly ? <></> : <Button onClick={() => setReadOnly(true)}>取消</Button>}
            </Space>
        </div>
        <Table size="small" bordered
            scroll={{ x: 'max-content', y: scrollY }}
            rowKey={'functionalityID'}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            expandedRowKeys={[...expandedRowKeys]}
            onExpandedRowsChange={onExpandedRowsChange}
        />
    </div>
}
export default RoleFunctionItem;