
import {
    AppstoreOutlined,
    MessageOutlined,
    HomeOutlined,
    ClusterOutlined,
    BankOutlined,
    ControlOutlined,
    FileDoneOutlined,
    NodeIndexOutlined,
    InteractionOutlined,
    SolutionOutlined,
    QuestionOutlined,
    GroupOutlined,
    UngroupOutlined,
    HddOutlined,
    AreaChartOutlined,
    AccountBookOutlined,
    ScheduleOutlined,
    TeamOutlined,
    CarOutlined,
    UserOutlined,
    CommentOutlined,
    CustomerServiceOutlined,
    LogoutOutlined,
    DesktopOutlined,
    SettingOutlined,
    DatabaseOutlined,
    ExceptionOutlined,
    QuestionCircleOutlined,
    ProfileOutlined,
    FileProtectOutlined,
    AuditOutlined,
    CloudUploadOutlined
} from '@ant-design/icons';

const Get = (name: string) => {
    var icon = <AppstoreOutlined />
    switch (name) {
        case 'AuditOutlined':
            icon = < AuditOutlined />;
            break;
        case 'CloudUploadOutlined':
            icon = < CloudUploadOutlined />;
            break;
        case 'FileProtectOutlined':
            icon = < FileProtectOutlined />;
            break;
        case 'ProfileOutlined':
            icon = < ProfileOutlined />;
            break;
        case 'MessageOutlined':
            icon = < MessageOutlined />;
            break;
        case 'HomeOutlined':
            icon = < HomeOutlined />;
            break;
        case 'ClusterOutlined':
            icon = < ClusterOutlined />;
            break;
        case 'DesktopOutlined':
            icon = < DesktopOutlined />;
            break;
        case 'SettingOutlined':
            icon = < SettingOutlined />;
            break;
        case 'DatabaseOutlined':
            icon = < DatabaseOutlined />;
            break;
        case 'ExceptionOutlined':
            icon = < ExceptionOutlined />;
            break;
        case 'BankOutlined':
            icon = < BankOutlined />;
            break;
        case 'ExceptionOutlined':
            icon = < ExceptionOutlined />;
            break;
        case 'ControlOutlined':
            icon = < ControlOutlined />;
            break;
        case 'FileDoneOutlined':
            icon = <FileDoneOutlined />
            break;
        case 'GroupOutlined':
            icon = <GroupOutlined />
            break;
        case 'UngroupOutlined':
            icon = <UngroupOutlined />
            break;
        case 'HddOutlined':
            icon = <HddOutlined />
            break;
        case 'AreaChartOutlined':
            icon = <AreaChartOutlined />;
            break;
        case 'CustomerServiceOutlined':
            icon = <CustomerServiceOutlined />;
            break;
        case 'AccountBookOutlined':
            icon = <AccountBookOutlined />;
            break;
        case 'ScheduleOutlined':
            icon = <ScheduleOutlined />;
            break;
        case 'NodeIndexOutlined':
            icon = <NodeIndexOutlined />;
            break;
        case 'InteractionOutlined':
            icon = <InteractionOutlined />;
            break;
        case 'SolutionOutlined':
            icon = <SolutionOutlined />;
            break;
        case 'TeamOutlined':
            icon = <TeamOutlined />;
            break;
        case 'CarOutlined':
            icon = <CarOutlined />;
            break;
        case 'QuestionOutlined':
            icon = <QuestionOutlined />;
            break;
        case 'UserOutlined':
            icon = <UserOutlined />;
            break;
        case 'QuestionCircleOutlined':
            icon = <QuestionCircleOutlined />;
            break;
        default:
            icon = < AppstoreOutlined />;
            break;
    }
    return icon
}

export default {
    Get
}