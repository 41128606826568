import { Button, Input, Space, Table, Modal, message, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from "moment";
import { ExclamationCircleFilled } from '@ant-design/icons';
import AccountRecharge from "./AccountRecharge";
import RechargeRecord from "./RechargeRecord";
import ConsumptionRecord from "./ConsumptionRecord";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";

const { confirm } = Modal;

const OrganizationAccount = () => {
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [isShowRechargeRecord, setIsShowRechargeRecord] = useState(false);
    const [isShowConsumptionRecord, setIsShowConsumptionRecord] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);

    const columns: any[] = [
        { title: '组织名称', dataIndex: 'organizationName', key: 'organizationName', width: 350, ellipsis: true },
        {
            title: '账户状态', dataIndex: 'state', width: 80, ellipsis: true,
            render: (text: any) => (
                text ? <Tag color="green">已开通</Tag> : <Tag color="red">未开通</Tag>
            ),
        },
        {
            title: '账户余额（元）', dataIndex: 'balance', width: 200, ellipsis: true,
            render: (text: any, record: any) => (
                record.state ? text : ''
            )
        },
        {
            title: '开户人', dataIndex: 'createBy', width: 150, ellipsis: true,
            render: (text: any, record: any) => (
                record.state ? text : ''
            )
        },
        {
            title: '开户时间', dataIndex: 'createTime', width: 150, ellipsis: true,
            render: (text: any, record: any) => (
                record.state ? moment(text).format('YYYY-MM-DD hh:mm:ss') : ''
            )
        },
        {
            title: '操作', align: 'center', width: 80, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '开户', onClick: () => {
                            showCreateConfirm(record);
                        },
                        hidden: record.state
                    },
                    {
                        name: '充值', onClick: () => {
                            setCurrentRecord({
                                accountID: record.accountID,
                                organizationID: record.organizationID,
                                organizationName: record.organizationName
                            })
                            setIsShowRecharge(true);
                        },
                        danger: true,
                        hidden: !record.state
                    },
                    {
                        name: '充值记录', onClick: () => {
                            setCurrentRecord({
                                accountID: record.accountID,
                                organizationID: record.organizationID,
                                organizationName: record.organizationName
                            })
                            setIsShowRechargeRecord(true);
                        },
                        hidden: !record.state
                    },
                    {
                        name: '消费记录', onClick: () => {
                            setCurrentRecord({
                                accountID: record.accountID,
                                organizationID: record.organizationID,
                                organizationName: record.organizationName
                            })
                            setIsShowConsumptionRecord(true);
                        },
                        hidden: !record.state,
                        danger: true
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        var params = {
            searchValue: searchValue
        };
        Server.Post(URL.Account.GetAccountList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                if (response.data && response.data.length > 0) {
                    setExpandedRowKeys([response.data[0].organizationName]);
                }
            }
        });
    }

    const showCreateConfirm = (record: any) => {
        confirm({
            title: '组织开户',
            icon: <ExclamationCircleFilled />,
            content: '组织编码[' + record.organizationName + ']，确认要开户',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { organizationID: record.organizationID }
                Server.Post(URL.Account.CreateAccount, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('开户成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    return <div>
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                </Space>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }}
                rowKey={'organizationName'}
                expandedRowKeys={expandedRowKeys}
                onExpand={(expandedKeys: any, record: any) => { setExpandedRowKeys(expandedKeys ? [record.organizationName] : []) }}
                columns={columns} dataSource={dataSource} pagination={false}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={4} >
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} ></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
        <AccountRecharge isOpen={isShowRecharge} {...currentRecord}
            setIsOpen={(value: any) => setIsShowRecharge(value)} loadData={loadData} />
        <RechargeRecord isOpen={isShowRechargeRecord} {...currentRecord}
            setIsOpen={(value: any) => setIsShowRechargeRecord(value)} />
        <ConsumptionRecord isOpen={isShowConsumptionRecord} {...currentRecord}
            setIsOpen={(value: any) => setIsShowConsumptionRecord(value)} />
    </div>
}
export default OrganizationAccount;