import { Dropdown, MenuProps, Space } from "antd";

const PageURL = {
    CompanyEdit: '/masterdata/companyedit',
}

//关闭窗口，关闭不掉的就是返回
const closeWindow = (current: any) => {
    window.opener = null;
    window.open('', '_self');
    window.close();
    current.props.history.goBack()
}

//打开窗口，当前页面跳转打开新窗口
const openHistory = (current: any, url: string, params: any) => {
    let targetUrl = url;
    let paramsUrl = '';
    for (var key in params) {
        if (paramsUrl === '')
            paramsUrl = key + '=' + params[key];
        else
            paramsUrl = paramsUrl + '&&' + key + '=' + params[key];
    }
    if (paramsUrl !== '')
        targetUrl = targetUrl + '?' + encodeURI(paramsUrl);
    current.props.history.replace(targetUrl) //此处的url是全路径
}

//打开窗口，新的页签打开新窗口
const openWindow = (url: string, params: any) => {
    let targetUrl = document.location.origin + url;
    let paramsUrl = '';
    for (var key in params) {
        if (paramsUrl === '')
            paramsUrl = key + '=' + params[key];
        else
            paramsUrl = paramsUrl + '&&' + key + '=' + params[key];
    }

    if (paramsUrl !== '')
        targetUrl = targetUrl + '?' + encodeURI(paramsUrl);

    window.open(targetUrl) //此处的url是全路径
}
const getUrlParams = () => {
    var afterUrl = decodeURI(window.location.search.substring(1));
    var params: any = {};
    afterUrl.replace('&&', '&').split('&').map((item) => {
        var key = '';
        var value = null;
        item.split('=').map((param: any) => {
            if (key === '')
                key = param;
            else {
                value = param;
            }
        })
        if (value && value !== 'null')
            params[key] = value;
    })
    return params;
}

const getUrlParam = (key: string) => {
    var params = getUrlParams();
    return params[key];
}

const getTableScroll = (extraHeight: number, id: string) => {
    if (typeof extraHeight == "undefined" || extraHeight == 0) {
        //  默认底部分页64 + 边距10
        extraHeight = 74
    }
    let tHeader = null
    if (id) {
        var table = document.getElementById(id);
        if (table) {
            tHeader = table.getElementsByClassName("ant-table-thead")[0];
        }
    } else {
        tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
        tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    //窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
    return height
}


export interface IButtonConfig {
    key?: string,
    name: string,
    danger?: boolean,
    type?: string,
    onClick?: () => void,
    hidden?: boolean,
    disabled?: boolean
}

const InitButtons = (configs: IButtonConfig[], showNum: number = 3) => {
    var buttons: any = [];
    let items: MenuProps['items'] = [];
    (configs ?? []).map((btn: any, index: number) => {
        if (btn.hidden) {
            return;
        }
        if (buttons.length < (showNum - 1)) {
            buttons.push(<a onClick={btn.disabled ? () => { } : btn.onClick} style={{ color: btn.disabled ? '#D9DADC' : (btn.danger ? 'red' : '') }}>{btn.name}</a>)
        } else {
            var isLast = (configs.length - 1 == index)
                || configs.filter((item: any, idx: number) => idx >= index && (item.hidden ?? false) == false).length == 1;
            if (items?.length == 0 && isLast) {
                buttons.push(<a onClick={btn.disabled ? () => { } : btn.onClick} style={{ color: btn.disabled ? '#D9DADC' : (btn.danger ? 'red' : '') }}>{btn.name}</a>)
            } else {
                items?.push({
                    key: btn.key ?? index, disabled: btn.disabled ?? false, danger: (btn.danger ?? false), label: <a onClick={btn.onClick} >{btn.name}</a>
                })
            }
        }
    });
    return <Space>
        {buttons}
        {items.length > 0 ? <Dropdown menu={{ items }}>
            <a>更多</a>
        </Dropdown> : <></>}
    </Space>
}

export default {
    PageURL,
    openWindow,
    closeWindow,
    getUrlParams,
    getUrlParam,
    getTableScroll,
    InitButtons
};