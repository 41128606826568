import { Button, Input, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";

const { Search } = Input;

const UserAuthBUSelect = (props: any) => {
    const [dataSource, setDataSource] = useState<any>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

    const columns: ColumnsType<any> = [
        { title: '组织名称', dataIndex: 'organizationName', key: 'organizationName', align: 'left', ellipsis: true },
    ]

    useEffect(() => {
        if (props.visible) {
            loadData();
            setSelectedRowKeys([]);
        }
    }, [props.visible])

    const onOK = () => {
        var request = {
            userID: props.userID,
            organizationIDs: selectedRowKeys
        };
        Server.Post(URL.User.AddUserOrganization, request).then((response: any) => {
            if (response.isSuccess) {
                props.setVisible(false);
                props.reLoad();
            }
        })
    }

    const onCancel = () => {
        props.setVisible(false);
    }

    const loadData = () => {
        Server.Post(URL.Organization.GetOrganizationList, {}).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        })
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return <Modal
        maskClosable={false}
        title={'添加兼职组织'}
        width={600}
        visible={props.visible}
        onCancel={onCancel}
        footer={<>
            <Space>
                <Button type="primary" onClick={onOK}>确定</Button>
                <Button onClick={onCancel}>取消</Button>
            </Space>
        </>}>
        <div style={{ paddingBottom: '5px' }}>
            <Search placeholder="请输入组织编码、名称检索" onSearch={loadData} style={{ width: '450px' }} />
        </div>
        <div style={{ maxHeight: '350px', overflow: 'auto' }}>
            <Table
                rowSelection={{ ...rowSelection, checkStrictly: true }}
                rowKey={'organizationID'}
                columns={columns} size={'small'}
                bordered dataSource={dataSource}
                pagination={false} />
        </div>
    </Modal>
}
export default UserAuthBUSelect;