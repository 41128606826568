import { Button, Input, message, Space, Table, Modal, Tag, Dropdown, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import ProductEdit from "./ApplyProductEdit";
import moment from "moment";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";
const { confirm } = Modal;

const Product = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});
    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    const columns: any[] = [
        {
            key: 'applyProductName', title: '产品名称', dataIndex: 'applyProductName'
            , fixed: 'left', ellipsis: true, width: 220,
            render: (text: any, record: any) => <a onClick={() => {
                setEditRecord({
                    viewMode: 1,
                    applyProductID: record.applyProductID
                });
                setIsOpen(true)
            }}>{text}</a>
        }
        , {
            title: '启用状态', dataIndex: 'isActive', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
            }
        }
        , { key: 'companyName', title: '保险公司', dataIndex: 'companyName', width: 120, ellipsis: true }
        , { key: 'singleLimit', title: '单次限额', dataIndex: 'singleLimit', width: 100, ellipsis: true }
        , { key: 'deductibleAmountDesc', title: '免赔额', dataIndex: 'deductibleAmountDesc', width: 120, ellipsis: true }
        , { key: 'insureArea', title: '承保区域', dataIndex: 'insureArea', width: 200, ellipsis: true }
        , { key: 'applyEmail', title: '申报邮箱', dataIndex: 'applyEmail', width: 200, ellipsis: true }
        , { key: 'ccEmail', title: '抄送邮箱', dataIndex: 'ccEmail', width: 200, ellipsis: true }
        , { key: 'remark', title: '备注', dataIndex: 'remark', width: 150, ellipsis: true }
        , {
            title: '操作', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '编辑', onClick: () => {
                            setEditRecord({
                                viewMode: 1,
                                productID: record.productID
                            });
                            setIsOpen(true);
                        },
                    },
                    {
                        name: '删除', onClick: () => { showDeleteConfirm(record); }, danger: true,
                    },
                    { name: '启用', onClick: () => { showActiveConfirm(record); }, hidden: record.isActive },
                    { name: '禁用', onClick: () => { showActiveConfirm(record); }, hidden: !record.isActive, danger: true },
                ];
                return EUtils.InitButtons(configs);
            }
        }];

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除产品',
            icon: <ExclamationCircleFilled />,
            content: '产品名称[' + record.applyProductName + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { applyProductID: record.applyProductID }
                Server.Post(URL.ApplyProduct.DeleteApplyProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };
    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用申报产品' : '启用申报产品',
            icon: <ExclamationCircleFilled />,
            content: '申报产品[' + record.applyProductName + ']，确认要'
                + (record.isActive ? '禁用' : '启用')
                + (record.isActive ? '。禁用申报产品同时将下线已上线的申报产品,请慎重！' : ''),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    applyProductID: record.applyProductID,
                    isActive: !record.isActive
                }
                Server.Post(URL.ApplyProduct.SetActiveApplyProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.ApplyProduct.GetApplyProductList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }
    const refESearch = useRef<any>(null);
    const searchConfig: ISearchConfig[] = [
        { filedID: 'ApplyProductName', filedName: '产品名称' },
        { filedID: 'CompanyName', filedName: '保险公司' },
        { filedID: 'DeductibleAmountDesc', filedName: '免赔额' },
        { filedID: 'InsureArea', filedName: '承保区域' },
        { filedID: 'ApplyEmail', filedName: '申报邮箱' },
        { filedID: 'CcEmail', filedName: '抄送邮箱' },
        { filedID: 'Remark', filedName: '备注' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', filedID: 'IsActive', title: '启用状态', data: [{ value: false, label: '禁用' }, { value: true, label: '启用' }] },
    ];

    return <div>
        <ESearch searchConfig={searchConfig} moreConfig={moreConfig} onLoad={loadData} resize={resize} ref={refESearch} />
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            vendorID: null
                        });
                        setIsOpen(true)
                    }}>新增</Button>
                </Space>
            </div>
            <Table size="small" bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={8} align={'center'}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align={'center'}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
        <ProductEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
    </div >
}
export default Product;