import { Button, Input, Space, Table, Modal, message, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import CustomerEdit from "./CustomerEdit";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";

const { confirm } = Modal;

const Customer = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.Customer.GetCustomerList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除客户资料',
            icon: <ExclamationCircleFilled />,
            content: '客户资料编码[' + record.code + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { customerID: record.customerID }
                Server.Post(URL.Customer.DeleteCustomer, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const columns: any[] = [{
        key: 'code', title: '公司代码', dataIndex: 'code', fixed: 'left', width: 150,
        ellipsis: true,
        render: (text: any, record: any) => <a onClick={() => {
            setEditRecord({
                viewMode: 1,
                customerID: record.customerID
            });
            setIsOpen(true)
        }}>{text}</a>
    }
        , { title: '公司名称', dataIndex: 'name', ellipsis: true, width: 150 }
        , {
        title: '启用状态', dataIndex: 'isActive', ellipsis: true, width: 100,
        render: (text: any) => {
            return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
        }
    },
    { title: '联系人', dataIndex: 'contact', ellipsis: true, width: 150 },
    { title: '电话', dataIndex: 'tel', ellipsis: true, width: 150 },
    { title: '地址', dataIndex: 'address', ellipsis: true, width: 150 },
    { title: '备注', dataIndex: 'remark', ellipsis: true, width: 150 },
    {
        title: '操作', align: 'center', width: 80, fixed: 'right', ellipsis: true,
        render(value: any, record: any, index: any) {
            var configs: IButtonConfig[] = [
                {
                    name: '编辑', onClick: () => {
                        setEditRecord({
                            viewMode: 1,
                            customerID: record.customerID
                        });
                        setIsOpen(true)
                    }
                },
                { name: '删除', onClick: () => { showDeleteConfirm(record); }, danger: true },
                { name: '启用', onClick: () => { showActiveConfirm(record); }, hidden: record.isActive },
                { name: '禁用', onClick: () => { showActiveConfirm(record); }, hidden: !record.isActive, danger: true },
            ];
            return EUtils.InitButtons(configs);
        }
    }];
    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用客户资料' : '启用客户资料',
            icon: <ExclamationCircleFilled />,
            content: '客户资料[' + record.name + ']，确认要' + (record.isActive ? '禁用' : '启用'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    customerID: record.customerID,
                    isActive: !record.isActive
                }
                Server.Post(URL.Customer.SetActiveCustomer, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const refESearch = useRef<any>(null);
    const searchConfig: ISearchConfig[] = [
        { filedID: 'Code', filedName: '公司编码' },
        { filedID: 'Name', filedName: '公司名称' },
        { filedID: 'Contact', filedName: '联系人' },
        { filedID: 'Tel', filedName: '电话' },
        { filedID: 'Address', filedName: '地址' },
        { filedID: 'Remark', filedName: '备注' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', filedID: 'IsActive', title: '启用状态', data: [{ value: false, label: '禁用' }, { value: true, label: '启用' }] }
    ];

    return <div>
        <ESearch searchConfig={searchConfig} moreConfig={moreConfig} onLoad={loadData} resize={resize} ref={refESearch} />
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            customerID: null
                        });
                        setIsOpen(true)
                    }}>新增</Button>
                </Space>
            </div>
            <Table size="small"
                bordered
                columns={columns}
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={6} align={'center'}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align={'center'}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
        <CustomerEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
    </div>
}
export default Customer;