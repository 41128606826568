import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom'
import Main from './views/main/Main';
import 'antd/dist/antd.css';
import './styles/main.css';
import './styles/loading.css';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import Login from './views/login/Login';
import Error404 from './views/error/Error404';

console.log(process.env.NODE_ENV);
console.log(process.env.REACT_APP_ENV);
console.log(process.env.REACT_APP_URL);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

ConfigProvider.config({
  theme: {
      primaryColor: '#00A2E9',
      errorColor: '#ff4d4f',
      warningColor: '#faad14',
      successColor: '#52c41a',
      infoColor: '#00A2E9',
  }
});
root.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Main />} />
        <Route path='/home/*' element={<Main />} />
        <Route path='/system/*' element={<Main />} />
        <Route path='/masterdata/*' element={<Main />} />
        <Route path='/order/*' element={<Main />} />
        <Route path='/singleonline/*' element={<Main />} />
        <Route path='/applyonline/*' element={<Main />} />
        <Route path='/yearonline/*' element={<Main />} />
        <Route path='/inquiry/*' element={<Main />} />
        <Route path='/attachment/*' element={<Main />} />
        <Route path='/*' element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  </ConfigProvider >
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
