import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import CompanyEdit from "../../masterdata/company/CompanyEdit";

const FileEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;

    useEffect(() => {
        if (rest.isOpen) {
            formRef.current?.setFieldsValue({
                fileID: props.fileID,
                fileName: props.fileName,
            });
        }
    }, [rest.isOpen])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        rest.setIsOpen(false);
    }

    return <Modal title={"附件重命名"} width={500} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'文件ID'} name={'fileID'} initialValue={props.fileID}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'附件名称'} name={'fileName'} initialValue={props.fileName} rules={[{ required: true, message: '请输入角色名称' }]}>
                <Input />
            </Form.Item>
        </Form>
    </Modal >
};
export default FileEdit;
