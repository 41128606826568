import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import axios from "axios";

const ExportSearch = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [startMouth, setStartMouth] = useState<any>(moment().format('M'));
    const [endMouth, setEndMouth] = useState<any>(moment().format('M'));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (rest.isOpen) {
            formRef.current?.setFieldValue('startMouth', moment().format('M'))
            formRef.current?.setFieldValue('endMouth', moment().format('M'))
        }
    }, [rest.isOpen])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        setIsLoading(true);
        formRef.current?.validateFields().then((values => {
            var reuqest = {
                ...values,
            };
            axios.post(URL.InquiryOrder.ExportInquiryOrder, reuqest, {
                'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
            }).then(function (response) {
                console.log(response);
                const blob = new Blob([response.data]);
                var fileName = values.startMonth + '月至' + values.endMonth + '月询价记录数据.xlsx';
                if (values.startMonth == values.endMonth) {
                    fileName = values.startMonth + '月询价记录数据.xlsx';
                }
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = global.URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                global.URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);

                message.success('导出成功');
                rest.setIsOpen(false);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
            });
        })).catch((info: any) => {
            console.log(info);
        });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal title={"导出询价数据"} width={400} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 10 }}
        footer={
            <Space>
                <Button onClick={handleSave} loading={isLoading} type='primary'>{isLoading ? '导出中' : '立即导出'}</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 6 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 18 }
            }}>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'开始月份'} name={'startMonth'}
                        initialValue={startMouth} rules={[{ message: '请选择询价月份' }]}>
                        <Select
                            onChange={(value) => {
                                setStartMouth(value);
                                if (parseInt(endMouth) < parseInt(value)) {
                                    setEndMouth(value);
                                    formRef.current?.setFieldValue('endMouth', value)
                                }
                            }}
                            value={startMouth}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={[
                                { value: '1', label: '1月' },
                                { value: '2', label: '2月' },
                                { value: '3', label: '3月' },
                                { value: '4', label: '4月' },
                                { value: '5', label: '5月' },
                                { value: '6', label: '6月' },
                                { value: '7', label: '7月' },
                                { value: '8', label: '8月' },
                                { value: '9', label: '9月' },
                                { value: '10', label: '10月' },
                                { value: '11', label: '11月' },
                                { value: '12', label: '12月' },]} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'截至月份'} name={'endMonth'}
                        initialValue={endMouth} rules={[{ message: '请选择询价月份' }]}>
                        <Select
                            onChange={(value) => {
                                setEndMouth(value);
                            }}
                            value={endMouth}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={[
                                { value: '1', disabled: 1 < startMouth, label: '1月' },
                                { value: '2', disabled: 2 < startMouth, label: '2月' },
                                { value: '3', disabled: 3 < startMouth, label: '3月' },
                                { value: '4', disabled: 4 < startMouth, label: '4月' },
                                { value: '5', disabled: 5 < startMouth, label: '5月' },
                                { value: '6', disabled: 6 < startMouth, label: '6月' },
                                { value: '7', disabled: 7 < startMouth, label: '7月' },
                                { value: '8', disabled: 8 < startMouth, label: '8月' },
                                { value: '9', disabled: 9 < startMouth, label: '9月' },
                                { value: '10', disabled: 10 < startMouth, label: '10月' },
                                { value: '11', disabled: 11 < startMouth, label: '11月' },
                                { value: '12', disabled: 12 < startMouth, label: '12月' },]} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal >
};
export default ExportSearch;
