import { Button, Form, FormInstance, InputNumber, message, Modal, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const AccountRecharge = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [account, setAccount] = useState<any>({});

    useEffect(() => {
        if (rest.isOpen) {
            var params = {
                accountID: rest.accountID
            }
            Server.Post(URL.Account.GetAccount, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setAccount(response);
                }
            })
        }
    }, [rest.isOpen])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }
    const handleOk = () => {
        formRef.current?.validateFields().then((values: any) => {
            var params = {
                accountID: account.accountID,
                amount: values.amount
            };
            Server.Post(URL.Account.RechargeAccount, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('充值成功');
                    formRef.current?.resetFields();
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })
    }
    return <Modal title={"组织充值"} width={400} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}  
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button size='middle' onClick={handleCancel}>关闭</Button>
                <Button type='primary' onClick={handleOk} danger size='middle'>立即充值</Button>
            </Space>
        }>
        <Form ref={formRef} size='middle'
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <FormItem style={{ marginBottom: '5px' }} label={'组织余额'}>
                <span style={{ width: '100%' }}>{account.balance ?? 0}(元)</span>
            </FormItem>
            <FormItem style={{ marginBottom: '5px' }} label={'充值组织'}>
                <span style={{ width: '100%' }}>{account.organizationName}</span>
            </FormItem>
            <FormItem style={{ marginBottom: '5px' }} label={'充值金额'} initialValue={0} name={'amount'} >
                <InputNumber style={{ width: '100%' }} min={0} addonAfter="(元)" status="error"
                    step="0.01" />
            </FormItem>
        </Form>
    </Modal>
}
export default AccountRecharge;