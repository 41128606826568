import { Button, Input, Space, Tag, Tooltip } from "antd";
import { forwardRef, ReactElement, ReactNode, useEffect, useImperativeHandle, useState } from "react";
import ESearchItem from "./esearchitem/ESearchItem";
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ESearchDateTime from "./dateTime/ESearchDateTime";
import ESearchSelect from "./select/ESearchSelect";
const { Search } = Input;

export interface ISearchConfig {
    filedID: string,
    filedName?: string
}
export interface IMoreConfig {
    filedID: string,
    title: string,
    type: 'dateTime' | 'select' | 'number',
    data?: any[],
    dataUrl?: string,
    request?: any
}

export interface IESearch {
    showBU?: boolean,
    showProject?: boolean,
    showWarehouse?: boolean,
    defaultMore?: boolean,
    searchConfig?: ISearchConfig[],
    moreConfig?: IMoreConfig[],
    resize?: () => void,
    onLoad?: () => void,
    children?: React.ReactNode
    extra?: React.ReactNode
}

const ESearch = forwardRef((props: IESearch, ref: any) => {
    const [more, setMore] = useState(props.defaultMore ?? false);
    const [showData, setShowData] = useState<any>({});
    const [showDataHoveredKey, setShowDataHoveredKey] = useState<any>(null);
    const [filters, setFilters] = useState<any[] | null>([]);
    const [searchValue, setSearchValue] = useState<any>(null);

    const [selectedInfo, setSelectedInfo] = useState<any>({});

    useImperativeHandle(ref, () => ({
        getOptions
    }));

    const getOptions = () => {
        return {
            selectedInfo: selectedInfo,
            filters: filters
        };
    }

    useEffect(() => {
        if (props?.resize) {
            props?.resize();
        }
    }, [more])

    useEffect(() => {
        createFilters(searchValue);
    }, [searchValue, selectedInfo, showData])

    const removeShowData = (key: any) => {
        let newselectedInfo: any = {};
        (Object.keys(selectedInfo)).map((itemkey: any) => {
            if (itemkey != key)
                newselectedInfo[itemkey] = selectedInfo[itemkey];
        });
        setSelectedInfo(newselectedInfo);
        console.log(newselectedInfo);

        let newShowData: any = {};
        (Object.keys(showData)).map((itemkey: any) => {
            if (itemkey != key)
                newShowData[itemkey] = showData[itemkey];
        });
        setShowData(newShowData);
        console.log(newShowData);
        setTimeout(() => {
            // 这里是你想要延迟n秒执行的代码
            onSearch();
        }, 10);
    }

    const onSearch = () => {
        setShowDataHoveredKey(null);
        setMore(false);
        if (props?.resize) {
            props?.resize();
        }
        if (props?.onLoad) {
            props?.onLoad();
        }
    }

    const onClear = () => {
        setSelectedInfo({});
        setShowData({});
        setTimeout(() => {
            // 这里是你想要延迟一秒执行的代码
            onSearch();
        }, 100);
    }

    const getPlaceholder = () => {
        var value = '输入检索条件';
        if (props.searchConfig && props.searchConfig.length > 0) {
            value = '输入';
            (props.searchConfig ?? []).map((item: any, index: number) => {
                if (index == 0) {
                    value += item.filedName
                } else {
                    value += '、' + item.filedName
                }
            });
            value += "检索";
        }
        return value;
    }

    const createFilters = (value: any) => {
        var filters: any = [];
        if (value && value != '') {
            if (props.searchConfig) {
                var filter = {
                    type: 'or',
                    condition: []
                };
                var conditionData: any = [];
                (props.searchConfig ?? []).map((item: any) => {
                    conditionData.push({
                        category: 'search',
                        field: item.filedID,
                        operatorType: 'like',
                        value: value.toString()
                    });
                });
                filter.condition = conditionData

                filters.push(filter);
            }
        }

        if (props.moreConfig) {
            (props.moreConfig ?? []).map((item: any) => {
                if (selectedInfo.hasOwnProperty(item.filedID)
                    && selectedInfo[item.filedID]) {
                    var filter = null;
                    switch (item.type) {
                        case 'dateTime':
                            filter = {
                                type: 'and',
                                condition: []
                            };
                            var conditionData: any = [];
                            if (selectedInfo[item.filedID][0]) {
                                conditionData.push({
                                    category: 'search',
                                    field: item.filedID,
                                    operatorType: 'ge',
                                    value: selectedInfo[item.filedID][0].toString()
                                });
                            }
                            if (selectedInfo[item.filedID][1]) {
                                conditionData.push({
                                    category: 'search',
                                    field: item.filedID,
                                    operatorType: 'le',
                                    value: selectedInfo[item.filedID][1].toString()
                                });
                            }
                            if (conditionData.length > 0) {
                                filter.condition = conditionData
                            }
                            break;
                        case 'select':
                            filter = {
                                type: 'or',
                                condition: []
                            };
                            if (selectedInfo[item.filedID].length > 0) {
                                var conditionData: any = [];
                                (selectedInfo[item.filedID]).map((value: any) => {
                                    conditionData.push({
                                        category: 'search',
                                        field: item.filedID,
                                        operatorType: 'eq',
                                        value: value.toString()
                                    });
                                })
                                filter.condition = conditionData
                            }
                            break;
                    }
                    if (filter) {
                        filters.push(filter);
                    }
                }
            });
        }

        setFilters(filters);
    }

    return <div >
        <div style={{ paddingBottom: '5px' }}>
            <Space size={'small'}>
                {props.children}
                {props.searchConfig ?
                    (
                        !more ? <Search placeholder={getPlaceholder()}
                            style={{ width: '420px', marginRight: '10px' }}
                            value={searchValue}
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                            onSearch={(value: any) => {
                                onSearch()
                            }} /> : <Input placeholder={getPlaceholder()}
                                style={{ width: '420px', marginRight: '10px' }}
                                value={searchValue}
                                onChange={(e: any) => {
                                    setSearchValue(e.target.value)
                                }} />
                    ) : <></>}
                {props.moreConfig ?
                    <span onClick={() => { setMore(!more) }}>{more ? '收起筛选' : '展开筛选'}{more ? <CaretUpOutlined /> : < CaretDownOutlined />}</span>
                    : ''}
            </Space>
            {props.extra ? <Space style={{ float: 'right' }}>
                {props.extra}
            </Space> : <></>}
        </div>
        {!more && Object.keys(showData).length > 0 ? <Space size={0} wrap>
            {(Object.keys(showData)).map((key: any) => {
                return <Tooltip title={''}>
                    <Tag key={key} style={{
                        maxWidth: '100%', overflow: 'hidden'
                        , border: showDataHoveredKey == key ? '1px solid red' : ''
                    }}
                        onMouseOver={() => setShowDataHoveredKey(key)}
                        onMouseLeave={() => setShowDataHoveredKey(null)}
                        closable
                        onClose={() => { removeShowData(key) }}>{showData[key] ?? ''}</Tag>
                </Tooltip>
            })}
        </Space> : <></>
        }
        <div style={{ display: more ? 'block' : 'none', border: '1px solid #d9d9d9', marginBottom: '5px' }}>
            <div>
                {(props.moreConfig ?? []).map((item: any) => {
                    switch (item.type) {
                        case 'dateTime':
                            return <ESearchDateTime {...item}
                                selectedInfo={selectedInfo}
                                onChange={(filedID, values, names) => {
                                    var newselectedInfo = {
                                        ...selectedInfo
                                    };
                                    if (values && names) {
                                        newselectedInfo[filedID] = values;
                                        showData[filedID] = names
                                    } else {
                                        if (newselectedInfo.hasOwnProperty(filedID))
                                            delete newselectedInfo[filedID];

                                        if (showData.hasOwnProperty(filedID)) {
                                            let newShowData = { ...showData };
                                            delete newShowData[filedID];
                                            setShowData(newShowData);
                                        }
                                    }
                                    setSelectedInfo(newselectedInfo);
                                }} />
                        case 'select':
                            return <ESearchSelect {...item}
                                selectedInfo={selectedInfo}
                                onChange={(filedID, values, names) => {
                                    var newselectedInfo = {
                                        ...selectedInfo
                                    };
                                    if (values && names) {
                                        newselectedInfo[filedID] = values;

                                        showData[filedID] = (item.title ?? '') + '：' + names
                                    } else {
                                        if (newselectedInfo.hasOwnProperty(filedID))
                                            delete newselectedInfo[filedID];
                                        if (showData.hasOwnProperty(filedID)) {
                                            let newShowData = { ...showData };
                                            delete newShowData[filedID];
                                            setShowData(newShowData);
                                        }
                                    }
                                    setSelectedInfo(newselectedInfo);
                                }}
                                resize={() => {
                                    if (props.resize) {
                                        props.resize();
                                    }
                                }} />
                        default:
                            return <ESearchItem {...item} onChange={(filedID, values, names) => {
                                var newselectedInfo = {
                                    ...selectedInfo
                                };
                                if (values && names) {
                                    newselectedInfo[filedID] = values;

                                    showData[filedID] = (item.title ?? '') + '：' + names
                                } else {
                                    if (newselectedInfo.hasOwnProperty(filedID))
                                        delete newselectedInfo[filedID];

                                    if (showData.hasOwnProperty(filedID)) {
                                        let newShowData = { ...showData };
                                        delete newShowData[filedID];
                                        setShowData(newShowData);
                                    }
                                }
                                setSelectedInfo(newselectedInfo);
                            }} />;
                    }
                })}
            </div>
            <div style={{ margin: '4px', height: '26px' }}>
                <Space size={'small'} style={{ float: 'right' }}>
                    {/* <Button type='link'>保存搜索</Button> */}
                    <Button size="small" type='primary' onClick={() => { onSearch() }}>查询</Button>
                    <Button size="small" onClick={onClear}>清空</Button>
                </Space>
            </div>
        </div>
    </div >
})
export default ESearch;