
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {
    HomeOutlined,
    ClusterOutlined,
    DesktopOutlined,
    SettingOutlined,
    DatabaseOutlined,
    ControlOutlined,
    ExceptionOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    FundProjectionScreenOutlined,
    QuestionCircleOutlined,
    VideoCameraOutlined,
    SwapOutlined,
    NotificationOutlined,
    UserOutlined,
    InfoCircleOutlined,
    LogoutOutlined,
    ProfileOutlined,
    AuditOutlined,
    FileProtectOutlined,
    FileDoneOutlined
} from '@ant-design/icons';
import { Avatar, Badge, ConfigProvider, Divider, Dropdown, Layout, Menu, Modal, Space, theme, Tooltip, Typography } from 'antd';
import UserSetting from '../user/UserSetting';
import Company from '../masterdata/company/Company';
import SingleProduct from '../masterdata/singleproduct/SingleProduct';
import YearProduct from '../masterdata/applyproduct/ApplyProduct';
import OrganizationUser from '../system/organization/OrganizationUser';
import RoleList from '../system/role/RoleList';
import OrganizationAccount from '../singleonline/organizationAccount/OrganizationAccount';
import OnlineOrder from '../singleonline/onlineOrder/OnlineOrder';
import OnlineProduct from '../singleonline/onlineProduct/OnlineProduct';
import OnlineUser from '../singleonline/onlineUser/OnlineUser';
import AgentSetting from '../singleonline/agentSetting/AgentSetting';
import Home from '../home/Home';
import Vendor from '../masterdata/vendor/Vendor';
import OnlineVendor from '../singleonline/onlineVendor/OnlineVendor';
import Error404 from '../error/Error404';
import Sales from '../masterdata/sales/Sales';
import ApplyAllocation from '../applyonline/applyallocation/ApplyAllocation';
import ApplyOrder from '../applyonline/applyorder/ApplyOrder';
import ApplyMember from '../applyonline/applymember/ApplyMember';
import ApplyAllocationDetail from '../applyonline/applyallocation/ApplyAllocationDetail';
import ApplyOrderDetail from '../applyonline/applyorder/ApplyOrderDetail';
import YearOrderList from '../yearOnline/yearOrder/YearOrder';
import SettleOrderList from '../yearOnline/settleOrder/SettleOrderList';
import YearOrderEdit from '../yearOnline/yearOrder/YearOrderEdit';
import InquiryList from '../inquiry/inquiryOrder/InquiryList';
import Customer from '../masterdata/customer/Customer';
import RoleAuth from '../system/roleAuth/RoleAuth';
import UserAuth from '../system/user/userAuth/UserAuth';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Server from '../../server/Server';
import URL from '../../server/URL';
import EIcon from '../../utils/EIcon';
import SearchUser from '../system/searchuser/SearchUser';
import FileList from '../attachment/fileList/FileList';

const { Header, Sider, Content } = Layout;

const Main = () => {
    const [primary, setPrimary] = useState('#1890ff');
    const [collapsed, setCollapsed] = useState(false);
    const [loginOutVisible, setloginOutVisible] = React.useState(false);
    const [confirmLoading, setconfirmLoading] = React.useState(false);
    const [user, setUser] = React.useState<any>(null);
    const [currentPage, setCurrentPage] = useState('');
    const [menuData, setMenuData] = useState<any[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<any>(null);

    React.useEffect(() => {
        let tokenKey = process.env.REACT_APP_TOKEN ?? '';
        var token = localStorage.getItem(tokenKey);
        if (!token) {
            window.location.href = '/login';
        } else {
            var userInfo = localStorage.getItem('EKnown.UserInfo');
            if (userInfo) {
                var user = JSON.parse(userInfo);
                setUser(user);
            }
        }
    }, [])

    useEffect(() => {
        if (menuData) {
            if (window.location.pathname == '/') {
                setCurrentPage('首页');
            } else {
                (menuData ?? []).map((m: any) => {
                    if (m.children && (m.children.length > 0)) {
                        m.children.map((item: any) => {
                            if (item.router == window.location.pathname ||
                                item.router == window.location.pathname + window.location.search
                            ) {
                                setCurrentPage(item.name);
                            }
                        })
                    }
                    else {
                        if (m.router == window.location.pathname ||
                            m.router == window.location.pathname + window.location.search) {
                            setCurrentPage(m.name);
                        }
                    }
                });
            }
        }
    }, [selectedKeys])

    React.useEffect(() => {
        if (user) {
            var request = {};
            Server.Post(URL.Main.LoadMenu, request).then((response: any) => {
                if (response.isSuccess) {
                    setMenuData(response.data);
                    if (response.data.length > 0) {
                        setSelectedKeys([response.data[0].id]);
                    }
                }
            });
        }
    }, [user])

    const loginOutOK = () => {
        try {
            setconfirmLoading(true);
            localStorage.clear();
            window.location.href = '/login';
        } finally {
            setloginOutVisible(false)
            setconfirmLoading(false);
        }
    }

    const loginOutCancel = () => {
        setloginOutVisible(false)
    }

    const menu = (
        <Menu>
            <Menu.Item placeholder='bottomCenter' icon={<UserOutlined />}
                onClick={() => {
                    window.location.href = '/home/setting';
                }} >
                个人设置
            </Menu.Item>
            <Menu.Item danger placeholder='bottomCenter'
                icon={<LogoutOutlined />}
                onClick={() => { setloginOutVisible(true); }}>注销</Menu.Item>
            <Modal
                title="注销登录"
                visible={loginOutVisible}
                onOk={loginOutOK}
                onCancel={loginOutCancel}
                okText='注销'
                cancelText='取消'
                confirmLoading={confirmLoading}
            >
                <p>确认注销用户 {user?.userName} 登录状态?</p>
            </Modal>
        </Menu >
    );


    return (
        <Layout style={{ height: 'calc(100vh)' }}>
            <Sider trigger={null} collapsible collapsed={collapsed} width={210}
                style={{ backgroundColor: 'white' }}>
                <div className="logo" style={{
                    height: '65px',
                    fontSize: '22px',
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: '#1890ff',
                    borderBottom: '1px solid'
                }} >
                    {collapsed ?
                        <Tooltip title={collapsed ? '展开导航' : '收起导航'} placement={'right'}>
                            {
                                React.createElement(MenuUnfoldOutlined, {
                                    className: 'trigger',
                                    onClick: () => setCollapsed(!collapsed),
                                    style: { fontSize: '20px', marginTop: '20px' }
                                })
                            }
                        </Tooltip> :
                        <>
                            <div style={{
                                fontSize: '22px',
                                textAlign: 'center',
                                alignItems: 'center',
                                //backgroundImage: `url(${Logo})`,
                                backgroundSize: 'cover', height: '100%', width: '100%',
                                backgroundRepeat: 'repeat-x',
                                backgroundPosition: 'center',
                            }}>

                                <span style={{
                                    fontSize: '22px', height: '100%', alignItems: 'center',
                                    textAlign: 'center',
                                    lineHeight: '62px',
                                }}>
                                    誉旋科技
                                </span>
                                <Tooltip title={collapsed ? '展开导航' : '收起导航'} placement={'right'}>{React.createElement(MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: () => { setCollapsed(!collapsed) },
                                    style: {
                                        fontSize: '20px', marginTop: '23px'
                                        , float: 'right', paddingRight: '16px'
                                    }
                                })}
                                </Tooltip>
                            </div>
                        </>}
                </div>
                <Menu
                    style={{ backgroundColor: 'white' }}
                    mode="vertical"
                    defaultSelectedKeys={[currentPage]}
                    onSelect={(e: any) => {
                        setSelectedKeys([e.key]);
                    }}
                    items={
                        (menuData ?? []).map((m: any) => {
                            let children: Array<any> = [];
                            const parent: any = {
                                key: m.id,
                                label: m.children.length > 0 ? m.name : <Link to={m.router}>{m.name}</Link>,
                                icon: EIcon.Get(m.icon),
                            };
                            (m.children ?? []).map((childrenitem: any) => {
                                children.push({
                                    key: childrenitem.id,
                                    label: <Link to={childrenitem.router}>{childrenitem.name}</Link>
                                })
                                parent.children = children;
                            });
                            return parent;
                        })
                    }
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{
                    padding: 0,
                    backgroundColor: '#1890ff', color: 'white'
                }}>
                    <span >
                        <span style={{ fontSize: '14px', marginLeft: '10px' }}>{currentPage}</span>
                    </span>
                    <div style={{ float: 'right', marginRight: '5px' }}>
                        <Space size={0} style={{ float: 'right', marginRight: '5px' }} split={<Divider style={{ backgroundColor: 'white' }} type="vertical" />}>
                            <span>当前公司：{user?.organizationName}</span>
                            <Dropdown overlay={menu} >
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <Avatar
                                        style={{
                                            backgroundColor: 'white',
                                            color: '#1890ff',
                                            verticalAlign: 'middle',
                                        }}
                                        size='default'
                                        alt={user?.userName}
                                    >{user?.userName}</Avatar>
                                </a>
                            </Dropdown>
                        </Space>
                    </div>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '8px',
                        minHeight: 280,
                        height: 'calc(100% - 58px)',
                        overflow: 'auto'
                    }}
                >
                    <div className="site-layout-background" style={{
                        margin: '8px 8px 8px 8px',
                        height: 'calc(100% - 16px)',
                    }}>
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/setting' element={<UserSetting />} />
                            {/* system */}
                            <Route path='/organization/organizationUser' element={<OrganizationUser />} />
                            <Route path='/role/rolelist' element={<RoleList />} />
                            <Route path='/role/auth' element={<RoleAuth />} />
                            <Route path='/user/auth' element={<UserAuth />} />
                            <Route path='/user/sarchuser' element={<SearchUser />} />
                            {/* masterdata */}
                            <Route path='/customer/customerlist' element={<Customer />} />
                            <Route path='/company/companylist' element={<Company />} />
                            <Route path='/vendor/vendorlist' element={<Vendor />} />
                            <Route path='/sales/saleslist' element={<Sales />} />
                            <Route path='/singleproduct/productlist' element={<SingleProduct />} />
                            <Route path='/applyproduct/productlist' element={<YearProduct />} />
                            {/* order */}
                            {/* singleonline */}
                            <Route path='/organizationaccount' element={<OrganizationAccount />} />
                            <Route path='/onlinevendor' element={<OnlineVendor />} />
                            <Route path='/onlineproduct' element={<OnlineProduct />} />
                            <Route path='/agent' element={<AgentSetting />} />
                            <Route path='/order' element={<OnlineOrder />} />
                            <Route path='/user' element={<OnlineUser />} />
                            {/* applyonline */}
                            <Route path='/applyallocation' element={<ApplyAllocation />} />
                            <Route path='/applyallocationdetail' element={<ApplyAllocationDetail />} />
                            <Route path='/applyorder' element={<ApplyOrder />} />
                            <Route path='/applyorderdetail' element={<ApplyOrderDetail />} />
                            <Route path='/applymember' element={<ApplyMember />} />
                            {/* yearorder */}
                            <Route path='/yearorderlist' element={<YearOrderList />} />
                            <Route path='/yearorderedit' element={<YearOrderEdit />} />
                            <Route path='/settleorderlist' element={<SettleOrderList />} />
                            {/* inquiry */}
                            <Route path='/inquirylist' element={<InquiryList />} />
                            {/* attachment */}
                            <Route path='/file' element={<FileList />} />
                            {/* Error404 */}
                            <Route path='/*' element={<Error404 />} />
                        </Routes>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}
export default Main;