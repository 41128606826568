import { Button, Input, Space, Table, Tabs, Modal, message, Typography } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import RoleEdit from "./RoleEdit";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";

const { confirm } = Modal;

const RoleList = () => {
    const [searchValue, setSearchValue] = useState('');
    const [searchValueUser, setSearchValueUser] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });

    const [dataSourceAdmin, setDataSourceAdmin] = useState<any>([]);
    const [totalAdmin, setTotalAdmin] = useState(0);
    const [pageAdmin, setPageAdmin] = useState<any>({ pageIndex: 1, pageSize: 50 });

    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const columns: any = [
        { title: '角色名称', dataIndex: 'roleName', fixed: 'left', width: 350 },
        { title: '备注', dataIndex: 'remark', width: 350 },
        {
            title: '操作', align: 'center', width: 80, elipse: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '编辑', onClick: () => {
                            setEditRecord({
                                viewMode: 1,
                                roleID: record.roleID
                            });
                            setIsOpen(true)
                        }
                    }, {
                        name: '授权', onClick: () => {
                            window.location.href = '/system/user/auth?userID=' + record.userID;
                        }
                    },
                    { name: '移除', onClick: () => { showDeleteConfirm(record); }, danger: true },
                    { name: '角色授权', onClick: () => { window.location.href = "/system/role/auth?roleID=" + record.roleID; } },
                ];
                return EUtils.InitButtons(configs);
            },
        }
    ];

    const columnsAdmin: any[] = [
        { title: '用户名称', dataIndex: 'userName', fixed: 'left', width: 100 },
        { title: '账号', dataIndex: 'account', width: 80 },
        { title: '电话', dataIndex: 'tel', width: 80 },
        {
            title: '激活', dataIndex: 'isActive', width: 80,
            render: (text: any) => (
                text ? '启用' : '禁用'
            ),
        },
        {
            title: '管理员', dataIndex: 'isAdmin', width: 80,
            render: (text: any) => (
                text ? '是' : '否'
            ),
        },
        { title: '默认组织', dataIndex: 'organizationName', width: 100 },
        { title: '备注', dataIndex: 'remark', width: 150 },
        {
            title: '操作', align: 'center', width: 80, elipse: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    { name: '移除', onClick: () => { showDeleteConfirm(record); }, danger: true },
                ];
                return EUtils.InitButtons(configs);
            },
        }
    ]
    const [scrollY, setScrollY] = useState("")
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        setScrollY(EUtils.getTableScroll(82, ''))
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadUser();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Role.GetRoleList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    const loadUser = () => {
        var params = {
            searchValue: searchValueUser,
            pageIndex: pageAdmin.pageIndex,
            pageSize: pageAdmin.pageSize
        };
        Server.Post(URL.User.GetAdminUserList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSourceAdmin(response.data);
                setTotalAdmin(response.total);
            }
        })
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除角色',
            icon: <ExclamationCircleFilled />,
            content: '角色[' + record.roleName + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { roleID: record.roleID }
                Server.Post(URL.Role.DeleteRole, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const roleTab = <div>
        <div>
            <Input.Search allowClear style={{ width: '420px', marginRight: '10px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入角色名称" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            roleID: null
                        });
                        setIsOpen(true)
                    }}>新增</Button>
                </Space>
            </div>
            <Table size="small"
                bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1} align={'center'}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align={'center'}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
    </div>;
    const adminTab = <div>
        <div>
            <Input.Search allowClear style={{ width: '420px', marginRight: '10px' }}
                onChange={(e) => { setSearchValueUser(e.target.value) }}
                onSearch={() => { loadUser() }}
                placeholder="请输入账号、用户名、电话、备注" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                {/* <Space style={{ float: 'right' }}>
                    <Button type="primary">添加管理员</Button>
                </Space> */}
            </div>
            <Table size="small"
                bordered
                scroll={{ x: 'max-content' }}
                columns={columnsAdmin} dataSource={dataSourceAdmin} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: totalAdmin,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: pageAdmin.pageIndex,
                    defaultCurrent: 20,
                    pageSize: pageAdmin.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPageAdmin({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPageAdmin({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={6} align={'center'}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align={'center'}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
    </div>;

    const items = [
        { label: '角色管理', key: '0', children: roleTab }, // 务必填写 key
        { label: '系统管理员', key: '1', children: adminTab },
    ];

    return <div>
        <Tabs size="small" items={items} onChange={(activeKey) => {
            if (activeKey == '0') {
                loadData();
            } else {
                loadUser();
            }
        }} />
        <RoleEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
    </div>;
}
export default RoleList;