import { Button, Divider, Form, FormInstance, Input, message, Modal, Radio, Space, Switch, Table } from "antd";
import Password from "antd/lib/input/Password";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const OrganizationEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [organizationType, setOrganizationType] = useState<any>({ name: '公司', value: 1 });

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.record.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getData();
                    break;
                case 2:
                    getData();
                    break;
            }
        }
    }, [rest.isOpen])

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
        }
        else {
            formRef.current?.resetFields();
            formRef.current?.setFieldsValue({
                parentID: rest.record?.parentID
            })
            if (rest.record.parentTypeEnum && rest.record.parentTypeEnum == 2) {
                setOrganizationType({
                    value: 2,
                    name: '部门'
                })
            } else {
                setOrganizationType({ name: '公司', value: 1 })
            }
        }
    }

    const getData = () => {
        if (rest.record.organizationID) {
            var params = {
                organizationID: rest.record.organizationID
            };
            Server.Post(URL.Organization.GetOrganization, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = values;
            Server.Post(URL.Organization.SaveOrganization, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        }));
    }

    const isOrganization = () => {
        return organizationType.value == '1';
    }

    return <Modal title={(rest.record.viewMode == 0 ? '新增' : '编辑') + "组织架构"} width={600} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 10 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'组织ID'} name={'organizationID'} initialValue={null}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'父级ID'} name={'parentID'}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={(isOrganization() ? '公司' : '部门') + '类型'} name={'organizationType'} initialValue={organizationType.name}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} hidden={formRef.current?.getFieldValue('organizationTypeEnum') == 0 || rest.record.parentTypeEnum == 2} label={(isOrganization() ? '公司' : '部门') + '类型'} name={'organizationTypeEnum'} initialValue={organizationType.value}>
                <Radio.Group defaultValue={1} onChange={(e) => {
                    setOrganizationType({
                        name: e.target.value == 1 ? '公司' : '部门',
                        value: e.target.value
                    });
                    formRef.current?.setFieldValue('organizationType', e.target.value == 1 ? '公司' : '部门')
                }}>
                    <Radio value={1} name="公司">公司</Radio>
                    <Radio value={2} name="部门">部门</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={(isOrganization() ? '公司' : '部门') + '编码'} name={'organizationCode'} initialValue={''} rules={[{ required: true, message: '请输入用户代码' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={(isOrganization() ? '公司' : '部门') + '名称'} name={'organizationName'} initialValue={''} rules={[{ required: true, message: '请输入用户名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={(isOrganization() ? '公司' : '部门') + '负责人'} name={'master'} initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} hidden={!isOrganization()} label={'省份'} name={'province'} initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} hidden={!isOrganization()} label={'城市'} name={'city'} initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'备注'} name={'remark'} initialValue={''}>
                <Input />
            </Form.Item>
        </Form>
    </Modal>
};
export default OrganizationEdit;