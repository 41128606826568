import { Alert, Button, Card, Col, Descriptions, Divider, Input, List, Row, Space, Tag, Timeline } from "antd";
import { useEffect, useState } from "react";
import type { DescriptionsProps } from 'antd';
import RechargeApplyAllocation from "./RechargeApplyAllocation";
import EUtils from "../../../utils/EUtils";
import { useLoaderData } from "react-router-dom";
import URL from "../../../server/URL";
import Server from "../../../server/Server";
import moment from "moment";
import { read } from "fs";
import TextArea from "antd/lib/input/TextArea";

const ApplyAllocationDetail = () => {
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [recordRecharge, setRecordRecharge] = useState<any>({});
    const [record, setRecord] = useState<any>(null)

    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [dataApplyOrder, setDataApplyOrder] = useState<any>([]);

    const [searchValueConsume, setSearchValueConsume] = useState('');
    const [totalConsume, setTotalConsume] = useState(0);
    const [pageConsume, setPageConsume] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [dataApplyConsume, setDataApplyConsume] = useState<any>([]);


    useEffect(() => {
        var params = EUtils.getUrlParams();
        if (params.oid) {
            loadData(params.oid);
        }
    }, [])

    useEffect(() => {
        if (record) {
            loadDataApplyOrder()
        }
    }, [record, page])

    useEffect(() => {
        if (record) {
            loadDataApplyConsume()
        }
    }, [record, pageConsume])

    var loadData = (oid: any) => {
        var params = {
            applyAllocationID: oid
        }
        Server.Post(URL.ApplyAllocation.GetApplyAllocation, params, true).then((response: any) => {
            if (response.isSuccess) {
                setRecord(response);
            }
        })
    }

    var loadDataApplyOrder = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
            applyAllocationID: record.applyAllocationID
        }
        Server.Post(URL.ApplyOrder.GetAllocationApplyOrderList, params, true).then((response: any) => {
            if (response.isSuccess) {
                setDataApplyOrder(response.data);
                setTotal(response.total);
            }
        })
    }

    var loadDataApplyConsume = () => {
        var params = {
            searchValue: searchValueConsume,
            pageIndex: pageConsume.pageIndex,
            pageSize: pageConsume.pageSize,
            type: -1,
            applyAllocationID: record.applyAllocationID
        }
        Server.Post(URL.ApplyConsume.GetApplyConsumeList, params, true).then((response: any) => {
            if (response.isSuccess) {
                setDataApplyConsume(response.data);
                setTotalConsume(response.total);
            }
        })
    }

    return <div>
        <div style={{ height: '35px',marginBottom: '5px' }}>
            <span style={{ fontWeight: 'bold' }}>保单详情</span>
            <span style={{ float: 'right' }}>
                <Space size={'small'}>
                    <Button type='primary' danger onClick={() => {
                        setRecordRecharge({
                            applyAllocationID: record.applyAllocationID,
                        })
                        setIsShowRecharge(true);
                    }}>充值</Button>
                    <Button type='primary' onClick={() => { window.history.back() }}>返回</Button>
                </Space>
            </span>
        </div>
        <div>
            <Card title={<>
                <span>{'保单信息'}</span>
                <Space size={'small'} style={{ float: 'right' }}>
                    {
                        record ? (record.tags).map((item: any) => {
                            return <Tag color={item.color}>{item.name}</Tag>
                        }) : ''
                    }
                </Space>
            </>} size="small" type="inner" style={{ marginBottom: '5px' }} bodyStyle={{ padding: '0' }}>
                <Descriptions layout="horizontal" bordered size="small"
                    column={4}>
                    <Descriptions.Item span={2} label="保单编码" >{record ? record.applyAllocationNO : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} label="协议号" >{record ? record.agreementNO : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保人" >{record ? record.insureBy : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="被保人" >{record ? record.insuredBy : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="产品名称" >{record ? record.applyProductName : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="保险公司" >{record ? record.companyName : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="生效日期" >{record ? moment(record.effectTime).format('YYYY-MM-DD') : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="失效日期" >{record ? moment(record.expireTime).format('YYYY-MM-DD') : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保限额" >{record ? record.singleLimit : ''}{'万元'}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="总保额" contentStyle={{ fontWeight: 'bold' }}>{record ? record.amount : '0'}{'万元'}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="剩余保额" contentStyle={{ fontWeight: 'bold', color: 'green' }}>{record ? (record.amount - record.usedAmount) : '0'}{'万元'}</Descriptions.Item>
                    <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="免赔额" >
                        <TextArea autoSize bordered={false} readOnly value={record ? record.deductibleAmountDesc : ''} />
                    </Descriptions.Item>
                    <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="承保区域" >{record ? record.insureArea : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="所属公司" >{record ? record.organizationName : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="业务员" >{record ? record.salesName : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="会员账号" >{record ? record.memberName : ''}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card title={<>
                <Space size={'small'}>
                    <span>{'申报记录(' + total + '笔)'}</span>
                    <Tag color="warning">申报合计：{record ? record.usedAmount : 0}{'万元'}</Tag>
                </Space>
            </>} size="small" type="inner" style={{ marginBottom: '5px' }}
                bodyStyle={{ padding: '0 5px' }}
                extra={<>
                    <Input.Search allowClear style={{ width: '350px' }}
                        onChange={(e) => { setSearchValue(e.target.value) }}
                        onSearch={() => { loadDataApplyOrder() }}
                        placeholder="输入申报单号查询" />
                </>}
            >
                <List style={{ padding: '5px' }}
                    pagination={{
                        position: 'bottom',
                        style: { marginRight: '10px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        size: 'small',
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [5, 10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }}
                    dataSource={dataApplyOrder}
                    renderItem={(item: any, index) => (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <div style={{ margin: '5px' }}>
                                        <span>
                                            <Space size={'small'}>
                                                <span>申报单号:<a onClick={() => {
                                                    window.location.href = '/yearonline/applyorderdetail?oid=' + item.applyOrderID;
                                                }}>{item.applyOrderNO}</a></span>
                                                <Tag color={item.stateEnum == 0 ? '' : 'green'}>{item.state}</Tag>
                                                <Tag color={item.sendStateEnum == 0 ? '' : 'green'}>{item.sendState}</Tag>
                                            </Space>
                                        </span>
                                        <span style={{ float: 'right' }}>
                                            <Button type={'primary'} size={'small'}
                                                onClick={() => {
                                                    window.location.href = '/yearonline/applyorderdetail?oid=' + item.applyOrderID;
                                                }}>查看详情</Button>
                                        </span>
                                    </div>
                                }
                                description={<>
                                    <Descriptions layout="horizontal" bordered size="small"
                                        style={{ width: '100%' }}
                                        column={4}>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="申报时间" >{moment(item.applyTime).format("YYYY-MM-DD")}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="申报类型" >{(item.applyTypeEnum == 0 ? item.applyType : (item.applyType + '(' + moment(item.requiredTime).format("YYYY-MM-DD HH:mm") + ')'))} </Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="发送时间" >{item.sendTime ? moment(item.sendTime).format("YYYY-MM-DD HH:mm:ss") : ''}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="保额" contentStyle={{ fontWeight: 'bold', color: 'green' }}>{item.insureAmount}{'万元'}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="承运时间" >{moment(item.carrierTime).format("YYYY-MM-DD")}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="货物品名" >{item.partName}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="货物包装" >{item.packages}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="货物件数" >{item.eaQty}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="始发地" >{item.startAddress}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="目的地" >{item.destAddress}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="车牌号" >{item.truckNO}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ width: '90px' }} label="运单号" >{item.transportNO}</Descriptions.Item>
                                        <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="说明" >{item.remark}</Descriptions.Item>
                                    </Descriptions>
                                </>}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <Card title={
                <Space size={'small'}>
                    <span>{'消费记录(' + totalConsume + '笔)'}</span>
                    <Tag color="green">充值合计：{record ? record.amount : 0}{'万元'}</Tag>
                    <Tag color="warning">申报合计：{record ? record.usedAmount : 0}{'万元'}</Tag>
                </Space>
            } size="small" type="inner"
                style={{ marginBottom: '5px' }}
                bodyStyle={{ padding: '0 5px' }}
                extra={<>
                    <Button type="primary" size="small" onClick={() => { loadDataApplyConsume(); }}>刷新</Button>
                </>}
            >
                <List
                    style={{ padding: '5px' }}
                    pagination={{
                        position: 'bottom',
                        style: { marginRight: '10px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: totalConsume,
                        size: 'small',
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: pageConsume.pageIndex,
                        defaultCurrent: 20,
                        pageSize: pageConsume.pageSize,
                        pageSizeOptions: [5, 10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPageConsume({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPageConsume({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }}
                    dataSource={dataApplyConsume}
                    renderItem={(item: any, index) => (
                        <List.Item >
                            <Descriptions layout="horizontal" bordered size="small"
                                style={{ marginBottom: '5px', width: '100%' }}
                                column={5}>
                                <Descriptions.Item labelStyle={{ width: '80px' }} label="类型" > {<Tag color={item.consumeTypeEnum == 0 ? 'green' : 'warning'}>{item.consumeType}</Tag>}</Descriptions.Item>
                                <Descriptions.Item labelStyle={{ width: '80px' }} label="时间" >{moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                                <Descriptions.Item labelStyle={{ width: '80px' }} label="金额" >{item.consumeAmount}{'万元'}</Descriptions.Item>
                                <Descriptions.Item labelStyle={{ width: '80px' }} label="余额" >{item.allocationAmount}{'万元'}</Descriptions.Item>
                                <Descriptions.Item labelStyle={{ width: '80px' }} label="单据" >
                                    <a onClick={() => {
                                        if (item.applyOrderNO != null) {
                                            window.location.href = '/yearonline/applyorderdetail?oid=' + item.applyOrderID;
                                        }
                                    }}>{item.applyOrderNO}</a>
                                </Descriptions.Item>
                            </Descriptions>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
        <RechargeApplyAllocation isOpen={isShowRecharge} {...recordRecharge}
            setIsOpen={(value: any) => setIsShowRecharge(value)}
            loadData={() => {
                if (record) {
                    loadData(record.applyAllocationID)
                }
            }} />
    </div>
}
export default ApplyAllocationDetail;