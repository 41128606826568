import { Result, Input, Table, AutoComplete, Empty, Space, Divider, } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";

const { Search } = Input;

const ByUser = () => {
    const [dataSource, setDataSource] = useState<any>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);

    const [options, setOptions] = useState<any>([]);
    const [user, setUser] = useState<any>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [noFunaction, setNotFunction] = useState(false);

    const columns: ColumnsType<any> = [
        {
            title: '功能权限', dataIndex: 'name', key: 'name', align: 'left', fixed: 'left',
            render(value, record, index) {
                return <>
                    <div>{value}</div>
                    {!record.children && record.actions ? <div>
                        <Divider style={{ margin: '5px', padding: '0' }} />
                        <Space wrap size={'small'} style={{ float: 'right' }}>
                            {record.actions.map((items: any) => {
                                return <span>{items.name}</span>
                            })}
                        </Space>
                    </div> : ''}
                </>
            },
        }]

    const onExpandedRowsChange = (expandedRows: any) => {
        setExpandedRowKeys(expandedRows);
    }

    const handleSearch = (value: string) => {
        setOptions([{ label: <div>加载中....</div>, value: '' }]);
        if (value) {
            var request = {
                searchValue: value
            };
            Server.Post(URL.User.GetUserListByNameAccount, request, false).then((response: any) => {
                if (response.isSuccess) {
                    if (response.data && response.data.length > 0) {
                        var data: any = [];
                        response.data.map((item: any) => {
                            data.push({
                                label: <div style={{ color: value == item.userName ? 'red' : '' }}>姓名：{item.userName}（组织：{item.organizationName}，账号：{item.account}）</div>,
                                value: '姓名：' + item.userName + '（组织：' + item.organizationName + '，账号：' + item.account + '）',
                                user: item,
                            });
                        })
                        setOptions(data);
                    } else {
                        setOptions([{ label: <div>未查找到对应内容</div>, value: '' }]);
                    }
                }
            })
        } else {
            setOptions([]);
        }
    };

    useEffect(() => {
        setDataSource([])
        setNotFunction(false);
        if (user) {
            var userInfo = localStorage.getItem('EKnown.UserInfo');
            if (userInfo) {
                var request = {
                    tenantID: JSON.parse(userInfo).tenantID,
                    userID: user.userID
                };
                Server.Post(URL.User.GetUserFunctionalityList, request).then((response: any) => {
                    if (response.isSuccess) {
                        setIsAdmin(response.isAdmin);
                        if (response.data && response.data.length > 0) {
                            setDataSource(response.data)
                        } else {
                            setNotFunction(true);
                        }
                    };
                });
            }
        } else {
            setDataSource([]);
        }
    }, [user])

    const onSelect = (value: string, option: any) => {
        setUser(option.user);
    };

    return <div >
        <div style={{ width: '80%', left: '10%', position: 'absolute' }}>
            <AutoComplete
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={true}
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
                size="small"
            >
                <Search placeholder="请输入用户名、账号检索" onChange={e => {
                    if (e.target.value == '') {
                        setUser(null)
                    }
                }} enterButton allowClear />
            </AutoComplete>
            <div style={{ padding: '20px 0' }}>
                {dataSource && dataSource.length > 0 ?
                    <Table size="small" bordered
                        scroll={{ x: 'max-content' }}
                        rowKey={'id'}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        expandedRowKeys={[...expandedRowKeys]}
                        onExpandedRowsChange={onExpandedRowsChange} /> :
                    (noFunaction ?
                        <Empty description={isAdmin ? ('用户“' + user?.userName + '”为系统管理员，拥有系统所有权限') : ('用户“' + user?.userName + '”暂无任何权限点')} /> :
                        <Result subTitle={'在输入框中输入用户名称、帐号，查看该用户的权限。'} title='' icon={<SearchOutlined />} />)}
            </div>
        </div>
    </div>
}
export default ByUser;