import { Button, Card, Col, Descriptions, Input, List, Row, Space, Tag, Timeline } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils from "../../../utils/EUtils";

const ApplyOrderDetail = () => {
    const [record, setRecord] = useState<any>(null);
    const [recordAllocation, setRecordAllocation] = useState<any>(null);

    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [dataApplyEmail, setDataApplyEmail] = useState<any>([{}, {}]);

    useEffect(() => {
        var params = EUtils.getUrlParams();
        if (params.oid) {
            loadData(params.oid);
        }
    }, [])

    var loadData = (oid: any) => {
        var params = {
            applyOrderID: oid
        }
        Server.Post(URL.ApplyOrder.GetApplyOrder, params, true).then((response: any) => {
            if (response.isSuccess) {
                setRecord(response);
            }
        })
    }

    var loadDataAllocation = () => {
        var params = {
            applyAllocationID: record.applyAllocationID
        }
        Server.Post(URL.ApplyAllocation.GetApplyAllocation, params, true).then((response: any) => {
            if (response.isSuccess) {
                setRecordAllocation(response);
            }
        })
    }

    useEffect(() => {
        if (record) {
            loadDataAllocation();
            loadDataApplyEmail();
        }
    }, [record])

    useEffect(() => {
        if (record) {
            loadDataApplyEmail();
        }
    }, [page])

    var loadDataApplyEmail = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
            applyOrderID: record.applyOrderID
        }
        Server.Post(URL.ApplyEmail.GetApplyEmailList, params, true).then((response: any) => {
            if (response.isSuccess) {
                setDataApplyEmail(response.data);
                setTotal(response.total)
            }
        })
    }

    const showBody = (body: string) => {
        var result = body ?? '';
        result = result.replaceAll('<br/>', "")
        result = result.replaceAll('&nbsp;', ' ')
        return result;
    }

    return <div>
        <div style={{ height: '35px',marginBottom: '5px' }}>
            <span style={{ fontWeight: 'bold' }}>申报订单详情</span>
            <Space style={{ float: 'right' }}>
                {/* {record && record.stateEnum == 1 ? <Button type='primary' danger onClick={() => { }}>邮件发送</Button> : ''} */}
                <Button type='primary' onClick={() => { window.history.back() }}>返回</Button>
            </Space>
        </div>
        <div style={{ marginBottom: '5px' }}>
            <Card size="small" title={
                <>
                    <span >申报订单</span>
                    <Space size={'small'} style={{ float: 'right' }}>
                        <>
                            {
                                record ? (record.tags).map((item: any) => {
                                    return <Tag color={item.color}>{item.name}</Tag>
                                }) : ''
                            }
                        </>
                    </Space>
                </>
            } type="inner" bodyStyle={{ padding: '0' }} style={{ marginBottom: '5px' }} >
                <Descriptions layout="horizontal" bordered size="small"
                    column={4}>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="申报单号" >{record ? record.applyOrderNO : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="申报时间" >{record ? moment(record.applyTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="申报类型" >{record ? (record.applyTypeEnum == 0 ? record.applyType : (record.applyType + '(' + moment(record.requiredTime).format("YYYY-MM-DD HH:mm") + ')')) : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="承运时间" >{record ? moment(record.carrirTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="货物品名" >{record ? record.partName : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="货物包装" >{record ? record.packages : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="货物件数" >{record ? record.eaQty : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="始发地" >{record ? record.startAddress : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="目的地" >{record ? record.destAddress : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="车牌号" >{record ? record.truckNO : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="运单号" >{record ? record.transportNO : ''}</Descriptions.Item>
                    <Descriptions.Item span={3} labelStyle={{ width: '90px' }} label="说明" >{record ? record.remark : ''}</Descriptions.Item>
                    <Descriptions.Item span={1} labelStyle={{ width: '90px' }} label="保额" contentStyle={{ fontWeight: 'bold', color: 'green' }}>{record ? record.insureAmount : ''}{'万元'}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card size="small" title={<>
                <Space size={'small'}>
                    <>
                        <span>{'保单信息'}</span>
                        {
                            recordAllocation ? (recordAllocation.tags ?? []).map((item: any) => {
                                return <Tag color={item.color}>{item.name}</Tag>
                            }) : ''
                        }
                        <Tag color="green">充值合计：{recordAllocation ? recordAllocation.amount : 0}{'万元'}</Tag>
                        <Tag color="warning">申报合计：{recordAllocation ? recordAllocation.usedAmount : 0}{'万元'}</Tag>
                    </>
                </Space>
            </>} type="inner" bodyStyle={{ padding: '0' }}
                style={{ marginBottom: '5px' }}
                extra={
                    record ? <>
                        <Button type="primary" size="small" onClick={() => {
                            window.location.href = '/yearonline/applyallocationDetail?oid=' + recordAllocation.applyAllocationID
                        }}>查看详情</Button>
                    </> : <></>
                }>
                <Descriptions layout="horizontal" bordered size="small"
                    column={4}>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="保单编码" >
                        <a onClick={() => {
                            window.location.href = '/yearonline/applyallocationDetail?oid=' + recordAllocation.applyAllocationID
                        }}>{recordAllocation ? recordAllocation.applyAllocationNO : ''}</a>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="协议号" >{recordAllocation ? recordAllocation.agreementNO : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保人" >{recordAllocation ? recordAllocation.insureBy : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="被保人" >{recordAllocation ? recordAllocation.insuredBy : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="产品名称" >{recordAllocation ? recordAllocation.applyProductName : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="保险公司" >{recordAllocation ? recordAllocation.companyName : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="生效日期" >{recordAllocation ? moment(recordAllocation.effectTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="失效日期" >{recordAllocation ? moment(recordAllocation.expireTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保限额" >{recordAllocation ? recordAllocation.singleLimit : ''}{'万元'}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="总保额" contentStyle={{ fontWeight: 'bold' }}>{recordAllocation ? recordAllocation.amount : ''}{'万元'}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="剩余保额" contentStyle={{ fontWeight: 'bold', color: 'green' }}>{recordAllocation ? (recordAllocation.amount - recordAllocation.usedAmount) : ''}{'万元'}</Descriptions.Item>
                    <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="免赔额" >
                        <TextArea autoSize bordered={false} readOnly value={recordAllocation ? recordAllocation.deductibleAmountDesc : ''} />
                    </Descriptions.Item>
                    <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="承保区域" >{recordAllocation ? recordAllocation.insureArea : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} labelStyle={{ width: '100px' }} label="所属公司" >{recordAllocation ? recordAllocation.organizationName : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="业务员" >{recordAllocation ? recordAllocation.salesName : ''}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '90px' }} label="会员账号" >{recordAllocation ? recordAllocation.memberName : ''}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card title={<>
                <Space size={'small'}>
                    <span>{'邮件记录(' + total + '笔)'}</span>
                </Space>
            </>} size="small" type="inner" style={{ marginBottom: '5px' }}
                bodyStyle={{ padding: '0 5px' }}
                extra={<>
                    <Input.Search allowClear style={{ width: '350px' }}
                        onChange={(e) => { setSearchValue(e.target.value) }}
                        onSearch={() => { loadDataApplyEmail() }}
                        placeholder="输入邮件内容查询" />
                </>}
            >
                <List style={{ padding: '5px' }}
                    pagination={{
                        position: 'bottom',
                        style: { marginRight: '10px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        size: 'small',
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [5, 10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }}
                    dataSource={dataApplyEmail}
                    renderItem={(item: any, index) => (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <div style={{ margin: '5px' }}>
                                        <span>
                                            <Space size={'small'}>
                                                <span>发送时间:{moment(item.createTime).format('YYYY-HH-DD HH:mm:ss')}</span>
                                                <Tag color={'green'}>{item.channel}</Tag>
                                                <Tag color={item.emailTypeEnum == 0 ? 'green' : 'orange'}>{item.emailTypeEnum == 0 ? '系统' : '人工'}</Tag>
                                                <Tag color={item.result == 1 ? 'green' : 'red'}>{item.result == 1 ? '成功' : '失败'}</Tag>
                                            </Space>
                                        </span>
                                    </div>
                                }
                                description={<>
                                    <Descriptions layout="horizontal" bordered size="small"
                                        style={{ width: '100%' }}
                                        column={4}>
                                        <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="邮件标题" >{item.subjectName}</Descriptions.Item>
                                        <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="发送邮箱" >{item.mailApply}</Descriptions.Item>
                                        <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="收件邮箱" >{item.mailTo}</Descriptions.Item>
                                        <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="抄送邮箱" >{item.mailCc}</Descriptions.Item>
                                        <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="附件" >{item.attachmentName}</Descriptions.Item>
                                        <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="内容" >
                                            {/* {showBody(item.content)} */}
                                            <TextArea autoSize bordered={false} readOnly value={showBody(item.content)} />
                                        </Descriptions.Item>
                                        {
                                            item.result == 0 ? <>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="原因" >{item.reason}</Descriptions.Item>
                                            </> : ''
                                        }
                                    </Descriptions>
                                </>}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    </div>
}
export default ApplyOrderDetail;