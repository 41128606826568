let BaseUrl = '/api/';
let MainUrl = BaseUrl + 'Main/';
let OrganizationUrl = BaseUrl + 'Organization/';
let RoleUrl = BaseUrl + 'Role/';
let UserUrl = BaseUrl + 'User/';
let SalesUrl = BaseUrl + 'Sales/';
let CompanyUrl = BaseUrl + 'Company/';
let CustomerUrl = BaseUrl + 'Customer/';
let VendorUrl = BaseUrl + 'Vendor/';
let ProductUrl = BaseUrl + 'Product/';
let ApplyProductUrl = BaseUrl + 'ApplyProduct/';
let AccountUrl = BaseUrl + 'Account/';
let OnlineVendorUrl = BaseUrl + 'OnlineVendor/';
let OnlineProductUrl = BaseUrl + 'OnlineProduct/';
let AgentUrl = BaseUrl + 'Agent/';
let MemberUrl = BaseUrl + 'Member/';
let OnlineOrderUrl = BaseUrl + 'OnlineOrder/';
let ApplyAllocationUrl = BaseUrl + 'ApplyAllocation/';
let ApplyOrderUrl = BaseUrl + 'ApplyOrder/';
let ApplyMemberUrl = BaseUrl + 'ApplyMember/';
let ApplyConsumeUrl = BaseUrl + 'ApplyConsume/';
let ApplyEmailUrl = BaseUrl + 'ApplyEmail/';
let YearOrderUrl = BaseUrl + 'YearOrder/';
let YearOrderSettleUrl = BaseUrl + 'YearOrderSettle/';
let InquiryOrderUrl = BaseUrl + 'InquiryOrder/';

const URL = {
    Main: {
        UserLogin: MainUrl + 'UserLogin',
        LoginOut: MainUrl + 'LoginOut',
        LoadMenu: MainUrl + 'LoadMenu',
    },
    Organization: {
        GetOrganizationList: OrganizationUrl + 'GetOrganizationList',
        GetOrganization: OrganizationUrl + 'GetOrganization',
        SaveOrganization: OrganizationUrl + 'SaveOrganization',
        DeleteOrganization: OrganizationUrl + 'DeleteOrganization'
    },
    Role: {
        GetRoleList: RoleUrl + 'GetRoleList',
        GetRole: RoleUrl + 'GetRole',
        SaveRole: RoleUrl + 'SaveRole',
        DeleteRole: RoleUrl + 'DeleteRole',
        GetRoleFunctionalityList: RoleUrl + 'GetRoleFunctionalityList',
        SaveRoleFunctionality: RoleUrl + 'SaveRoleFunctionality',
    },
    User: {
        GetUserList: UserUrl + 'GetUserList',
        GetAdminUserList: UserUrl + 'GetAdminUserList',
        GetUser: UserUrl + 'GetUser',
        SaveUser: UserUrl + 'SaveUser',
        DeleteUser: UserUrl + 'DeleteUser',
        SetActiveUser: UserUrl + 'SetActiveUser',
        SetAdminUser: UserUrl + 'SetAdminUser',
        GetUserListByNameAccount: UserUrl + 'GetUserListByNameAccount',
        GetUserFunctionalityList: UserUrl + 'GetUserFunctionalityList',
        GetUserOrganizationRoleList: UserUrl + 'GetUserOrganizationRoleList',
        SetDefaultUserOrganization: UserUrl + 'SetDefaultUserOrganization',
        RemoveUserOrganization: UserUrl + 'RemoveUserOrganization',
        RemoveUserOrganizationRole: UserUrl + 'RemoveUserOrganizationRole',
        AddUserOrganization: UserUrl + 'AddUserOrganization',
        AddUserOrganizationRole: UserUrl + 'AddUserOrganizationRole',
    },
    Company: {
        GetCompanyList: CompanyUrl + 'GetCompanyList',
        GetCompany: CompanyUrl + 'GetCompany',
        SaveCompany: CompanyUrl + 'SaveCompany',
        DeleteCompany: CompanyUrl + 'DeleteCompany',
        SetActiveCompany: CompanyUrl + 'SetActiveCompany',
        GetCompanyOptions: CompanyUrl + 'GetCompanyOptions',
    },
    Customer: {
        GetCustomerList: CustomerUrl + 'GetCustomerList',
        GetCustomer: CustomerUrl + 'GetCustomer',
        SaveCustomer: CustomerUrl + 'SaveCustomer',
        DeleteCustomer: CustomerUrl + 'DeleteCustomer',
        SetActiveCustomer: CustomerUrl + 'SetActiveCustomer',
        GetCustomerOptions: CustomerUrl + 'GetCustomerOptions',
    },
    Sales: {
        GetSalesList: SalesUrl + 'GetSalesList',
        GetSales: SalesUrl + 'GetSales',
        SaveSales: SalesUrl + 'SaveSales',
        DeleteSales: SalesUrl + 'DeleteSales',
        SetActiveSales: SalesUrl + 'SetActiveSales',
        GetSalesOptions: SalesUrl + 'GetSalesOptions',
    },
    Vendor: {
        GetVendorList: VendorUrl + 'GetVendorList',
        GetVendor: VendorUrl + 'GetVendor',
        SaveVendor: VendorUrl + 'SaveVendor',
        DeleteVendor: VendorUrl + 'DeleteVendor',
        SetActiveVendor: VendorUrl + 'SetActiveVendor',
        SetOnlineVendor: VendorUrl + 'SetOnlineVendor',
        GetVendorOptions: VendorUrl + 'GetVendorOptions',
    },
    Product: {
        GetProductList: ProductUrl + 'GetProductList',
        GetProduct: ProductUrl + 'GetProduct',
        SaveProduct: ProductUrl + 'SaveProduct',
        DeleteProduct: ProductUrl + 'DeleteProduct',
        SetActiveProduct: ProductUrl + 'SetActiveProduct',
        GetScopeOptions: ProductUrl + 'GetScopeOptions',
        GetProductTypeOptions: ProductUrl + 'GetProductTypeOptions',
        GetTypeOptions: ProductUrl + 'GetTypeOptions',
        GetProductCompanyList: ProductUrl + 'GetProductCompanyList',
        GetProductAddition: ProductUrl + 'GetProductAddition',
        SaveProductAddition: ProductUrl + 'SaveProductAddition'
    },
    ApplyProduct: {
        GetApplyProductList: ApplyProductUrl + 'GetApplyProductList',
        GetApplyProduct: ApplyProductUrl + 'GetApplyProduct',
        SaveApplyProduct: ApplyProductUrl + 'SaveApplyProduct',
        DeleteApplyProduct: ApplyProductUrl + 'DeleteApplyProduct',
        SetActiveApplyProduct: ApplyProductUrl + 'SetActiveApplyProduct',
        GetApplyProductOptions: ApplyProductUrl + 'GetApplyProductOptions',
    },
    Account: {
        GetAccountList: AccountUrl + 'GetAccountList',
        GetAccount: AccountUrl + 'GetAccount',
        CreateAccount: AccountUrl + 'CreateAccount',
        GetAccountRechargeList: AccountUrl + 'GetAccountRechargeList',
        RechargeAccount: AccountUrl + 'RechargeAccount',
        GetAccountConsumptionList: AccountUrl + 'GetAccountConsumptionList',
    },
    OnlineVendor: {
        GetVendorList: OnlineVendorUrl + 'GetVendorList',
        SetOnlineVendor: OnlineVendorUrl + 'SetOnlineVendor',
    },
    OnlineProduct: {
        GetProductList: OnlineProductUrl + 'GetProductList',
        SetOnlineProduct: OnlineProductUrl + 'SetOnlineProduct',
        SetHotProduct: OnlineProductUrl + 'SetHotProduct',
    },
    Agent: {
        GetAgentList: AgentUrl + 'GetAgentList',
        BindAgent: AgentUrl + 'BindAgent',
        UnBindAgent: AgentUrl + 'UnBindAgent',
        RechargeAgent: AgentUrl + 'RechargeAgent',
        GetAgentRateList: AgentUrl + 'GetAgentRateList',
        SaveAgentRate: AgentUrl + 'SaveAgentRate',
    },
    Member: {
        GetMemberList: MemberUrl + 'GetMemberList',
        AddMember: MemberUrl + 'AddMember',
        SetActiveMember: MemberUrl + 'SetActiveMember',
        GetMemberAccount: MemberUrl + 'GetMemberAccount',
    },
    OnlineOrder: {
        GetOnlineOrderList: OnlineOrderUrl + 'GetOnlineOrderList',
    },
    ApplyAllocation: {
        GetApplyAllocationList: ApplyAllocationUrl + 'GetApplyAllocationList',
        GetApplyAllocation: ApplyAllocationUrl + 'GetApplyAllocation',
        GetApplyAllocationAmount: ApplyAllocationUrl + 'GetApplyAllocationAmount',
        AddApplyAllocation: ApplyAllocationUrl + 'AddApplyAllocation',
        RechargeApplyAllocation: ApplyAllocationUrl + 'RechargeApplyAllocation',
    },
    ApplyOrder: {
        GetApplyOrderList: ApplyOrderUrl + 'GetApplyOrderList',
        GetApplyOrder: ApplyOrderUrl + 'GetApplyOrder',
        AddApplyOrder: ApplyOrderUrl + 'AddApplyOrder',
        RechargeApplyOrder: ApplyOrderUrl + 'RechargeApplyOrder',
        GetAllocationApplyOrderList: ApplyOrderUrl + 'GetAllocationApplyOrderList',
    },
    ApplyMember: {
        GetApplyMemberList: ApplyMemberUrl + 'GetApplyMemberList',
        SearchApplyMember: ApplyMemberUrl + 'SearchApplyMember',
        SetActiveApplyMember: ApplyMemberUrl + 'SetActiveApplyMember',
    },
    ApplyConsume: {
        GetApplyConsumeList: ApplyConsumeUrl + 'GetApplyConsumeList',
    },
    ApplyEmail: {
        GetApplyEmailList: ApplyEmailUrl + 'GetApplyEmailList',
    },
    YearOrder: {
        GetYearOrderList: YearOrderUrl + 'GetYearOrderList',
        GetYearOrder: YearOrderUrl + 'GetYearOrder',
        SaveYearOrder: YearOrderUrl + 'SaveYearOrder',
        DeleteYearOrder: YearOrderUrl + 'DeleteYearOrder',
    },
    YearOrderSettle: {
        GetYearOrderSettleList: YearOrderSettleUrl + 'GetYearOrderSettleList',
        SettleVendor: YearOrderSettleUrl + 'SettleVendor',
        SettleSales: YearOrderSettleUrl + 'SettleSales'
    },
    InquiryOrder: {
        GetInquiryOrderList: InquiryOrderUrl + 'GetInquiryOrderList',
        GetInquiryOrder: InquiryOrderUrl + 'GetInquiryOrder',
        SaveInquiryOrder: InquiryOrderUrl + 'SaveInquiryOrder',
        DeleteInquiryOrder: InquiryOrderUrl + 'DeleteInquiryOrder',
        ExportInquiryOrder: InquiryOrderUrl + 'ExportInquiryOrder',
        GetInquiryRecord: InquiryOrderUrl + 'GetInquiryRecord',
        SaveInquiryRecord: InquiryOrderUrl + 'SaveInquiryRecord',
        DeleteInquiryRecord: InquiryOrderUrl + 'DeleteInquiryRecord',
    }
};
export default URL;