import { Button, Col, Divider, Form, FormInstance, Input, message, Modal, Row, Select, Space, Switch, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const VendorEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [connectMode, setConnectMode] = useState<any>({ value: 'API', label: 'API对接' });

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getVendor();
                    break;
                case 2:
                    getVendor();
                    break;
            }

        }
    }, [rest.isOpen])

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
        }
        else {
            formRef.current?.resetFields();
        }
    }

    const getVendor = () => {
        if (rest.vendorID) {
            var params = {
                vendorID: rest.vendorID
            };
            Server.Post(URL.Vendor.GetVendor, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = {
                ...values,
                connectModeEnum: connectMode.value,
                connectMode: connectMode.label
            };


            Server.Post(URL.Vendor.SaveVendor, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        }));
    }

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "供应商"} width={900} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 6 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 18 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'供应商ID'} name={'vendorID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'供应商代码'} name={'code'} initialValue={''} rules={[{ required: true, message: '请输入供应商代码' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'供应商名称'} name={'name'} initialValue={''} rules={[{ required: true, message: '请输入供应商名称' }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'联系人'} name={'contact'} initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'电话'} name={'tel'} initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} labelCol={{ span: 3 }} wrapperCol={{ span: 24 }} label={'地址'} name={'address'} initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'对接方式'} name={'connectMode'} initialValue={connectMode.value}>
                        <Select
                            defaultValue={connectMode.value}
                            value={connectMode.value}
                            labelInValue
                            onSelect={(e) => setConnectMode(e)}
                            style={{ width: '100%' }}
                            options={[
                                { value: 'API', label: 'API对接' },
                                { value: 'H5', label: 'H5对接' }
                            ]} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'是否激活'} name={'isActive'} initialValue={true} valuePropName={'checked'}>
                        <Switch checked />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'预定地址'} name={'bookingUrl'} initialValue={''}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'订单地址'} name={'orderListUrl'} initialValue={''}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} labelCol={{ span: 3 }} wrapperCol={{ span: 24 }} label={'备注'} name={'remark'} initialValue={''}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal >
};
export default VendorEdit