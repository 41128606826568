import { Button, Input, message, Space, Table, Modal, Tag, Dropdown, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import ApplyOrderDetail from "./ApplyOrderDetail";
import moment from "moment";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";
const { confirm } = Modal;

const ApplyOrder = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const refESearch = useRef<any>(null);

    const columns: any[] = [
        {
            key: 'applyOrderNO', title: '申报单号', dataIndex: 'applyOrderNO'
            , fixed: 'left', ellipsis: true, width: 120,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/yearonline/applyorderdetail?oid=' + record.applyOrderID
            }}>{text}</a>
        }, {
            key: 'applyTime', title: '申报日期', dataIndex: 'applyTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                moment(text).format('YYYY-MM-DD')
            )
        }, {
            title: '申报状态', dataIndex: 'state', width: 80,
            render: (text: string, record: any) => {
                return <Tag color={record.stateEnum == 0 ? '' : 'green'}>{text}</Tag>;
            }
        }, {
            key: 'applyType', title: '申报类型', dataIndex: 'applyType',
            align: 'center', width: 100, ellipsis: true,
            render: (text: string, record: any) => (
                text + (record.applyTypeEnum == 0 ? '' : '(' + moment(record.requiredTime).format('YYYY-MM-DD HH:mm') + ')')
            )
        }, {
            title: '发送状态', dataIndex: 'sendState', width: 80,
            render: (text: string, record: any) => {
                return <Tag color={record.sendStateEnum == 0 ? '' : 'green'}>{text}</Tag>;
            }
        }, {
            key: 'sendTime', title: '发送日期', dataIndex: 'sendTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , { key: 'agreementNO', title: '协议号', dataIndex: 'agreementNO', width: 100, ellipsis: true },
        { key: 'partName', title: '货物名称', dataIndex: 'partName', ellipsis: true, width: 120 }
        , {
            key: 'carrierTime', title: '承运日期', dataIndex: 'carrierTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }
        , { key: 'packages', title: '货物包装', dataIndex: 'packages', width: 100, ellipsis: true }
        , { key: 'eaQty', title: '货物件数', dataIndex: 'eaQty', width: 100, ellipsis: true }
        , { key: 'startAddress', title: '始发地', dataIndex: 'startAddress', width: 100, ellipsis: true }
        , { key: 'destAddress', title: '目的地', dataIndex: 'destAddress', width: 100, ellipsis: true }
        , { key: 'truckNO', title: '车牌号', dataIndex: 'truckNO', width: 100, ellipsis: true }
        , { key: 'transportNO', title: '运单号', dataIndex: 'transportNO', width: 100, ellipsis: true }
        , {
            key: 'insureAmount', title: '保额', dataIndex: 'insureAmount', align: 'right'
            , width: 100, ellipsis: true, render: (text: string, record: any) => (
                text + '万元'
            )
        }
        , {
            key: 'applyAllocationNO', title: '保单编码', dataIndex: 'applyAllocationNO'
            , width: 100, ellipsis: true,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID
            }}>{text}</a>
        }
        , { title: '备注', dataIndex: 'remark', width: 200, ellipsis: true }
        , {
            title: '操作', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '查看', onClick: () => {
                            window.location.href = '/yearonline/applyorderdetail?oid=' + record.applyOrderID
                        },
                    },
                    {
                        name: '查看保单', onClick: () => {
                            window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID;
                        },
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }];
    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''))
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.ApplyOrder.GetApplyOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }
    const searchConfig: ISearchConfig[] = [
        { filedName: '申报单号', filedID: 'ApplyAllocationNO' },
        { filedName: '协议号', filedID: 'AgreementNO' },
        { filedName: '货物名称', filedID: 'PartName' },
        { filedName: '货物包装', filedID: 'Packages' },
        { filedName: '始发地', filedID: 'StartAddress' },
        { filedName: '目的地', filedID: 'DestAddress' },
        { filedName: '车牌号', filedID: 'TruckNO' },
        { filedName: '运单号', filedID: 'TransportNO' },
        { filedName: '保单编码', filedID: 'ApplyAllocationNO' },
        { filedName: '备注', filedID: 'Remark' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', title: '申报状态', filedID: 'StateEnum', data: [{ value: 0, label: '待申报' }, { value: 1, label: '已申报' }] },
        { type: 'select', title: '申报类型', filedID: 'ApplyTypeEnum', data: [{ value: 0, label: '即时申报' }, { value: 1, label: '延迟申报' }] },
        { type: 'select', title: '发送状态', filedID: 'SendStateEnum', data: [{ value: 0, label: '未发送' }, { value: 1, label: '已发送' }] },
        { type: 'dateTime', title: '承运日期', filedID: 'CarrierTime', },
        { type: 'dateTime', title: '申报日期', filedID: 'ApplyTime', },
        { type: 'dateTime', title: '发送日期', filedID: 'SendTime', },
    ];

    return <div style={{ height: '100%' }}>
        <div>
            <ESearch searchConfig={searchConfig} moreConfig={moreConfig} resize={resize} onLoad={loadData} ref={refESearch} />
            <div>
                <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                    <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                </div>
                <Table size="small" bordered
                    scroll={{ x: 'max-content', y: scrollY }}
                    columns={columns}
                    dataSource={dataSource}
                    summary={(pageData) => {
                        var totalInsureAmount = 0;
                        pageData.forEach(({ insureAmount }) => {
                            totalInsureAmount += insureAmount;
                        });
                        return <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={1} >
                                    <Typography.Text>{'合计：' + dataSource.length ?? 0}</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={14} ></Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={1} align={'right'}>
                                    <Typography.Text>{totalInsureAmount}{'万元'}</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} colSpan={2} ></Table.Summary.Cell>
                                <Table.Summary.Cell index={4} colSpan={2} ></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    }}
                    pagination={{
                        position: ['bottomRight'],
                        style: { margin: '5px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }} />
            </div>
        </div>
    </div>
}
export default ApplyOrder;