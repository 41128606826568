import { Button, Card, Col, DatePicker, Descriptions, Divider, Empty, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useFetcher } from "react-router-dom";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const ApplyAllocationEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [company, setCompany] = useState<any>(null);
    const [member, setMember] = useState<any>(null);
    const [sales, setSales] = useState<any>(null);
    const [applyProduct, setApplyProduct] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);
    const [salesData, setSalesData] = useState<any>([]);
    const [applyProductData, setApplyProductData] = useState<any>([]);

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
            }
            loadCompany();
            loadSales();
            var userInfo = localStorage.getItem('EKnown.UserInfo');
            if (userInfo) {
                var user = JSON.parse(userInfo);
                formRef.current?.setFieldValue('organizationName', user.organizationName)
            }
        }
    }, [rest.isOpen])

    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const loadApplyProduct = () => {
        var params = {
            companyID: company.value
        }
        Server.Post(URL.ApplyProduct.GetApplyProductOptions, params, false).then((response: any) => {
            if (response.isSuccess) {
                setApplyProductData(response.data);
                if (response.data && response.data.length > 0) {
                    setApplyProduct(response.data[0]);
                    formRef.current?.setFieldValue('applyProduct', response.data[0]);
                } else {
                    setApplyProduct(null);
                    formRef.current?.setFieldValue('applyProduct', null);
                }
            }
        });
    }

    const loadSales = () => {
        Server.Post(URL.Sales.GetSalesOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setSalesData(response.data);
            }
        })
    }

    const setData = (record: any) => {
        formRef.current?.resetFields();
        setCompany(null);
        setApplyProduct(null);
        setMember(null);
        setSales(null);
    }

    useEffect(() => {
        if (company) {
            loadApplyProduct();
        } else {
            setApplyProduct(null);
            formRef.current?.setFieldValue('applyProduct', null);
        }
    }, [company])


    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            if (!company) {
                message.warn('请选择保险公司');
                return;
            }
            if (!applyProduct) {
                message.warn('请选择申报产品');
                return;
            }
            if (!member) {
                message.warn('请检索已注册的会员');
                return;
            } if (!sales) {
                message.warn('请选择业务员');
                return;
            }
            if (values.amount <= 0) {
                message.warn('签约金必须大于0');
                return;
            }
            var params = {
                ...values,
                companyID: company.value,
                companyName: company.label,
                applyProductID: applyProduct.value,
                applyProductName: applyProduct.label,
                memberID: member.memberID,
                memberName: member.memberName,
                salesID: sales.value,
                salesName: sales.label
            };

            Server.Post(URL.ApplyAllocation.AddApplyAllocation, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    var loadMember = ((value: any) => {
        if (value) {
            var params = {
                searchValue: value
            };
            Server.Post(URL.ApplyMember.SearchApplyMember, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setMember({
                        memberID: response.memberID,
                        account: response.account,
                        memberName: response.memberName,
                        tel: response.tel,
                    })
                } else {
                    setMember(null);
                    message.warning(response.returnMsg);
                }
            })
        } else {
            setMember(null);
        }
    })

    useEffect(() => {
        console.log(applyProduct);
        if (applyProduct) {
            console.log(applyProduct.data);
            console.log(JSON.parse(applyProduct.data));
        }
    }, [applyProduct])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal title={"签约保单"} width={1200} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}>
            <Form.Item hidden label={'申报产品ID'} name={'applyAllocationID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Row>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'保险公司'} name={'company'} initialValue={company?.value} rules={[{ required: true, message: '请选择保险公司' }]}>
                        <Select defaultValue={company?.value}
                            value={company?.value}
                            labelInValue
                            placeholder={'选择保险公司'}
                            onSelect={(e) => {
                                setCompany(e);
                            }}
                            style={{ width: '100%' }}
                            options={companyData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'保险产品'} name={'applyProduct'} initialValue={applyProduct?.value} rules={[{ required: true, message: '请选择申报产品' }]}>
                        <Select defaultValue={applyProduct?.value}
                            value={applyProduct?.value}
                            labelInValue
                            placeholder={'选择申报产品'}
                            onSelect={(e) => {
                                console.log(e);
                                var items = applyProductData.filter((x: any) => x.value == e.key)
                                if (items && items.length > 0) {
                                    setApplyProduct(items[0]);
                                    console.log(items[0]);
                                }
                            }}
                            style={{ width: '100%' }}
                            options={applyProductData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'检索会员'} name={'member'} initialValue={''} rules={[{ required: member ? false : true, message: '请选择已注册的会员' }]}>
                        <Input.Search placeholder="账号/手机号码"
                            onSearch={(e) => {
                                loadMember(e);
                            }}
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setMember(null);
                                }
                            }}
                            allowClear
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={16} >
                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 24 }}>
                        <Card size="small" title={'保险产品'} style={{ width: '100%' }} bodyStyle={{ padding: '0' }} >
                            {applyProduct ?
                                <Descriptions layout="horizontal" bordered size="small"
                                    column={1}>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="产品名称" >{JSON.parse(applyProduct.data).ApplyProductName}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="保险公司" >{JSON.parse(applyProduct.data).CompanyName}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="投保限额" >{JSON.parse(applyProduct.data).SingleLimit}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="免赔额" >
                                        <TextArea autoSize bordered={false} readOnly value={JSON.parse(applyProduct.data).DeductibleAmountDesc} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} labelStyle={{ width: '100px' }} label="承保区域" >{JSON.parse(applyProduct.data).InsureArea}</Descriptions.Item>
                                </Descriptions> :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'请选择保险产品'} />}
                        </Card>
                    </Form.Item>
                </Col>
                <Col span={8} >
                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 24 }}
                        style={{ marginLeft: '5px' }} >
                        <Card size="small" title={'会员信息'} style={{ width: '100%' }} bodyStyle={{ padding: '0' }} >
                            {member ?
                                <Descriptions layout='horizontal' bordered size="small"
                                    column={1}>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="用户账号" >{member.account}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="用户名称" >{member.memberName}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ width: '100px' }} label="手机号码" >{member.tel}</Descriptions.Item>
                                </Descriptions> :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'请检索会员'} />}
                        </Card>
                    </Form.Item>
                </Col>
            </Row>
            <Divider style={{ padding: '5px', margin: 0 }} />
            <Row >
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'投保人'} name={'insureBy'} initialValue={''} rules={[{ required: true, message: '请填写投保人' }]}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'被保人'} name={'insuredBy'} initialValue={''} rules={[{ required: true, message: '请填写被保人' }]}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'签约金额'} name={'amount'} initialValue={''} rules={[{ required: true, message: '请填写签约金额' }]}>
                        <InputNumber min={1} style={{ width: '100%' }} addonAfter={'万元'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'生效时间'} name={'effectTime'} initialValue={moment()} rules={[{ required: true, message: '请选择生效时间' }]}>
                        <DatePicker
                            onChange={(e) =>
                                formRef.current?.setFieldValue('expireTime', moment(e).add('day', 365))
                            }
                            defaultValue={moment()}
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'失效时间'} name={'expireTime'} initialValue={moment().add('day', 365)} rules={[{ required: true, message: '请选择失效时间' }]}>
                        <DatePicker
                            disabledDate={(current) => {
                                return current &&
                                    (current < formRef.current?.getFieldValue('effectTime').startOf('days'));
                            }}
                            defaultValue={moment().add('day', 365)}
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'所属公司'} name={'organizationName'} initialValue={''}>
                        <Input readOnly disabled style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px', width: '100%' }}
                        label={'协议号'} name={'AgreementNO'} initialValue={''} rules={[{ required: true, message: '请填写协议号' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px', width: '100%' }}
                        label={'备注'} name={'remark'} initialValue={''}  >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'业务员'} name={'sales'} initialValue={sales?.value} rules={[{ required: true, message: '请选择业务员' }]}>
                        <Select defaultValue={sales?.value}
                            value={sales?.value}
                            labelInValue
                            placeholder={'选择业务员'}
                            onSelect={(e) => {
                                setSales(e);
                            }}
                            style={{ width: '100%' }}
                            options={salesData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal >
};
export default ApplyAllocationEdit;
