import { Breadcrumb, Button, Card, Descriptions, message, Modal, Space, Table, Tag } from "antd/es";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import UserAuthBUSelect from "../userAuthBUSelect/UserAuthBUSelect";
import UserAuthRoleSelect from "../userAuthRoleSelect/UserAuthRoleSelect";
import { QuestionCircleOutlined, DownOutlined } from '@ant-design/icons';
import EUtils from "../../../../utils/EUtils";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";

const UserAuth = () => {
    const [userID, setUserID] = useState(null);
    const [user, setUser] = useState<any>(null);
    const [dataSource, setDataSource] = useState<any[]>([]);

    const [visibleBUSelect, setVisibleBUSelect] = useState(false);
    const [recordBUSelect, setRecordBUSelect] = useState<any>(false);
    const [visibleRoleSelect, setVisibleRoleSelect] = useState(false);
    const [recordRoleSelect, setRecordRoleSelect] = useState<any>(false);

    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        var userID = EUtils.getUrlParam('userID');
        if (userID) {
            setUserID(userID);
        }
    }, [])

    useEffect(() => {
        if (userID) {
            var request = {
                userID: userID
            };
            Server.Post(URL.User.GetUser, request).then((response: any) => {
                if (response.isSuccess) {
                    setUser(response);
                    loadBUData();
                }
            })
        }
    }, [userID])

    const loadBUData = () => {
        var request = {
            userID: userID
        }
        Server.Post(URL.User.GetUserOrganizationRoleList, request).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        })
    }

    const columns: ColumnsType<any> = [
        {
            title: '组织名称', dataIndex: 'levelName', key: 'levelName', align: 'center', width: 300,
        },
        {
            title: '角色名称', dataIndex: 'roleNames', key: 'roleNames', align: 'center',
            render(value, record, index) {

                return <Space size={[1, 1]} wrap>
                    {value.map((roleName: any, index: number) => {
                        var roleID = record.roleIDs[index];
                        return <Button type="link" onClick={() => {
                            window.location.href = '/system/role/auth?roleID=' + roleID;
                        }}>{roleName}</Button>
                    })}
                </Space>
            },
        }, {
            title: '默认', dataIndex: 'isDefault', key: 'isDefault', align: 'center', width: 100,
            render(value, record, index) {
                return value ? '√' : ''
            },
        }, {
            title: '操作', dataIndex: 'option', key: 'option', align: 'center', width: 120,
            render(value, record, index) {
                var data: any = []
                if (!record.isDefault) {
                    data.push(<Button size="small" onClick={() => onSetDefault(record)} >设为默认</Button>)
                    data.push(<Button size="small" danger type={'primary'} onClick={() => onRemoveBU(record)}>移除组织</Button>)
                }
                data.push(<Button size="small" danger onClick={() => onRemoveRole(record)}>移除角色</Button>)
                return <>
                    <Space>
                        {data}
                    </Space>
                </>
            },
        }]

    const onSetDefault = (record: any) => {
        modal.confirm({
            title: '设为默认组织',
            icon: <QuestionCircleOutlined />,
            content: <>
                <p>{'确定将组织设为用户默认组织？'}</p>
            </>,
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var request = {
                    userID: record.userID,
                    userOrganizationID: record.userOrganizationID
                };
                Server.Post(URL.User.SetDefaultUserOrganization, request).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('设为默认组织成功');
                        loadBUData();
                    } else {
                        message.warning(response.returnMsg);
                    }
                })

            },
            onCancel() { },
        });
    }

    const onRemoveBU = (record: any) => {
        modal.confirm({
            title: '移除组织',
            icon: <QuestionCircleOutlined />,
            content: <>
                <p>{'确定移除用户组织？'}</p>
            </>,
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var request = {
                    userOrganizationID: record.userOrganizationID
                };
                Server.Post(URL.User.RemoveUserOrganization, request).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('移除组织成功');
                        loadBUData();
                    } else {
                        message.warning(response.returnMsg);
                    }
                })

            },
            onCancel() { },
        });
    }

    const onRemoveRole = (record: any) => {
        modal.confirm({
            title: '移除角色',
            icon: <QuestionCircleOutlined />,
            content: <>
                <p>{'确定移除用户组织下' + record.organizationName + '所有角色？'}</p>
            </>,
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var request = {
                    userOrganizationID: record.userOrganizationID
                };
                Server.Post(URL.User.RemoveUserOrganizationRole, request).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('移除角色成功');
                        loadBUData();
                    } else {
                        message.warning(response.returnMsg);
                    }
                })

            },
            onCancel() { },
        });
    }

    return <div >
        <div style={{ borderBottom: '1px solid #d9d9d9', height: '38px', verticalAlign: 'middle' }}>
            <span style={{ fontWeight: 'bold' }}>
                {'用户授权'}
            </span>
            <Space style={{ float: 'right', margin: '2px' }}>
                <Button onClick={() => { window.history.back() }}>返回</Button>
            </Space>
        </div>
        <Card style={{ marginTop: '5px' }} type="inner" size="small" bodyStyle={{ padding: 0, margin: '0px' }}>
            <Descriptions column={3}>
                <Descriptions.Item label={'用户名称'}>{user?.userName}</Descriptions.Item>
                <Descriptions.Item label={'账号'}>{user?.account}</Descriptions.Item>
                <Descriptions.Item label={'管理员'}>{user?.isAdmin ? '是' : '否'}</Descriptions.Item>
            </Descriptions>
        </Card>
        <Card style={{ marginTop: '5px' }} type="inner" size="small" bodyStyle={{ padding: 0 }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', padding: '5px' }}>
                <span style={{ lineHeight: '32px', margin: '5px', fontWeight: 'bold' }}>组织和角色</span>
                <Space style={{ float: 'right' }}>
                    <Button onClick={() => {
                        setRecordRoleSelect({
                            userID: userID,
                        });
                        setVisibleRoleSelect(true);
                    }}>角色授权</Button>
                    {/* <Button type="primary" onClick={() => {
                        setRecordBUSelect({
                            userID: userID,
                        });
                        setVisibleBUSelect(true);
                    }}>添加兼职组织</Button> */}
                </Space>
            </div>
            <Table size="small" bordered columns={columns} dataSource={dataSource} pagination={false} />
        </Card>
        <UserAuthBUSelect visible={visibleBUSelect} setVisible={setVisibleBUSelect} {...recordBUSelect}
            reLoad={loadBUData} />
        <UserAuthRoleSelect visible={visibleRoleSelect} setVisible={setVisibleRoleSelect} {...recordRoleSelect}
            reLoad={loadBUData} />
        {contextHolder}
    </div>
}
export default UserAuth;