import { Button, Input, Space, Table, Modal, message, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from 'moment';
import 'moment/locale/zh-cn';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ApplyMemberEdit from "./ApplyMemberEdit";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { ISearchConfig } from "../../../components/esearch/ESearch";

const { confirm } = Modal;

const ApplyMember = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);

    const refESearch = useRef<any>(null);

    const columns: any[] = [
        {
            title: '会员账号', dataIndex: 'account', fixed: 'left', width: 150
        }
        , { title: '会员名称', dataIndex: 'name', fixed: 'left', width: 150 }
        , {
            title: '启用状态', dataIndex: 'isActive', width: 100,
            render: (text: any) => {
                return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
            }
        }
        , { title: '手机号', dataIndex: 'tel', width: 150 }
        , { title: '会员级别', dataIndex: 'memberType', width: 150 }
        , {
            title: '注册时间', dataIndex: 'createTime', width: 150
            , render: (text: any) => (
                moment(text).format('YYYY-MM-DD hh:mm:ss')
            )
        }
        , { title: '上级代理', dataIndex: 'superiorMember', width: 150 }
        , { title: '所属组织', dataIndex: 'organizationName', width: 150 }
        , {
            title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '启用', onClick: () => {
                            showActiveConfirm(record);
                        },
                        hidden: record.isActive
                    },
                    {
                        name: '禁用', onClick: () => {
                            showActiveConfirm(record);
                        },
                        hidden: !record.isActive,
                        danger: true
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }];

    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [])
    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions(),
        };
        Server.Post(URL.ApplyMember.GetApplyMemberList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用会员' : '启用会员',
            icon: <ExclamationCircleFilled />,
            content: '会员[' + record.account + ']，确认要' + (record.isActive ? '禁用' : '启用'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    memberID: record.memberID,
                    isActive: !record.isActive
                }
                Server.Post(URL.Member.SetActiveMember, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const searchConfig: ISearchConfig[] = [
        { filedID: 'account', filedName: '会员账号' },
        { filedID: 'name', filedName: '会员名称' },
        { filedID: 'tel', filedName: '手机号' },
        { filedID: 'superiorMember', filedName: '上级代理' }
    ];

    return <div>
        <ESearch searchConfig={searchConfig} onLoad={loadData} resize={resize} ref={refESearch} />
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => setIsOpen(true)}>开通会员</Button>
                </Space>
            </div>
            <Table size="small" bordered
                scroll={{ x: 'max-content', y: scrollY }}
                columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>{'合计：' + dataSource.length ?? 0}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={6} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
        <ApplyMemberEdit isOpen={isOpen} setIsOpen={setIsOpen} loadData={loadData} />
    </div>
}
export default ApplyMember;