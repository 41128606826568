import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import {
    UserOutlined,
    LockOutlined
} from '@ant-design/icons';
import BgImage from '../../images/BgImage.jpg';
import Server from '../../server/Server';
import URL from '../../server/URL';

const Login = function () {
    const [name, changeName] = useState<string>('elven');
    const [pwd, changePwd] = useState<string>('123');

    const login = () => {
        var params = { account: name, password: pwd };
        Server.Post(URL.Main.UserLogin, params).then((response: any) => {
            if (response.isSuccess) {
                let tokenKey = process.env.REACT_APP_TOKEN ?? '';
                localStorage.setItem(tokenKey, response.token);
                var userInfo = {
                    account: response.account,
                    userName: response.userName,
                    organizationName: response.organizationName
                };
                localStorage.setItem('EKnown.UserInfo', JSON.stringify(userInfo));
                window.location.href = '/home';
            } else {
                message.warn(response.returnMsg);
            }
        })
    }
    return (
        <div style={{ background: `url(${BgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%', height: '100%' }}>
            <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                    <div style={{ width: '100%', height: '100px' }}>
                        {/* <img src={logo} className="App-logo" alt="logo" /> */}
                    </div>
                    <div style={{ width: '100%', fontSize: '22px', color: 'white' }}>誉旋管理系统</div>
                </div>
                <div>
                    <Form style={{ width: '350px' }}
                        wrapperCol={{ span: 24 }}>
                        <Form.Item style={{ marginBottom: '10px' }} >
                            <Input placeholder='输入用户名' onChange={(e) => { changeName(e.target.value); }} defaultValue={name}
                                prefix={<UserOutlined />} />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '10px' }} >
                            <Input.Password prefix={<LockOutlined />} placeholder='请输入密码' onChange={(e) => { changePwd(e.target.value); }} defaultValue={pwd} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type='primary' onClick={login} block>登 录</Button>
                        </Form.Item>
                    </Form>
                </div>
                {/* <div>
                    <span style={{ float: 'left' }}><Button type='link' onClick={() => { window.location.href = '/forgetpwd'; }}>忘记密码</Button></span>
                    <span style={{ float: 'right' }}><Button type='link' onClick={() => { window.location.href = '/register'; }}>立即注册</Button></span>
                </div> */}
            </div>
        </div>
    );
}

export default Login;