import { Button, Divider, Form, FormInstance, Input, message, Modal, Space, Switch, Table } from "antd";
import Password from "antd/lib/input/Password";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const ApplyMemberEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = values;
            Server.Post(URL.Member.AddMember, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        }));
    }

    return <Modal title={"开通会员"} width={600} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} label={'会员账号'} name={'account'} initialValue={''} rules={[{ required: true, message: '请输入用户账号' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'会员密码'} name={'password'} initialValue={''} rules={[{ required: true, message: '请输入用户密码' }]}>
                <Password />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'会员名称'} name={'name'} initialValue={''} rules={[{ required: true, message: '请输入用户名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'手机号码'} name={'tel'} initialValue={''} rules={[{ required: true, pattern: new RegExp(/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/, 'g'), message: '请输入11位手机号码' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'公司名称'} name={'companyName'} initialValue={''}>
                <Input />
            </Form.Item>
        </Form>
    </Modal>
};
export default ApplyMemberEdit;