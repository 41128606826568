import { Button, Divider, Form, FormInstance, Input, message, Modal, Space, Switch, Table } from "antd";
import Password from "antd/lib/input/Password";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const UserEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.record.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getUser();
                    break;
                case 2:
                    getUser();
                    break;
            }

        }
    }, [rest.isOpen])

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
        }
        else {
            formRef.current?.resetFields();
            formRef.current?.setFieldsValue({
                organizationID: rest.record?.organizationID,
                organizationName: rest.record?.organizationName
            })
        }
    }

    const getUser = () => {
        if (rest.record.userID) {
            var params = {
                userID: rest.record.userID
            };
            Server.Post(URL.User.GetUser, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = values;
            Server.Post(URL.User.SaveUser, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        }));
    }

    return <Modal title={(rest.record.viewMode == 0 ? '新增' : '编辑') + "用户"} width={600} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 10 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'用户ID'} name={'userID'} initialValue={null}>
                <Input readOnly={rest.record.viewMode == 2} />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'公司ID'} name={'organizationID'} initialValue={''}>
                <Input readOnly={rest.record.viewMode == 2} />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'默认组织'} name={'organizationName'} initialValue={''}>
                <Input readOnly disabled />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'用户账号'} name={'account'} initialValue={''} rules={[{ required: true, message: '请输入用户代码' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'用户密码'} name={'password'} initialValue={''} rules={[{ required: true, message: '请输入用户名称' }]}>
                <Password />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'用户名称'} name={'userName'} initialValue={''} rules={[{ required: true, message: '请输入用户名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'手机号码'} name={'tel'} initialValue={''} rules={[{ required: true, pattern: new RegExp(/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/, 'g'), message: '请输入11位手机号码' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'是否激活'} name={'isActive'} initialValue={true} valuePropName={'checked'}>
                <Switch checked />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'是否管理员'} name={'isAdmin'} initialValue={false} valuePropName={'checked'} >
                <Switch checked />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'备注'} name={'remark'} initialValue={''}>
                <Input />
            </Form.Item>
        </Form>
    </Modal>
};
export default UserEdit;