import { Button, Input, message, Space, Table, Modal, Tag, Dropdown, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import moment from "moment";
import ApplyAllocationEdit from "./ApplyAllocationEdit";
import RechargeApplyAllocation from "./RechargeApplyAllocation";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";
const { confirm } = Modal;

const ApplyAllocation = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [record, setRecord] = useState<any>({});
    const refESearch = useRef<any>(null);

    const columns: any[] = [
        {
            key: 'applyAllocationNO', title: '保单编码', dataIndex: 'applyAllocationNO'
            , fixed: 'left', ellipsis: true, width: 100,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID
            }}>{text}</a>
        }, {
            key: 'companyName', title: '保险公司', dataIndex: 'companyName'
            , ellipsis: true, width: 100
        }, {
            key: 'applyProductName', title: '申报产品', dataIndex: 'applyProductName'
            , ellipsis: true, width: 100
        }, {
            key: 'member', title: '会员', dataIndex: 'memberName'
            , ellipsis: true, width: 100
        }, {
            key: 'agreementNO', title: '协议号', dataIndex: 'agreementNO'
            , ellipsis: true, width: 100
        }
        , { key: 'insureBy', title: '投保人', dataIndex: 'insureBy', ellipsis: true, width: 220 }
        , { key: 'insuredBy', title: '被保人', dataIndex: 'insuredBy', ellipsis: true, width: 220 }
        , {
            key: 'amount', title: '累计金额', dataIndex: 'amount', width: 120, ellipsis: true,
            render: (text: string) => {
                return text + '万元';
            }
        }
        , {
            key: 'amount', title: '保单余额', dataIndex: 'usedAmount', width: 120, ellipsis: true,
            render: (text: string, record: any) => {
                return (record.amount - record.usedAmount) + '万元';
            }
        }
        , {
            key: 'effectTime', title: '生效时间', dataIndex: 'effectTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                moment(text).format('YYYY-MM-DD')
            )
        }, {
            key: 'expireTime', title: '失效时间', dataIndex: 'expireTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                <span style={{ color: (moment(text) < moment()) ? 'red' : 'green' }}>{moment(text).format('YYYY-MM-DD')}</span>
            )
        },
        , { key: 'organizationName', title: '所属公司', dataIndex: 'organizationName', width: 120 }
        , { key: 'salesName', title: '业务员', dataIndex: 'salesName', width: 120 }
        , { title: '备注', dataIndex: 'remark', width: 150, ellipsis: true }
        , {
            title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '查看', onClick: () => {
                            window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID
                        },
                        hidden: record.isActive
                    },
                    {
                        name: '充值', onClick: () => {
                            setRecord({
                                applyAllocationID: record.applyAllocationID,
                            })
                            setIsShowRecharge(true);
                        },
                        hidden: moment(record.expireTime) <= moment(),
                        danger: true
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }];
    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.ApplyAllocation.GetApplyAllocationList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }

    const searchConfig: ISearchConfig[] = [
        { filedID: 'ApplyAllocationNO', filedName: '保单编码' },
        { filedID: 'CompanyName', filedName: '保险公司' },
        { filedID: 'ApplyProductName', filedName: '申报产品' },
        { filedID: 'MemberName', filedName: '会员' },
        { filedID: 'AgreementNO', filedName: '协议号' },
        { filedID: 'InsureBy', filedName: '投保人' },
        { filedID: 'InsuredBy', filedName: '被保人' },
        { filedID: 'SalesName', filedName: '业务员' },
        { filedID: 'Remark', filedName: '备注' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', title: '保险公司', filedID: 'CompanyID', dataUrl: URL.Company.GetCompanyOptions },
        { type: 'select', title: '业务员', filedID: 'SalesID', dataUrl: URL.Sales.GetSalesOptions }
    ];

    return <div style={{ height: '100%' }}>
        <div>
            <ESearch searchConfig={searchConfig} moreConfig={moreConfig} onLoad={loadData} resize={resize} ref={refESearch} />
            <div>
                <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                    <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                    <Space style={{ float: 'right' }}>
                        <Button type="primary" onClick={() => {
                            setEditRecord({
                                viewMode: 0,
                                vendorID: null
                            });
                            setIsOpen(true)
                        }}>签约保单</Button>
                    </Space>
                </div>
                <Table size="small" bordered
                    scroll={{ x: 'max-content', y: scrollY }}
                    columns={columns}
                    dataSource={dataSource}
                    summary={(pageData) => {
                        var totalAmount = 0;
                        var totalUsedAmount = 0;
                        pageData.forEach(({ amount, usedAmount }) => {
                            totalAmount += amount;
                            totalUsedAmount += usedAmount;
                        });
                        return <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={1} >
                                    <Typography.Text>{'合计：' + dataSource.length ?? 0}</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={6} >
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={1} >
                                    <Typography.Text>{totalAmount}{'万元'}</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} colSpan={1} >
                                    <Typography.Text>{totalUsedAmount}{'万元'}</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} colSpan={5} >
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} colSpan={2} >
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    }}
                    pagination={{
                        position: ['bottomRight'],
                        style: { margin: '5px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }} />
            </div>
        </div>
        <ApplyAllocationEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
        <RechargeApplyAllocation isOpen={isShowRecharge} {...record} loadData={loadData} setIsOpen={(value: any) => setIsShowRecharge(value)} />
    </div>
}
export default ApplyAllocation;