import { Button, Input, message, Space, Table, Modal, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import ETableList from "../../../components/eTableList/ETableList";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";

const { confirm } = Modal;

const OnlineProduct = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [dataSource, setDataSource] = useState<any>([]);

    const columns: any[] = [
        , { key: 'productName', title: '产品名称', dataIndex: 'productName', fixed: 'left', width: 200 }
        , {
            title: '上线状态', dataIndex: 'isOnline', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">上线</Tag> : <Tag color="red">下线</Tag>;
            }
        }, {
            title: '热门推荐', dataIndex: 'isHot', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">是</Tag> : <Tag color="red">否</Tag>
            }
        }
        , { key: 'vendorName', title: '供应商', dataIndex: 'vendorName', width: 120 }
        , { key: 'companyName', title: '保险公司', dataIndex: 'companyName', width: 120 }
        , { key: 'scope', title: '保险范围', dataIndex: 'scope', width: 80 }
        , { key: 'productType', title: '产品类型', dataIndex: 'productType', width: 80 }
        , { key: 'type', title: '险种类型', dataIndex: 'type', width: 80 }
        , { key: 'rate', title: '费率', dataIndex: 'rate', width: 80 }
        , { key: 'minAmount', title: '底价', dataIndex: 'minAmount', width: 80 }
        , { key: 'limitsAmount', title: '限额', dataIndex: 'limitsAmount', width: 80 }
        , { title: '备注', dataIndex: 'remark', width: 150 }
        , {
            title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '上线', onClick: () => {
                            showOnlineConfirm(record)
                        },
                        hidden: record.isOnline
                    },
                    {
                        name: '下线', onClick: () => {
                            showOnlineConfirm(record)
                        },
                        danger: true,
                        hidden: !record.isOnline
                    },
                    {
                        name: '设置推荐', onClick: () => {
                            showHotConfirm(record)
                        },
                        hidden: record.isHot
                    },
                    {
                        name: '取消推荐', onClick: () => {
                            showHotConfirm(record)
                        },
                        danger: true,
                        hidden: !record.isHot
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }];

    const showOnlineConfirm = (record: any) => {
        confirm({
            title: record.isOnline ? '下线供应商' : '下线供应商',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.productName + ']，确认要' + (record.isOnline ? '下线' : '上线'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    productID: record.productID,
                    isOnline: !record.isOnline
                }
                Server.Post(URL.OnlineProduct.SetOnlineProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isOnline ? '下线' : '上线') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    }
    const showHotConfirm = (record: any) => {
        confirm({
            title: record.isHot ? '取消热门推荐' : '设置热门推荐',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.productName + ']，确认要' + (record.isHot ? '取消' : '设置'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    productID: record.productID,
                    isHot: !record.isHot
                }
                Server.Post(URL.OnlineProduct.SetHotProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isHot ? '取消' : '设置') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    }
    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.OnlineProduct.GetProductList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    const refESearch = useRef<any>(null);
    const searchConfig: ISearchConfig[] = [
        { filedID: 'ProductName', filedName: '保险产品' },
        { filedID: 'VendorName', filedName: '供应商' },
        { filedID: 'CompanyName', filedName: '保险公司' },
        { filedID: 'Scope', filedName: '保险范围' },
        { filedID: 'ProductType', filedName: '产品类型' },
        { filedID: 'Type', filedName: '险种类型' },
        { filedID: 'Remark', filedName: '备注' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', title: '保险公司', filedID: 'CompanyID', dataUrl: URL.Company.GetCompanyOptions },
        { type: 'select', title: '供应商', filedID: 'VendorID', dataUrl: URL.Vendor.GetVendorOptions },
        { type: 'select', filedID: 'isOnline', title: '上线状态', data: [{ value: false, label: '下线' }, { value: true, label: '上线' }] },
        { type: 'select', filedID: 'isHot', title: '热门推荐', data: [{ value: false, label: '否' }, { value: true, label: '是' }] },
    ];

    return <div>
        <ESearch searchConfig={searchConfig} moreConfig={moreConfig} onLoad={loadData} resize={resize} ref={refESearch} />
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
            </div>
            <Table size="small" bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={11} >
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} ></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
    </div>
}
export default OnlineProduct;