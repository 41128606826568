import { Button, Form, FormInstance, InputNumber, message, Modal, Space, Table } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from "moment";
import React from "react";

const RechargeApplyAllocation = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [applyAllocation, setApplyAllocation] = useState<any>({});

    useEffect(() => {
        if (rest.isOpen) {
            var params = {
                applyAllocationID: rest.applyAllocationID
            }
            Server.Post(URL.ApplyAllocation.GetApplyAllocationAmount, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setApplyAllocation(response);
                }
            })
        }
    }, [rest.isOpen])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }
    const handleOk = () => {
        formRef.current?.validateFields().then((values: any) => {
            if (values.amount <= 0) {
                message.warn('充值保额必须大于0');
                return;
            }
            var params = {
                applyAllocationID: applyAllocation.applyAllocationID,
                amount: values.amount
            };
            Server.Post(URL.ApplyAllocation.RechargeApplyAllocation, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('充值成功');
                    formRef.current?.resetFields();
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })
    }
    return <Modal title={"保单充值"} width={400} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button size='middle' onClick={handleCancel}>关闭</Button>
                <Button type='primary' onClick={handleOk} danger size='middle'>立即充值</Button>
            </Space>
        }>
        <Form ref={formRef} size='middle'>
            <FormItem style={{ marginBottom: '5px' }} hidden label={'分配单ID'}>
                <span style={{ width: '100%' }}>{applyAllocation.applyAllocationID ?? 0}(元)</span>
            </FormItem>
            <FormItem style={{ marginBottom: '5px' }} label={'保单编码'}>
                <span style={{ width: '100%' }}>{applyAllocation.applyAllocationNO ?? ''}</span>
            </FormItem>
            <FormItem style={{ marginBottom: '5px' }} label={'总保额'}>
                <span style={{ width: '100%' }}>{applyAllocation.amount ?? 0}(万元)</span>
            </FormItem>
            <FormItem style={{ marginBottom: '5px' }} label={'剩余保额'}>
                <span style={{ width: '100%' }}>{(applyAllocation.amount - applyAllocation.usedAmount) ?? 0}(万元)</span>
            </FormItem>
            <FormItem style={{ marginBottom: '5px' }} label={'充值保额'} initialValue={0} name={'amount'} >
                <InputNumber style={{ width: '100%' }} min={0} addonAfter="(万元)" status="error"
                    step="1" />
            </FormItem>
        </Form>
    </Modal>
}
export default RechargeApplyAllocation;