import { Button, Col, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const ProductEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [scope, setScope] = useState<any>({ value: 0, label: '国内' });
    const [scopeData, setScopeData] = useState<any>([]);
    const [company, setCompany] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);
    const [productType, setProductType] = useState<any>(null);
    const [productTypeData, setProductTypeData] = useState<any>([]);
    const [type, setType] = useState<any>({ value: 0, label: '基本险' });
    const [typeData, setTypeData] = useState<any>([]);
    const [vendor, setVendor] = useState<any>(null);
    const [vendorData, setVendorData] = useState<any>(null);

    useEffect(() => {
        if (rest.isOpen) {
            console.log(rest.viewMode);
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getProduct();
                    break;
                case 2:
                    getProduct();
                    break;
            }
            loadVendor();
            loadCompany();
            loadScope();
            loadProductType();
            loadType();
        }
    }, [rest.isOpen])

    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const loadVendor = () => {
        Server.Post(URL.Vendor.GetVendorOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setVendorData(response.data);
            }
        })
    }

    const loadScope = () => {
        Server.Post(URL.Product.GetScopeOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setScopeData(response.data);
            }
        })
    }

    const loadProductType = () => {
        Server.Post(URL.Product.GetProductTypeOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setProductTypeData(response.data);
            }
        })
    }

    const loadType = () => {
        Server.Post(URL.Product.GetTypeOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setTypeData(response.data);
            }
        })
    }

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
            setVendor({ value: record.vendorID, label: record.vendorName });
            setCompany({ value: record.companyID, label: record.companyName });
            setScope({ value: record.scopeEnum, label: record.scope });
            setProductType({ value: record.productTypeEnum, label: record.productType });
            setType({ value: record.typeEnum, label: record.type });
        }
        else {
            formRef.current?.resetFields();
            setVendor(null);
            setCompany(null);
            setScope({ value: 0, label: '国内' });
            setProductType({ value: 'PH', label: '普货' });
            setType({ value: 0, label: '基本险' });
        }
    }

    const getProduct = () => {
        if (rest.productID) {
            var params = {
                productID: rest.productID
            };
            Server.Post(URL.Product.GetProduct, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = {
                ...values,
                vendorID: vendor.value,
                vendorName: vendor.label,
                scopeEnum: scope.value,
                scope: scope.label,
                companyID: company.value,
                companyName: company.label,
                productTypeEnum: productType.value,
                productType: productType.label,
                typeEnum: type.value,
                type: type.label
            };


            Server.Post(URL.Product.SaveProduct, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    useEffect(() => {
        var companyName = company?.label ?? '';
        var productTypeName = productType?.label ?? '';
        var typeName = type?.label ?? '';
        var productName = companyName + productTypeName + typeName;
        formRef.current?.setFieldValue('productName', productName)
    }, [company, productType, type])


    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "保险产品"} width={1000} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 6 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 18 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'保险产品ID'} name={'productID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'供应商'} name={'vendorID'} initialValue={vendor?.value ?? ''} rules={[{ required: true, message: '请选择供应商' }]}>
                        <Select
                            defaultValue={vendor?.value ?? ''}
                            value={vendor?.value ?? ''}
                            labelInValue
                            placeholder={'选择供应商'}
                            onSelect={(e) => setVendor(e)}
                            style={{ width: '100%' }}
                            options={vendorData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'供应商产品ID'} name={'vendorProductID'} initialValue={''} rules={[{ required: true, message: '供应商产品ID' }]}>
                        <Input style={{ width: '100%' }} placeholder={'供应商产品唯一标识'} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'保险公司'} name={'companyID'} initialValue={company?.value} rules={[{ required: true, message: '请选择保险公司' }]}>
                        <Select defaultValue={company?.value}
                            value={company?.value}
                            labelInValue
                            placeholder={'选择保险公司'}
                            onSelect={(e) => {
                                setCompany(e);
                            }}
                            style={{ width: '100%' }}
                            options={companyData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'保险范围'} name={'scopeEnum'} initialValue={scope?.value} rules={[{ required: true, message: '请选择保险范围' }]}>
                        <Select
                            defaultValue={scope?.value}
                            value={scope?.value}
                            labelInValue
                            placeholder={'选择保险范围'}
                            onSelect={(e) => setScope(e)}
                            style={{ width: '100%' }}
                            options={scopeData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'产品类型'} name={'productTypeEnum'} initialValue={productType?.value} rules={[{ required: true, message: '请选择产品类型' }]}>
                        <Select defaultValue={productType?.value}
                            value={productType?.value}
                            labelInValue
                            placeholder={'选择产品类型'}
                            onSelect={(e) => {
                                setProductType(e);
                            }}
                            style={{ width: '100%' }}
                            options={productTypeData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'保险险种'} name={'typeEnum'} initialValue={type?.value} rules={[{ required: true, message: '请选择保险险种' }]}>
                        <Select defaultValue={type?.value}
                            value={type?.value}
                            labelInValue
                            placeholder={'选择保险险种'}
                            onSelect={(e) => {
                                setType(e);
                            }}
                            style={{ width: '100%' }}
                            options={typeData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label={'产品名称'} name={'productName'} initialValue={''}>
                        <Input readOnly disabled style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'费率'} name={'rate'} initialValue={'1'}>
                        <InputNumber style={{ width: '100%' }} min={0} step="0.01" addonBefore="万分之" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'底价'} name={'minAmount'} initialValue={'1'}>
                        <InputNumber style={{ width: '100%' }} min={1} step="0.01" addonAfter="元" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'限额'} name={'limitsAmount'} initialValue={'300'}>
                        <InputNumber style={{ width: '100%' }} max={300} step="0.01" addonAfter="万元" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'启用'} name={'isActive'} initialValue={true} valuePropName={'checked'}>
                        <Switch checked />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'允许批改'} name={'isAllowUpdate'} initialValue={true} valuePropName={'checked'}>
                        <Switch checked />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'信用代码必填'} name={'isIDRequired'} initialValue={false} valuePropName={'checked'}>
                        <Switch checked />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'打开下载'} name={'isOpenDown'} initialValue={true} valuePropName={'checked'}>
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} labelCol={{ span: 3 }} wrapperCol={{ span: 24 }}
                        label={'备注'} name={'remark'} initialValue={''}  >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
};
export default ProductEdit;
