import { Button, Form, Input, message, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const ProductAddition = (props: any) => {
    const { ...rest } = props;
    const [text, setText] = useState('')

    useEffect(() => {
        if (rest.isOpen) {
            var params = {
                type: rest.type,
                productID: rest.productID
            }
            Server.Post(URL.Product.GetProductAddition, params).then((response: any) => {
                if (response.isSuccess) {
                    setText(response.text);
                }
            })
        }
    }, [rest.isOpen])

    const handleSave = () => {
        var params = {
            type: rest.type,
            productID: rest.productID,
            text: text
        }
        Server.Post(URL.Product.SaveProductAddition, params).then((response: any) => {
            if (response.isSuccess) {
                message.success('保存成功');
                rest.setIsOpen(false);
            }
        })
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    return <Modal title={rest.title ?? ''} width={1000} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Input.TextArea style={{ minHeight: '400px' }} defaultValue={text} value={text} onChange={(e) => { setText(e.target.value) }} />
    </Modal>
}

export default ProductAddition;
