import { Button, Card, Descriptions, Input, List, message, Modal, Select, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import InquiryRecordEdit from "../inquiryRecord/InquiryRecordEdit";
import InquiryEdit from "./InquiryOrderEdit";
import ExportSearch from "./ExportSearch";
import { ExclamationCircleFilled } from '@ant-design/icons';
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";
const { confirm } = Modal;

const { Search } = Input;

const InquiryList = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [dataSource, setDataSource] = useState<any>([]);

    const [salesData, setSalesData] = useState<any>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const [isOpenRecord, setIsOpenRecord] = useState(false);
    const [editRecordRecord, setEditRecordRecord] = useState<any>({});

    const [isOpenExport, setIsOpenExport] = useState(false);

    const [searchValue, setSearchValue] = useState<any>(null);
    const [inquiryMonth, setInquiryMonth] = useState<any>(moment().format('M'));
    const [sales, setSales] = useState<any>(null);
    const refESearch = useRef<any>(null);

    const columns: any[] = [
        {
            key: 'inquiryOrderNO', title: '询价单号', dataIndex: 'inquiryOrderNO'
            , fixed: 'left', ellipsis: true, width: 100,
            render: (text: string, record: any) => <a onClick={() => {
                setEditRecord({
                    viewMode: 2,
                    inquiryID: record.inquiryID
                });
                setIsOpen(true);
            }}>{text}</a>
        }, {
            key: 'count', title: '记录数'
            , fixed: 'left', ellipsis: true, width: 100,
            render: (text: string, record: any) => {
                return '(' + (record?.records?.length ?? 0) + '笔)';
            }
        }, {
            key: 'customerName', title: '投保客户', dataIndex: 'customerName'
            , ellipsis: true, width: 220
        }, {
            key: 'cityFullName', title: '所在城市', dataIndex: 'cityFullName'
            , ellipsis: true, width: 100
        }, {
            key: 'inquiryDate', title: '询价日期', dataIndex: 'inquiryDate'
            , ellipsis: true, width: 120,
            render: (text: string, record: any) => {
                return moment(text).format('YYYY-MM-DD')
            }
        }, {
            key: 'inquiryMonth', title: '询价月份', dataIndex: 'inquiryMonth'
            , ellipsis: true, width: 100,
            render: (text: string, record: any) => {
                return text ? (text + '月') : ''
            }
        }, {
            key: 'salesName', title: '业务员', dataIndex: 'salesName'
            , ellipsis: true, width: 120
        }, {
            key: 'remark', title: '说明', dataIndex: 'remark'
            , ellipsis: true, width: 320
        }, {
            key: 'createBy', title: '创建人', dataIndex: 'createBy', width: 100,
            align: 'center', ellipsis: true
        },
        {
            key: 'createTime', title: '创建时间', dataIndex: 'createTime', width: 100,
            align: 'center', ellipsis: true, render: (text: string, record: any) => (
                moment(text).format('YYYY-MM-DD HH:mm:ss')
            )
        }, {
            title: '操作', align: 'center', width: 80, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '查看', onClick: () => {
                            setEditRecord({
                                viewMode: 2,
                                inquiryOrderID: record.inquiryOrderID,
                                inquiryOrderNO: record.inquiryOrderNO
                            });
                            setIsOpen(true);
                        },
                    },
                    {
                        name: '添加记录', onClick: () => {
                            setEditRecordRecord({
                                viewMode: 0,
                                inquiryOrderID: record.inquiryOrderID,
                                inquiryOrderNO: record.inquiryOrderNO
                            });
                            setIsOpenRecord(true);
                        }
                    },
                    {
                        name: '编辑', onClick: () => {
                            setEditRecord({
                                viewMode: 1,
                                productID: record.productID
                            });
                            setIsOpen(true);
                        },
                        hidden: record?.records && record?.records.length > 0
                    },
                    {
                        name: '删除', onClick: () => { showDeleteConfirm(record); }, danger: true, hidden: record?.records && record?.records.length > 0
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }];

    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
            options: refESearch.current.getOptions(),
        };
        Server.Post(URL.InquiryOrder.GetInquiryOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除询价单',
            icon: <ExclamationCircleFilled />,
            content: '询价单[' + record.inquiryOrderNO + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { inquiryOrderID: record.inquiryOrderID }
                Server.Post(URL.InquiryOrder.DeleteInquiryOrder, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('删除成功!');
                        loadData();
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const showDeleteRecordConfirm = (record: any) => {
        confirm({
            title: '删除询价记录',
            icon: <ExclamationCircleFilled />,
            content: '询价记录[' + record.insuranceType + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { inquiryRecordID: record.inquiryRecordID }
                Server.Post(URL.InquiryOrder.DeleteInquiryRecord, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('删除成功!');
                        loadData();
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const searchConfig: ISearchConfig[] = [
        { filedID: 'InquiryOrderNO', filedName: '询价单号' },
        { filedID: 'CustomerName', filedName: '客户名称' },
        { filedID: 'CityFullName', filedName: '城市名称' },
        { filedID: 'SalesName', filedName: '业务员' },
        { filedID: 'Remark', filedName: '说明' }
    ];

    const moreConfig: IMoreConfig[] = [
        {
            type: 'select', title: '询价月份', filedID: 'InquiryMonth', data: [
                { value: '1', label: '1月' },
                { value: '2', label: '2月' },
                { value: '3', label: '3月' },
                { value: '4', label: '4月' },
                { value: '5', label: '5月' },
                { value: '6', label: '6月' },
                { value: '7', label: '7月' },
                { value: '8', label: '8月' },
                { value: '9', label: '9月' },
                { value: '10', label: '10月' },
                { value: '11', label: '11月' },
                { value: '12', label: '12月' },
            ]
        },
        { type: 'select', title: '业务员', filedID: 'SalesID', dataUrl: URL.Sales.GetSalesOptions }
    ]

    return <>
        <ESearch
            searchConfig={searchConfig}
            moreConfig={moreConfig}
            resize={resize}
            onLoad={loadData}
            ref={refESearch} />
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            inquirylD: null
                        });
                        setIsOpen(true)
                    }}>新增</Button>
                    <Button onClick={() => {
                        setIsOpenExport(true);
                    }}>导出</Button>
                </Space>
            </div>
            <Table size="small" bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns}
                dataSource={dataSource}
                rowKey={'inquiryOrderID'}
                expandable={{
                    expandedRowRender: (record) => {
                        return <p style={{}}>
                            <List size="small">
                                {record.records.map((item: any) => {
                                    return <List.Item>
                                        <Descriptions
                                            bordered
                                            labelStyle={{ width: '80px' }}
                                            title={<>
                                                <Space size={'small'}>
                                                    <span style={{ fontSize: '12px' }}>记录日期：{moment(item.updateTime).format('YYYY-MM-DD HH:mm')}</span>
                                                    <span style={{ fontSize: '12px' }}>记录人：{item.updateBy}</span>
                                                    <Tag color='warning'>{item.insuranceType}</Tag>
                                                    <Tag color='success'>{item.companyName}</Tag>
                                                </Space>
                                            </>}
                                            size={'small'}
                                            style={{ width: '100%', padding: 0, margin: 0 }}
                                            column={1}
                                            extra={
                                                <Space>
                                                    <Button size="small" type="primary" onClick={() => {
                                                        setEditRecordRecord({
                                                            viewMode: 2,
                                                            inquiryRecordID: item.inquiryRecordID,
                                                            inquiryOrderID: item.inquiryOrderID,
                                                            inquiryOrderNO: item.inquiryOrderNO
                                                        });
                                                        setIsOpenRecord(true);
                                                    }}>查看</Button>
                                                    <Button size="small" onClick={() => {
                                                        setEditRecordRecord({
                                                            viewMode: 1,
                                                            inquiryRecordID: item.inquiryRecordID,
                                                            inquiryOrderID: item.inquiryOrderID,
                                                            inquiryOrderNO: item.inquiryOrderNO
                                                        });
                                                        setIsOpenRecord(true);
                                                    }}>编辑</Button>
                                                    <Button type="primary" size="small" danger onClick={() => {
                                                        showDeleteRecordConfirm(item);
                                                    }}>删除</Button>
                                                </Space>
                                            }
                                        >
                                            <Descriptions.Item label={'需求：'} labelStyle={{ fontWeight: 'bold' }}>
                                                {item.required}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'结果：'} labelStyle={{ fontWeight: 'bold' }}>
                                                {item.result}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </List.Item>
                                })}
                            </List>
                        </p>
                    },
                    rowExpandable: (record) => record.records && record.records.length > 0,
                }}
                pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={1} >
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={8} >
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4} colSpan={2} ></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
        <InquiryEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
        <InquiryRecordEdit isOpen={isOpenRecord} setIsOpen={() => setIsOpenRecord(false)} loadData={loadData} {...editRecordRecord} />
        <ExportSearch isOpen={isOpenExport} setIsOpen={() => setIsOpenExport(false)} />
    </>
}
export default InquiryList;