import { Button, Card, Col, DatePicker, Descriptions, Form, FormInstance, Input, InputNumber, message, Row, Select, Space, Modal, Tag } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils from "../../../utils/EUtils";
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import CustomerEdit from "../../masterdata/customer/CustomerEdit";
import CompanyEdit from "../../masterdata/company/CompanyEdit";
import VendorEdit from "../../masterdata/vendor/VendorEdit";
import SalesEdit from "../../masterdata/sales/SalesEdit";
const { confirm } = Modal;

const YearOrderEdit = () => {
    const formRef = useRef<FormInstance>(null);
    const [viewMode, setViewMode] = useState<number>(0);
    const [title, setTitle] = useState('年保记录')
    const [yearOrderID, setYearOrderID] = useState<any>(null);
    const [record, setRecord] = useState<any>(null);

    const [company, setCompany] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);

    const [isOpenCompany, setIsOpenCompany] = useState(false);
    const [editRecordCompany, setEditRecordCompany] = useState<any>({});

    const [vendor, setVendor] = useState<any>(null);
    const [vendorData, setVendorData] = useState<any>(null);

    const [isOpenVendor, setIsOpenVendor] = useState(false);
    const [editRecordVendor, setEditRecordVendor] = useState<any>({});

    const [sales, setSales] = useState<any>(null);
    const [salesData, setSalesData] = useState<any>([]);

    const [isOpenSales, setIsOpenSales] = useState(false);
    const [editRecordSales, setEditRecordSales] = useState<any>({});

    const [customer, setCustomer] = useState<any>(null);
    const [customerData, setCustomerData] = useState<any>([]);

    const [isOpenCustomer, setIsOpenCustomer] = useState(false);
    const [editRecordCustomer, setEditRecordCustomer] = useState<any>({});

    useEffect(() => {
        var params = EUtils.getUrlParams();
        console.log(params);
        if (params && params.yearOrderID) {
            setYearOrderID(params.yearOrderID);
        }
        if (params && params.viewMode) {
            setViewMode(Number.parseInt(params.viewMode));
        } else {
            setViewMode(0);
        }

        loadCompany();
        loadCustomer();
        loadVendor();
        loadSales();
    }, [])

    const loadCustomer = () => {
        Server.Post(URL.Customer.GetCustomerOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCustomerData(response.data);
            }
        })
    }

    useEffect(() => {
        switch (viewMode) {
            case 0:
                setTitle('年保记录新建');
                break;
            case 1:
                setTitle('年保记录编辑');
                break;
            case 2: setTitle('年保记录详情');
                break;
        }
    }, [viewMode])


    useEffect(() => {
        if (yearOrderID) {
            var params = {
                yearOrderID: yearOrderID
            }
            Server.Post(URL.YearOrder.GetYearOrder, params, false)
                .then((response: any) => {
                    if (response.isSuccess) {
                        setRecord(response);
                        formRef.current?.setFieldsValue({
                            ...response,
                            customer: { value: response.customerID, label: response.customerName },
                            company: { value: response.companyID, label: response.companyName },
                            vendor: { value: response.vendorID, label: response.vendorName },
                            sales: { value: response.salesID, label: response.salesName },
                            insureTime: moment(response.insureTime),
                            effectTime: moment(response.effectTime),
                            expireTime: moment(response.expireTime),
                        });
                        setCustomer({ value: response.customerID, label: response.customerName });
                        setCompany({ value: response.companyID, label: response.companyName });
                        setVendor({ value: response.vendorID, label: response.vendorName });
                        setSales({ value: response.salesID, label: response.salesName });
                    } else {
                        message.warn(response.returnMsg);
                        window.close();
                    }
                })

        } else {
            setCompany(null);
            setVendor(null);
            setSales(null);
            formRef.current?.resetFields();
        }
    }, [yearOrderID])

    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const loadVendor = () => {
        Server.Post(URL.Vendor.GetVendorOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setVendorData(response.data);
            }
        })
    }

    const loadSales = () => {
        Server.Post(URL.Sales.GetSalesOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setSalesData(response.data);
            }
        })
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {

            var params = {
                ...values,
                customerID: customer.value,
                customerName: customer.label,
                companyID: company.value,
                companyName: company.label,
                vendorID: vendor.value,
                vendorName: vendor.label,
                salesID: sales.value,
                salesName: sales.label,
                insureTime: moment(values.insureTime).format("YYYY-MM-DD")
            };

            console.log(params);
            Server.Post(URL.YearOrder.SaveYearOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    window.location.href = '/yearonline/yearorderedit?yearOrderID=' + response.yearOrderID + '&viewMode=2';
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除年保记录',
            icon: <ExclamationCircleFilled />,
            content: '年保记录[' + record.yearOrderNO + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { yearOrderID: record.yearOrderID }
                Server.Post(URL.YearOrder.DeleteYearOrder, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('删除成功!');
                        window.close();
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const caculateAmount = () => {
        var amount = formRef.current?.getFieldValue('amount');
        var vendorRate = formRef.current?.getFieldValue('vendorRate');
        var salesRate = formRef.current?.getFieldValue('salesRate');
        var vendorAmount = amount * vendorRate;
        var salesAmount = amount * salesRate;
        formRef.current?.setFieldValue('vendorAmount', vendorAmount);
        formRef.current?.setFieldValue('salesAmount', salesAmount);
        formRef.current?.setFieldValue('profitAmount', (vendorAmount - salesAmount).toFixed(2));
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <div>
        <div style={{ height: '35px', marginTop: '5px', marginBottom: '5px' }}>
            <span style={{ fontWeight: 'bold' }}>
                {title}
            </span>
            <Space style={{ float: 'right' }}>
                {viewMode == 2 ? <Button onClick={() => {
                    window.location.href = '/yearonline/yearorderedit';
                }}>新建</Button> : <></>}
                {record && (record.vendorSettleStateEnum != 1 && record.salesSettleStateEnum != 1) ?
                    <>
                        {viewMode == 2 ? <Button type='primary' onClick={() => {
                            window.location.href = '/yearonline/yearorderedit?yearOrderID=' + yearOrderID + '&viewMode=1'
                        }}>编辑</Button> : <></>}
                    </> : <></>}
                {viewMode != 2 ? <Button type='primary' onClick={() => {
                    handleSave()
                }}>保存</Button> : <></>}
                {record && (record.vendorSettleStateEnum != 1 && record.salesSettleStateEnum != 1) ?
                    <>
                        {viewMode == 2 ? <Button type='primary' danger onClick={() => { showDeleteConfirm(record) }}>删除</Button> : <></>}
                    </> : <></>}
                <Button onClick={() => { window.close() }}>返回</Button>
            </Space>
        </div>
        <Card title={<>
            <span>{'年保记录[' + (record ? record.yearOrderNO : 'NULL') + ']'}</span>
            {record ? <Space style={{ float: 'right' }}>
                <Tag color={(record.vendorSettleStateEnum == 1) ? 'green' : 'warning'}>{('佣金' + record.vendorSettleState)}</Tag>
                <Tag color={(record.salesSettleStateEnum == 1) ? 'green' : 'warning'}>{('提成' + record.salesSettleState)}</Tag>
            </Space> : ''}
        </>} type="inner" size="small" bodyStyle={{ padding: '0px' }}
            style={{ marginBottom: '5px' }}>
            {viewMode == 2 ?
                <Descriptions layout="horizontal" bordered size="small"
                    labelStyle={{ width: '90px' }}
                    column={4}>
                    <Descriptions.Item span={2} label="保险公司" >{record ? record.companyName : ''}</Descriptions.Item>
                    <Descriptions.Item label="保险险种" >{record ? record.insuranceType : ''}</Descriptions.Item>
                    <Descriptions.Item label="保险方案" >{record ? record.insurancePlan : ''}</Descriptions.Item>
                    <Descriptions.Item label="业务归属" >{record ? record.sourceType : ''}</Descriptions.Item>
                    <Descriptions.Item label="出单时间" >{record ? moment(record.insureTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item label="生效时间" >{record ? moment(record.effectTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item label="到期时间" >{record ? moment(record.expireTime).format("YYYY-MM-DD") : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} label="投保客户" >{record ? record.customerName : ''}</Descriptions.Item>
                    <Descriptions.Item label="保单号" >{record ? record.insuranceNO : ''}</Descriptions.Item>
                    <Descriptions.Item label="保费" >{record ? record.amount : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} label="供应商" >{record ? record.vendorName : ''}</Descriptions.Item>
                    <Descriptions.Item label="佣金比例" >{record ? record.vendorRate : ''}</Descriptions.Item>
                    <Descriptions.Item label="佣金金额" >{record ? record.vendorAmount : ''}</Descriptions.Item>
                    <Descriptions.Item span={2} label="业务员" >{record ? record.salesName : ''}</Descriptions.Item>
                    <Descriptions.Item label="提成比例" >{record ? record.salesRate : ''}</Descriptions.Item>
                    <Descriptions.Item label="提成金额" >{record ? record.salesAmount : ''}</Descriptions.Item>
                    <Descriptions.Item span={3} label="说明" >{record ? record.remark : ''}</Descriptions.Item>
                    <Descriptions.Item label="毛利" contentStyle={{ fontWeight: 'bold', color: record && record.profitAmount > 0 ? 'green' : 'red' }}>{record ? record.profitAmount : ''}</Descriptions.Item>
                </Descriptions> :
                <Form ref={formRef} labelCol={{
                    xs: { span: 24 },
                    sm: { span: 6 }
                }} wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 18 }
                }}
                    style={{ margin: '5px' }}
                >
                    <Row>
                        <Form.Item hidden
                            style={{ marginBottom: '5px' }} label='年保ID'
                            name={'yearOrderID'} initialValue={yearOrderID}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item hidden
                            style={{ marginBottom: '5px' }} label='年保编号'
                            name={'yearOrderNO'} initialValue={''}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col span={12} >
                            <Form.Item style={{ marginBottom: '5px' }} labelCol={{ span: 3 }}
                                wrapperCol={{ span: 24 }} label={'保险公司'} name={'company'}
                                initialValue={company?.value} rules={[{ required: true, message: '请选择保险公司' }]}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Select defaultValue={company?.value}
                                        value={company?.value}
                                        labelInValue
                                        placeholder={'选择保险公司'}
                                        onSelect={(e) => {
                                            setCompany(e);
                                            formRef.current?.setFieldValue('company', e)
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: '100%' }}
                                        options={companyData} />
                                    <Button style={{ width: '50px', marginLeft: '5px' }}
                                        onClick={() => {
                                            setEditRecordCompany({
                                                viewMode: 0,
                                                companyID: null
                                            });
                                            setIsOpenCompany(true)
                                        }}><PlusOutlined /></Button>
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='保险险种' name={'insuranceType'}
                                initialValue={'货运险'}>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={[
                                        { value: '货运险', label: '货运险' },
                                        { value: '物流责任险', label: '物流责任险' },
                                        { value: '雇主责任险', label: '雇主责任险' },
                                        { value: '团体意外险', label: '团体意外险' },
                                        { value: '仓储险', label: '仓储险' },
                                    ]} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='保险方案' name={'insurancePlan'}
                                initialValue={'年保'}>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={[
                                        { value: '年保', label: '年保' },
                                        { value: '批单', label: '批单' },
                                        { value: '超赔', label: '超赔' },
                                    ]} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Form.Item label='业务归属' style={{ marginBottom: '5px' }} name={'sourceType'}
                                initialValue={'誉旋科技'}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    style={{ width: '100%' }}
                                    options={[
                                        { value: '誉旋科技', label: '誉旋科技' },
                                        { value: '沐明保险', label: '沐明保险' },
                                    ]} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label='出单时间' style={{ marginBottom: '5px' }} name={'insureTime'}
                                initialValue={moment()}
                                rules={[{ required: true, message: '请选择出单时间' }]}>
                                <DatePicker
                                    // disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                    onChange={(e) => {
                                        var effectTime = formRef.current?.getFieldValue('effectTime');
                                        if (effectTime < moment(e)) {
                                            formRef.current?.setFieldValue('effectTime', moment(e));
                                            formRef.current?.setFieldValue('expireTime', moment(e).add('day', 365));
                                        }
                                    }}
                                    defaultValue={moment()}
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='生效日期' name={'effectTime'}
                                initialValue={moment()}
                                rules={[{ required: true, message: '请选择生效日期' }]}>
                                <DatePicker
                                    disabledDate={(current) => {
                                        return current &&
                                            (current < formRef.current?.getFieldValue('insureTime')?.startOf('days'));
                                    }}
                                    onChange={(e) => {
                                        formRef.current?.setFieldValue('expireTime', moment(e).add('day', 365));
                                    }}
                                    defaultValue={moment()}
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='到期日期' name={'expireTime'}
                                initialValue={moment().add('day', 365)}
                                rules={[{ required: true, message: '请选择到期日期' }]}>
                                <DatePicker
                                    disabledDate={(current) => {
                                        return current &&
                                            (current < formRef.current?.getFieldValue('effectTime')?.startOf('days'));
                                    }}
                                    defaultValue={moment().add('day', 365)}
                                    onChange={(e) => {
                                        formRef.current?.setFieldValue('effectTime', moment(e).add('day', -365));
                                        if (moment(e).add('day', -365) < formRef.current?.getFieldValue('insureTime')) {
                                            formRef.current?.setFieldValue('insureTime', moment(e).add('day', -365));
                                        }
                                    }}
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '5px' }} label='投保客户'
                                name={'customer'} initialValue={customer} rules={[{ required: true, message: '请填写投保客户' }]}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Select defaultValue={customer?.value}
                                        value={customer?.value}
                                        labelInValue
                                        placeholder={'选择投保客户'}
                                        onSelect={(e) => {
                                            setCustomer(e);
                                            formRef.current?.setFieldValue('customer', e)
                                        }}
                                        style={{ width: '100%' }}
                                        options={customerData}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption} />
                                    <Button style={{ width: '50px', marginLeft: '5px' }}
                                        onClick={() => {
                                            setEditRecordCustomer({
                                                viewMode: 0,
                                                customerID: null
                                            });
                                            setIsOpenCustomer(true)
                                        }}><PlusOutlined /></Button>
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='保单号' name={'insuranceNO'} initialValue={''}>
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='保费' name={'amount'}
                                initialValue={1}>
                                <InputNumber min={1} style={{ width: '100%' }}
                                    onChange={(e) => { caculateAmount() }}
                                    addonAfter={'元'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item style={{ marginBottom: '5px' }} labelCol={{ span: 3 }}
                                wrapperCol={{ span: 24 }} label={'供应商'} name={'vendor'}
                                initialValue={vendor?.value ?? ''} rules={[{ required: true, message: '请选择供应商' }]}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Select
                                        defaultValue={vendor?.value ?? ''}
                                        value={vendor?.value ?? ''}
                                        labelInValue
                                        placeholder={'选择供应商'}
                                        onSelect={(e) => {
                                            setVendor(e);
                                            formRef.current?.setFieldValue('vendor', e)
                                        }}
                                        style={{ width: '100%' }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        options={vendorData} />
                                    <Button style={{ width: '50px', marginLeft: '5px' }}
                                        onClick={() => {
                                            setEditRecordVendor({
                                                viewMode: 0,
                                                vendorID: null
                                            });
                                            setIsOpenVendor(true)
                                        }}><PlusOutlined /></Button>
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='佣金比例' name={'vendorRate'}
                                initialValue={0}>
                                <InputNumber style={{ width: '100%' }}
                                    min={0}
                                    max={1}
                                    step={0.001}
                                    onChange={(e) => { caculateAmount() }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='佣金金额' name={'vendorAmount'}
                                initialValue={0}>
                                <InputNumber readOnly style={{ width: '100%' }} addonAfter={'元'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '5px' }} label='业务员' name={'sales'} initialValue={sales?.vale}
                                rules={[{ required: true, message: '请选择业务员' }]}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Select defaultValue={sales?.value}
                                        value={sales?.value}
                                        labelInValue
                                        placeholder={'选择业务员'}
                                        onSelect={(e) => {
                                            setSales(e);
                                            formRef.current?.setFieldValue('sales', e)
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: '100%' }}
                                        options={salesData} />
                                    <Button style={{ width: '50px', marginLeft: '5px' }}
                                        onClick={() => {
                                            setEditRecordSales({
                                                viewMode: 0,
                                                salesID: null
                                            });
                                            setIsOpenSales(true)
                                        }}><PlusOutlined /></Button>
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='提成比例' name={'salesRate'} initialValue={0}>
                                <InputNumber style={{ width: '100%' }}
                                    min={0}
                                    max={1}
                                    step={0.001}
                                    onChange={(e) => { caculateAmount() }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='提成金额' name={'salesAmount'}
                                initialValue={0}>
                                <InputNumber readOnly min={0} style={{ width: '100%' }} addonAfter={'元'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <Form.Item labelCol={{ span: 2 }} wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '5px' }} label='备注' name={'remark'} initialValue={''}>
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label='毛利' name={'profitAmount'}

                                initialValue={0}>
                                <InputNumber disabled readOnly
                                    min={0}
                                    status={formRef.current?.getFieldValue('profitAmount') < 0 ? 'error' : ''}
                                    style={{ width: '100%' }} addonAfter={'元'} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            }
        </Card>
        {viewMode == 2 ? <>
            <Card title={<>
                <span>{'结算信息'}</span>
            </>} type="inner" size="small" bodyStyle={{ padding: '0px' }}>
                <Descriptions layout="horizontal" bordered size="small"
                    labelStyle={{ width: '90px' }}
                    column={4}>
                    <Descriptions.Item label="佣金状态" >{record ? <Tag color={record.vendorSettleStateEnum == 0 ? '' : 'green'}>{record.vendorSettleState}</Tag> : ''}</Descriptions.Item>
                    <Descriptions.Item label="佣金金额" >{record ? (record.vendorAmount + '元') : ''}</Descriptions.Item>
                    <Descriptions.Item label="操作人" >{record && record.vendorSettleBy ? record.vendorSettleBy : '暂无'}</Descriptions.Item>
                    <Descriptions.Item label="结算时间" >{record && record.vendorSettleTime ? moment(record.vendorSettleTime).format("YYYY-MM-DD HH:mm:ss") : '暂无'}</Descriptions.Item>
                    <Descriptions.Item label="提成状态" >{record ? <Tag color={record.salesSettleStateEnum == 0 ? '' : 'green'}>{record.salesSettleState}</Tag> : ''}</Descriptions.Item>
                    <Descriptions.Item label="提成金额" >{record ? (record.salesAmount + '元') : ''}</Descriptions.Item>
                    <Descriptions.Item label="操作人" >{record && record.salesSettleBy ? record.salesSettleBy : '暂无'}</Descriptions.Item>
                    <Descriptions.Item label="结算时间" >{record && record.salesSettleTime ? moment(record.salesSettleTime).format("YYYY-MM-DD HH:mm:ss") : '暂无'}</Descriptions.Item>
                </Descriptions>
            </Card>
        </> : ''}

        <CompanyEdit isOpen={isOpenCompany} setIsOpen={() => setIsOpenCompany(false)} loadData={loadCompany} {...editRecordCompany} />
        <VendorEdit isOpen={isOpenVendor} setIsOpen={() => setIsOpenVendor(false)} loadData={loadVendor} {...editRecordVendor} />
        <CustomerEdit isOpen={isOpenCustomer} setIsOpen={() => setIsOpenCustomer(false)} loadData={loadCustomer} {...editRecordCustomer} />
        <SalesEdit isOpen={isOpenSales} setIsOpen={() => setIsOpenSales(false)} loadData={loadSales} {...editRecordSales} />
    </div>
}
export default YearOrderEdit;