import { Badge, Space, Tag, DatePicker, InputNumber } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import dayjs from "dayjs";

interface IESearchItem {
    filedID: string,
    title: string,
    type: 'dateTime' | 'select' | 'number',
    request?: object,
    dataUrl?: string,
    data?: any[],
    onChange: (key: string, values: any[] | null, names: string | null) => void,
}

const ESearchItem = (props: IESearchItem) => {
    const [data, setData] = useState<any[]>([]);
    const [selected, setSelected] = useState<any>(null);
    const { filedID, title, type, request, dataUrl, onChange } = props;

    useEffect(() => {
        if (dataUrl) {
            loadUrl()
        } else {
            setData(props.data ?? []);
        }
    }, [dataUrl])

    const loadUrl = () => {
        if (dataUrl) {
            Server.Post(dataUrl, request ?? {}, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response.data ?? []);
                }
            })
        }
    }

    useEffect(() => {
        if (selected) {
            switch (type) {
                case 'dateTime':
                    onChange(filedID, [dayjs().add(-1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
                        , dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')], selected.label);
                    break;
                default:
                    var values: any[] = [];
                    var names = '';
                    selected.map((item: any, index: number) => {
                        values.push(item.value);
                        if (index <= 2) {
                            if (names == '')
                                names = item.label;
                            else {
                                if (index == 2)
                                    names += '、（' + (selected?.length - 2) + '）';
                                else {
                                    names += '、' + item.label;
                                }
                            }
                        }
                    })
                    onChange(filedID, values, names);
            }
        } else {
            onChange(filedID, null, null);
        }
    }, [selected])

    const createControl = () => {
        switch (type) {
            case 'dateTime':
                return <div style={{ overflow: 'auto' }}>
                    <Badge count={<CaretRightOutlined
                        style={!selected ? { color: '#1890ff', borderRadius: '300px', transform: 'rotate(-44deg)' } : {}} />}
                        offset={[-10, -8]}>
                        <Tag onClick={() => {
                            setSelected(null);
                        }} style={!selected ? { border: '1px solid #1890ff' } : { cursor: 'pointer' }}>全部</Tag>
                    </Badge>
                    {
                        ([{ value: 'week', label: '最近一周' },
                        { value: 'mouth', label: '最近一月' },
                        { value: 'quarter', label: '本季度' }]).map((item: any) => {
                            return <Badge count={<CaretRightOutlined
                                style={selected && selected.value == item.value ? { color: '#1890ff', borderRadius: '300px', transform: 'rotate(-45deg)' } : {}} />}
                                offset={[-10, -8]}>
                                <Tag onClick={() => {
                                    setSelected(item)
                                }} key={item.value} style={selected && selected.value == item.value ? { border: '1px solid #1890ff' } : { cursor: 'pointer' }}>{item.label}</Tag>
                            </Badge>
                        })
                    }
                    <DatePicker size="small" placeholder="开始时间" />
                    {'-'}
                    <DatePicker size="small" placeholder="结束时间" />
                </div>
                break;
            case 'number':
                return <Space>
                    <InputNumber size="small" placeholder="起始值" />
                    {'-'}
                    <InputNumber size="small" placeholder="截止值" />
                </Space>
                break;
            default:
                return <div style={{}}>
                    <Badge count={!selected || selected.length == 0 ? <CaretRightOutlined
                        style={{ color: '#1890ff', borderRadius: '300px', transform: 'rotate(-45deg)' }} /> : null}
                        offset={[-10, -8]}>
                        <Tag onClick={() => {
                            setSelected(null);
                        }} style={!selected || selected.length == 0 ? { border: '1px solid #1890ff' } : { cursor: 'pointer' }}>全部</Tag>
                    </Badge>
                    {
                        (data ?? []).map((item: any) => {
                            return <Space style={{ margin: '1px 0' }} size={2}>
                                <Badge count={selected && selected.filter((i: any) => i == item).length > 0 ? <CaretRightOutlined
                                    style={{ color: '#1890ff', borderRadius: '300px', transform: 'rotate(-45deg)' }} /> : null}
                                    offset={[-10, -8]}>
                                    <Tag onClick={() => {
                                        if (selected && selected.length > 0) {
                                            const has = selected.filter((i: any) => i == item).length;
                                            if (has > 0) {
                                                setSelected(selected.filter((i: any) => i != item))
                                            } else {
                                                setSelected([
                                                    ...selected,
                                                    item
                                                ]);
                                            }
                                        } else {
                                            setSelected([
                                                item
                                            ])
                                        }
                                    }} key={item.value}
                                        style={selected && selected.filter((i: any) => i == item).length > 0 ?
                                            { border: '1px solid #1890ff' } : { cursor: 'pointer' }}>{item.label}</Tag>
                                </Badge>
                            </Space>
                        })
                    }
                </div>
                break;
        }
    }

    return <div style={{ borderBottom: '1px dashed #d9d9d9', padding: '2px', fontSize: '12px' }}>
        <Space size={1}>
            <span style={{ minWidth: '70px', display: 'block', textAlign: 'right' }}>{(title ?? '选项标题') + "："}</span>
            {createControl()}
        </Space>
    </div>
}
export default ESearchItem;
