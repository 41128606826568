import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const ApplyProductEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [company, setCompany] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getApplyProduct();
                    break;
                case 2:
                    getApplyProduct();
                    break;
            }
            loadCompany();
        }
    }, [rest.isOpen])

    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
            setCompany({ value: record.companyID, label: record.companyName });
        }
        else {
            setCompany(null);
            formRef.current?.resetFields();
        }
    }

    const getApplyProduct = () => {
        if (rest.applyProductID) {
            var params = {
                applyProductID: rest.applyProductID
            };
            Server.Post(URL.ApplyProduct.GetApplyProduct, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = {
                ...values,
                companyID: company.value,
                companyName: company.label,
            };

            Server.Post(URL.ApplyProduct.SaveApplyProduct, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    useEffect(() => {
        var companyName = company?.label ?? '';
        var applyProductName = formRef.current?.getFieldValue("applyProductName")
        if (!applyProductName || formRef.current?.getFieldValue("applyProductName") == "") {
            var productName = companyName + "";
            formRef.current?.setFieldValue('applyProductName', productName)
        }
    }, [company])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "申报产品"} width={600} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item hidden label={'申报产品ID'} name={'applyProductID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Row>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'保险公司'} name={'companyID'} initialValue={company?.value} rules={[{ required: true, message: '请选择保险公司' }]}>
                        <Select defaultValue={company?.value}
                            value={company?.value}
                            labelInValue
                            placeholder={'选择保险公司'}
                            onSelect={(e) => {
                                setCompany(e);
                            }}
                            style={{ width: '100%' }}
                            options={companyData}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'产品名称'} name={'applyProductName'} initialValue={''} rules={[{ required: true, message: '请填写产品名称' }]}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'单笔限额'} name={'singleLimit'} initialValue={''} rules={[{ required: true, message: '请填写单笔限额' }]}>
                        <InputNumber style={{ width: '100%' }} min={0} addonAfter={'万元'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'免赔额'} name={'deductibleAmountDesc'} initialValue={''} rules={[{ required: true, message: '请填写免赔额' }]}>
                        <Input.TextArea style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'承保区域'} name={'insureArea'} initialValue={''} rules={[{ required: true, message: '承保区域' }]}>
                        <Input.TextArea style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        label={'发送邮箱'} name={'applyEmail'} initialValue={''}
                        rules={[{ required: true, message: '请填写发送邮件' }]}
                        extra={<>多个邮箱分号隔开,如：1@xx.com;2@xx.com</>} >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        label={'抄送邮箱'} name={'ccEmail'} initialValue={''}
                        extra={<>多个邮箱分号隔开,如：1@xx.com;2@xx.com</>} >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        label={'备注'} name={'remark'} initialValue={''} >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        label={'启用'} name={'isActive'} initialValue={true} valuePropName={'checked'}>
                        <Switch checked />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal >
};
export default ApplyProductEdit;
