import { Button, Col, Input, Row, Space, Table, Tree } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import FileEdit from "../fileEdit/FileEdit";

const FileList = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [treeData, setTreeData] = useState<any>([]);
    const [expandedKeys, setExpandedKeys] = useState<any>([]);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<any>({});
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>([]);

    const [isOpenUpload, setIsOpenUpload] = useState(false);
    const [editRecordUpload, setEditRecordUpload] = useState<any>([]);

    const columns: any[] = [{
        title: '文件名称', dataIndex: 'fileName', align: 'left', fixed: 'left', ellipsis: true,
        render: (text: any, record: any) => <a onClick={() => {
            setEditRecord({
                fileID: record.fileID,
                fileName: record.fileName
            });
            setIsOpen(true);
        }}>{text}</a>
    },
    { title: '文件格式', dataIndex: 'type', align: 'center', width: 100, ellipsis: true },
    { title: '创建人', dataIndex: 'createBy', align: 'center', width: 100, ellipsis: true },
    {
        title: '创建时间', dataIndex: 'createDate', align: 'center', width: 120, ellipsis: true,
        render: (text: any, record: any) => {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    {
        title: '操作', align: 'center', fixed: 'right', width: 100, ellipsis: true,
        render: (text: any, record: any) => {
            return <Space>
                <Button size="small" type="link" onClick={() => {
                    setEditRecord({
                        fileID: record.fileID,
                        fileName: record.fileName
                    });
                    setIsOpen(true);
                }}>重命名</Button>
                <Button size="small" type="link">预览</Button>
                <Button size="small" type="link">下载</Button>
                <Button size="small" type="link" danger>删除</Button>
            </Space >
        }
    }]

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        loadFile();
    }, [selectedKeys, page])

    const loadData = () => {

    }

    const loadFile = () => {
        setDataSource([
            { fileName: 'test001', type: '.png', size: 100, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test002', type: '.png', size: 120, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() },
            { fileName: 'test003', type: '.png', size: 112, createBy: '系统管理员', createTime: moment() }
        ])
    }

    return <div style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
            <Col span={4}>
                <div style={{ border: '1px solid #e7e7eb', height: '100%', overflow: 'auto' }}>
                    <div style={{ backgroundColor: '#fafafa', borderBottom: '1px solid #e7e7eb', height: '35px' }}>
                        <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>附件分类</span>
                    </div>
                    <Tree />
                </div>
            </Col>
            <Col span={20}>
                <div style={{ padding: '5px', border: '1px solid #e7e7eb', borderLeft: 'none', width: '100%', height: '100%', overflow: 'auto' }}>
                    <div>
                        <Input.Search allowClear style={{ width: '320px' }}
                            onChange={(e) => {
                                setSearchValue(e.target.value)
                            }}
                            onSearch={() => {
                                loadFile()
                            }}
                            placeholder="请输入文件名" />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                            <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>附件列表</span>
                            <Space style={{ float: 'right' }}>
                                <Button type="primary"
                                    // disabled={selectedOrganization.organizationTypeEnum > 1} 
                                    onClick={() => {
                                        // setEditRecordUser({
                                        //     viewMode: 0,
                                        //     organizationID: selectedOrganization.organizationID,
                                        //     organizationName: selectedOrganization.organizationName,
                                        //     userID: null
                                        // })
                                        // setIsOpenUser((true));
                                    }}>附件上传</Button>
                            </Space>
                        </div>
                        <Table size="small"
                            bordered
                            scroll={{ x: 'max-content' }}
                            columns={columns} dataSource={dataSource} pagination={{
                                position: ['bottomRight'],
                                style: { marginRight: '10px' },
                                showSizeChanger: true,
                                showQuickJumper: true,
                                total: total,
                                showTotal: ((total) => {
                                    return "共" + total + "条";
                                }),
                                current: page.pageIndex,
                                defaultCurrent: 20,
                                pageSize: page.pageSize,
                                pageSizeOptions: [10, 20, 50, 100, 200],
                                onShowSizeChange: ((page, pageSize) => {
                                    setPage({
                                        pageIndex: page,
                                        pageSize: pageSize
                                    });
                                }),
                                onChange: ((page, pageSize) => {
                                    setPage({
                                        pageIndex: page,
                                        pageSize: pageSize
                                    });
                                })
                            }} />
                    </div>
                </div>
            </Col>
        </Row>
        <FileEdit isOpen={isOpen} {...editRecord} setIsOpen={() => setIsOpen(false)} loadData={loadData} />
    </div >
}
export default FileList;
