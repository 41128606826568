import { Badge, DatePicker, Space, Tag, theme } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import dayjs from "dayjs";

interface IESearchDateTime {
    filedID: string,
    title: string,
    selectedInfo: any
    onChange: (filedID: string, values: any[] | null, names: string | null) => void
}
const ESearchDateTime = (props: IESearchDateTime) => {
    const { filedID, title, onChange, selectedInfo } = props;
    const [selected, setSelected] = useState<any>(null);
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [isCustom, setIsCustom] = useState<boolean>(false);

    // const {
    //     token: { '#00A2E9' },
    // } = theme.useToken();

    const data = [
        { value: 'week', label: '最近一周' },
        { value: 'mouth', label: '最近一月' },
        { value: 'quarter', label: '本季度' }
    ];

    useEffect(() => {
        if (selectedInfo && !selectedInfo.hasOwnProperty(filedID)) {
            setSelected(null);
            setIsCustom(false);
            setStartTime(null);
            setEndTime(null);
        }
    }, [selectedInfo])

    useEffect(() => {
        if (selected) {
            var startTime = dayjs().startOf('day');
            var endTime = dayjs().endOf('day');
            switch (selected.value) {
                case 'week':
                    startTime = dayjs().add(-7, 'days').startOf('day');
                    endTime = dayjs().endOf('day');
                    break;
                case 'mouth':
                    startTime = dayjs().add(-1, 'months').startOf('day');
                    endTime = dayjs().endOf('day');
                    break;
                case 'quarter':
                    startTime = dayjs().add(-3, 'months').startOf('day');
                    endTime = dayjs().endOf('day');
                    break;
            }
            setStartTime(startTime);
            setEndTime(endTime);

            setIsCustom(false);
        }
    }, [selected])

    useEffect(() => {
        var values: any[] | null = null;
        var names: string | null = null;
        if (startTime || endTime) {
            values = [startTime ? startTime.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null, endTime ? endTime.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null];
            names = title + '：' + (startTime ? startTime.format('YYYY-MM-DD') : '') + ' - ' + (endTime ? endTime.format('YYYY-MM-DD') : '');
        }
        onChange(filedID, values, names)
    }, [startTime, endTime])

    return <div style={{ borderBottom: '1px dashed #d9d9d9', padding: '2px', fontSize: '12px' }}>
        <Space size={0}>
            <span style={{ minWidth: '70px', display: 'block', textAlign: 'right' }}>{(title ?? '选项标题') + "："}</span>
            {!selected && !isCustom ?
                <Badge count={<CaretRightOutlined style={{ color: '#00A2E9', borderRadius: '300px', transform: 'rotate(-44deg)' }} />}
                    offset={[-10, -8]}>
                    <Tag onClick={() => {
                        setSelected(null);
                        setIsCustom(false);
                    }} style={{ border: '1px solid', borderColor: '#00A2E9' }}>全部</Tag>
                </Badge> :
                <Tag onClick={() => {
                    setSelected(null);
                    setIsCustom(false);
                }} style={{ cursor: 'pointer' }}>全部</Tag>
            }
            {
                (data).map((item: any) => {
                    return selected?.value == item.value ?
                        <Badge count={<CaretRightOutlined
                            style={{ color: '#00A2E9', borderRadius: '300px', transform: 'rotate(-44deg)' }} />}
                            offset={[-10, -8]}>
                            <Tag onClick={() => {
                                setSelected(item)
                            }} key={item.value} style={{ border: '1px solid', borderColor: '#00A2E9' }}>{item.label}</Tag>
                        </Badge> : <Tag onClick={() => {
                            setSelected(item)
                        }} key={item.value} style={{ cursor: 'pointer' }}>{item.label}</Tag>
                })
            }
            <DatePicker size="small" placeholder="开始时间"
                value={startTime}
                onChange={(value: any) => {
                    setSelected(null);
                    setIsCustom(true);
                    setStartTime(value);

                }} />
            <span style={{margin:'0px 4px'}}>{'至'}</span>
            <DatePicker size="small" placeholder="结束时间"
                value={endTime}
                onChange={(value: any) => {
                    setSelected(null);
                    setIsCustom(true);
                    setEndTime(value);
                }} />
        </Space>
    </div>
}
export default ESearchDateTime;