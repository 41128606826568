import { Button, Divider, Form, FormInstance, Input, message, Modal, Space, Switch, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const SalesEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getSales();
                    break;
                case 2:
                    getSales();
                    break;
            }

        }
    }, [rest.isOpen])

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue(record);
        }
        else {
            formRef.current?.resetFields();
        }
    }

    const getSales = () => {
        if (rest.salesID) {
            var params = {
                salesID: rest.salesID
            };
            Server.Post(URL.Sales.GetSales, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = values;
            Server.Post(URL.Sales.SaveSales, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        }));
    }

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "保险业务员"} width={600} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 4 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item style={{ marginBottom: '5px' }} hidden label={'业务员ID'} name={'salesID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'业务员代码'} name={'code'} initialValue={''} rules={[{ required: true, message: '请输入公司代码' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'业务员名称'} name={'name'} initialValue={''} rules={[{ required: true, message: '请输入公司名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'电话'} name={'tel'} initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'是否激活'} name={'isActive'} initialValue={true} valuePropName={'checked'}>
                <Switch checked />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'地址'} name={'address'} initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: '5px' }} label={'备注'} name={'remark'} initialValue={''}>
                <Input />
            </Form.Item>
        </Form>
    </Modal>
};
export default SalesEdit