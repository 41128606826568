import { Button, Input, Modal, Space, Table, Tag, Typography, message, Card, Form, FormInstance, Row, Col, Select, DatePicker } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import { ExclamationCircleFilled } from '@ant-design/icons';
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";
const { confirm } = Modal;

const YearOrderList = () => {
    const formRef = useRef<FormInstance>(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [dataSource, setDataSource] = useState<any>([]);

    const [company, setCompany] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);

    const [vendor, setVendor] = useState<any>(null);
    const [vendorData, setVendorData] = useState<any>(null);

    const [sales, setSales] = useState<any>(null);
    const [salesData, setSalesData] = useState<any>([]);

    const refESearch = useRef<any>(null);

    const columns: any[] = [
        {
            key: 'yearOrderNO', title: '年保单号', dataIndex: 'yearOrderNO'
            , fixed: 'left', ellipsis: true, width: 100,
            render: (text: string, record: any) => <a onClick={() => {
                EUtils.openWindow('/yearonline/yearorderedit', {
                    yearOrderID: record.yearOrderID,
                    viewMode: 2
                })
            }}>{text}</a>
        }, {
            key: 'customerName', title: '投保客户', dataIndex: 'customerName', width: 80, ellipsis: true
        }, {
            key: 'companyName', title: '保险公司', dataIndex: 'companyName',
            align: 'center', width: 100, ellipsis: true
        }, {
            key: 'insuranceType', title: '保险险种', dataIndex: 'insuranceType', width: 80, ellipsis: true
        }, { key: 'insurancePlan', title: '保险方案', dataIndex: 'insurancePlan', width: 100, ellipsis: true }
        , {
            key: 'insureTime', title: '出单时间', dataIndex: 'insureTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }, {
            key: 'effectTime', title: '生效日期', dataIndex: 'effectTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }
        , {
            key: 'amount', title: '保费', dataIndex: 'amount', width: 120, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }, { key: 'salesName', title: '业务员', dataIndex: 'salesName', width: 100, ellipsis: true }
        , { key: 'salesRate', title: '提成比例', dataIndex: 'salesRate', width: 100, ellipsis: true }
        , {
            key: 'salesAmount', title: '提成金额', dataIndex: 'salesAmount', width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }, {
            key: 'salesSettleState', title: '提成状态', dataIndex: 'salesSettleState', width: 80, ellipsis: true,
            render: (text: string, record: any) => (
                record.salesSettleStateEnum == 0 ? <Tag color="red">{record.salesSettleState}</Tag> : <Tag color="green">{record.salesSettleState}</Tag>
            )
        }, {
            key: 'salesSettleBy', title: '结算人员', dataIndex: 'salesSettleBy', width: 80, ellipsis: true
        }, {
            key: 'salesSettleTime', title: '结算时间', dataIndex: 'salesSettleTime'
            , width: 80, ellipsis: true
            , render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , { key: 'vendorName', title: '供应商', dataIndex: 'vendorName', width: 100, ellipsis: true }
        , {
            key: 'vendorRate', title: '佣金比例', dataIndex: 'vendorRate', width: 100, ellipsis: true
        }
        , {
            key: 'vendorAmount', title: '佣金金额', dataIndex: 'vendorAmount', width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }, {
            key: 'vendorSettleState', title: '佣金状态', dataIndex: 'vendorSettleState'
            , width: 80, ellipsis: true,
            render: (text: string, record: any) => (
                record.vendorSettleStateEnum == 0 ? <Tag color="red">{record.vendorSettleState}</Tag> : <Tag color="green">{record.vendorSettleState}</Tag>
            )
        }, {
            key: 'vendorSettleBy', title: '结算人员', dataIndex: 'vendorSettleBy', width: 80, ellipsis: true
        }, {
            key: 'vendorSettleTime', title: '结算时间', dataIndex: 'vendorSettleTime'
            , width: 80, ellipsis: true
            , render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , {
            key: 'profitAmount', title: '毛利', dataIndex: 'profitAmount'
            , width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }
        , { key: 'sourceType', title: '业务归属', dataIndex: 'sourceType', width: 100, ellipsis: true }
        , { key: 'insuranceNO', title: '保单号', dataIndex: 'insuranceNO', width: 100, ellipsis: true }
        , {
            key: 'expireTime', title: '到期日期', dataIndex: 'expireTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }, { key: 'remark', title: '备注', dataIndex: 'remark', width: 200, ellipsis: true }
        , { key: 'createBy', title: '创建人', dataIndex: 'createBy', width: 200, ellipsis: true }
        , {
            key: 'createTime', title: '创建时间', dataIndex: 'createTime', width: 200, ellipsis: true,
            render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        },
        {
            title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '查看', onClick: () => {
                            EUtils.openWindow('/yearonline/yearorderedit', {
                                yearOrderID: record.yearOrderID,
                                viewMode: 2
                            })
                        },
                    },
                    {
                        name: '编辑', onClick: () => {
                            EUtils.openWindow('/yearonline/yearorderedit', {
                                yearOrderID: record.yearOrderID,
                                viewMode: 1
                            })
                        },
                        hidden: record?.vendorSettleStateEnum == 1 || record?.salesSettleStateEnum == 1,
                    },
                    {
                        name: '删除', onClick: () => {
                            showDeleteConfirm(record);
                        },
                        danger: true,
                        hidden: record?.vendorSettleStateEnum == 1 || record?.salesSettleStateEnum == 1,
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }];

    useEffect(() => {
        loadCompany();
        loadVendor();
        loadSales();

        loadData();
    }, [])

    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [page])

    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const loadVendor = () => {
        Server.Post(URL.Vendor.GetVendorOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setVendorData(response.data);
            }
        })
    }

    const loadSales = () => {
        Server.Post(URL.Sales.GetSalesOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setSalesData(response.data);
            }
        })
    }


    const loadData = () => {
        var params = {
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.YearOrder.GetYearOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data ?? []);
                setTotal(response.total);
            }
        });
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除年保记录',
            icon: <ExclamationCircleFilled />,
            content: '年保记录[' + record.yearOrderNO + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { yearOrderID: record.yearOrderID }
                Server.Post(URL.YearOrder.DeleteYearOrder, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('删除成功!');
                        loadData();
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const searchConfig: ISearchConfig[] = [
        { filedName: '年报单号', filedID: 'YearOrderNO' },
        { filedName: '投保客户', filedID: 'CustomerName' },
        { filedName: '保险公司', filedID: 'CompanyName' },
        { filedName: '保险险种', filedID: 'insuranceType' },
        { filedName: '保险方案', filedID: 'insurancePlan' },
        { filedName: '供应商', filedID: 'VendorName' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', title: '保险公司', filedID: 'CompanyID', dataUrl: URL.Company.GetCompanyOptions },
        { type: 'select', title: '供应商', filedID: 'VendorID', dataUrl: URL.Vendor.GetVendorOptions },
        { type: 'select', title: '业务员', filedID: 'SalesID', dataUrl: URL.Sales.GetSalesOptions },
        { type: 'dateTime', title: '出单时间', filedID: 'InsureTime', },
    ];

    return <div>
        <ESearch searchConfig={searchConfig} moreConfig={moreConfig} resize={resize} onLoad={loadData} ref={refESearch} />
        {/* <div>
            <Card type="inner" size="small" bodyStyle={{ padding: '5px' }}>
                <Form ref={formRef} labelCol={{
                    xs: { span: 24 },
                    sm: { span: 6 }
                }} wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 20 }
                }}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label={'模糊查询'}
                                name={'searchValue'} initialValue={''}>
                                <Input style={{ width: '100%' }} allowClear placeholder='请输入查询条件' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label={'保险公司'}
                                name={'company'} initialValue={company?.value} >
                                <Select defaultValue={company?.value}
                                    value={company?.value}
                                    placeholder={'选择保险公司'}
                                    labelInValue
                                    allowClear
                                    onSelect={(e) => {
                                        setCompany(e);
                                    }}
                                    style={{ width: '100%' }}
                                    options={companyData}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label={'供应商'}
                                name={'vendor'} initialValue={vendor?.value} >
                                <Select defaultValue={vendor?.value}
                                    value={vendor?.value}
                                    labelInValue
                                    allowClear
                                    placeholder={'选择供应商'}
                                    onSelect={(e) => {
                                        setVendor(e);
                                    }}
                                    style={{ width: '100%' }}
                                    options={vendorData}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: '5px' }} label={'业务员'}
                                name={'sales'} initialValue={sales?.value} >
                                <Select defaultValue={sales?.value}
                                    value={sales?.value}
                                    labelInValue
                                    allowClear
                                    placeholder={'选择业务员'}
                                    onSelect={(e) => {
                                        setSales(e);
                                    }}
                                    style={{ width: '100%' }}
                                    options={salesData}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption} />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item labelCol={{ span: 3 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '5px' }} label={'出单时间'} name={'insureTime'}
                                initialValue={[null, null]} >
                                <DatePicker.RangePicker allowClear style={{ width: '100%' }}
                                    format={'YYYY-MM-DD'} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div style={{ margin: '2px 0', textAlign: 'right' }}>
                                <Space style={{ float: 'right' }}>
                                    <Button type="primary" onClick={loadData}>查询</Button>
                                    <Button onClick={() => {
                                        setCompany(null);
                                        setVendor(null);
                                        setSales(null);
                                        formRef.current?.setFieldsValue({
                                            searchValue: '',
                                            company: null,
                                            sales: null,
                                            vendor: null,
                                            vendorSettleState: { value: '-1', label: '全部' },
                                            salesSettleState: { value: '-1', label: '全部' },
                                            insureTime: [null, null]
                                        });
                                    }}>重置</Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div> */}
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        EUtils.openWindow('/yearonline/yearorderedit', {
                            yearOrderID: null,
                            viewMode: 0,
                        })
                    }}>新增</Button>
                </Space>
            </div>
            <Table size="small" bordered
                scroll={{ x: 'max-content', y: scrollY }}
                columns={columns}
                dataSource={dataSource}
                summary={(pageData) => {
                    var totalAmount = 0;
                    var totalVendorAmount = 0;
                    var totalSalesAmount = 0;
                    var totalProfitAmount = 0;
                    pageData.forEach(({ amount, vendorAmount, salesAmount, profitAmount }) => {
                        totalAmount += amount;
                        totalVendorAmount += vendorAmount;
                        totalSalesAmount += salesAmount;
                        totalProfitAmount += profitAmount;
                    });
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>{'合计：' + dataSource.length ?? 0}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={6} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={1} >
                                <Typography.Text>{totalAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} colSpan={1} >
                                <Typography.Text>{totalSalesAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5} colSpan={5} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={6} colSpan={1} >
                                <Typography.Text>{totalVendorAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7} colSpan={3} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={8} colSpan={1} >
                                <Typography.Text>{totalProfitAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={9} colSpan={6} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={10} colSpan={2} ></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }}
                pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
        </div>
    </div>
}
export default YearOrderList;