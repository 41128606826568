import { Button, Divider, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import AgentRateItem from "./AgentRateItem";

const AgentRate = (props: any) => {
    const { ...rest } = props;
    const [companyData, setCompanyData] = useState<any>([]);

    useEffect(() => {
        if (rest.isOpen) {
            Server.Post(URL.Product.GetProductCompanyList, {}).then((response: any) => {
                if (response.isSuccess) {
                    setCompanyData(response.data);
                }
            })
        }
    }, [rest.isOpen])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }
    return <Modal title="费率设置" width={1000} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Button onClick={handleCancel}>关闭窗口</Button>
        }>
        <div style={{ overflowY: 'auto', maxHeight: '492px' }}>
            {(companyData ?? []).map((item: any) => {
                return <AgentRateItem curentCompany={item} member={rest.member} />
            })}
        </div>
    </Modal>
};
export default AgentRate