import { Badge, Col, DatePicker, Space, Tag, theme } from "antd";
import { CaretRightOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import Server from "../../../server/Server";

interface IESearchSelect {
    filedID: string,
    title: string,
    request?: object,
    dataUrl?: string,
    data?: any[],
    selectedInfo: any,
    resize: () => void,
    onChange: (key: string, values: any[] | null, names: string | null) => void,
}
const ESearchSelect = (props: IESearchSelect) => {
    const [data, setData] = useState<any[]>([]);
    const [selected, setSelected] = useState<any>(null);
    const { filedID, title, request, dataUrl, selectedInfo, onChange, resize } = props;
    const [more, setMore] = useState<boolean>(false);

    // const {
    //     token: { colorPrimary },
    // } = theme.useToken();

    useEffect(() => {
        if (dataUrl) {
            loadUrl()
        } else {
            setData(props.data ?? []);
        }
    }, [dataUrl])

    useEffect(() => {
        resize();
    }, [more])

    const loadUrl = () => {
        if (dataUrl) {
            Server.Post(dataUrl, request ?? {}, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response.data ?? []);
                }
            })
        }
    }

    useEffect(() => {
        if (selectedInfo && !selectedInfo.hasOwnProperty(filedID)) {
            setSelected(null);
        }
    }, [selectedInfo])

    useEffect(() => {
        if (selected) {
            var values: any[] = [];
            var names = '';
            selected.map((item: any, index: number) => {
                values.push(item.value);
                if (index <= 2) {
                    if (names == '')
                        names = item.label;
                    else {
                        if (index == 2)
                            names += '、（' + (selected?.length - 2) + '）';
                        else {
                            names += '、' + item.label;
                        }
                    }
                }
            })
            onChange(filedID, values, names);
        } else {
            onChange(filedID, null, null);
        }
    }, [selected])

    const onClick = (item: any) => {
        if (selected && selected.length > 0) {
            const has = selected.filter((i: any) => i == item).length;
            if (has > 0) {
                setSelected(selected.filter((i: any) => i != item))
            } else {
                if (selected.length + 1 < data.length) {
                    setSelected([
                        ...selected,
                        item
                    ]);
                } else {
                    setSelected(null);
                }
            }
        } else {
            setSelected([
                item
            ])
        }
    }

    return <div style={{ borderBottom: '1px dashed #d9d9d9', padding: '2px', fontSize: '12px' }}>
        <Space size={1} wrap style={{ maxWidth: '120px' }}>
            <span style={{ minWidth: '70px', display: 'block', textAlign: 'right' }}>{(title ?? '选项标题') + "："}</span>
            {!selected || selected.length == 0 ?
                <Badge count={<CaretRightOutlined style={{ color: '#00A2E9', borderRadius: '300px', transform: 'rotate(-44deg)' }} />}
                    offset={[-10, -8]}>
                    <Tag onClick={() => {
                        setSelected(null);
                    }} style={{ border: '1px solid', borderColor: '#00A2E9' }}>全部</Tag>
                </Badge> :
                <Tag onClick={() => {
                    setSelected(null);
                }} style={{ cursor: 'pointer' }}>全部</Tag>
            }
        </Space>
        <Space size={1} wrap style={{ width: 'calc(100% - 180px)' }}>
            {
                (data ?? []).map((item: any, index: number) => {
                    if (!more && index >= 8) {
                        return false;
                    } else {
                        return (selected && selected.filter((i: any) => i == item).length > 0) ?
                            <Badge count={<CaretRightOutlined
                                style={{ color: '#00A2E9', borderRadius: '300px', transform: 'rotate(-44deg)' }} />}
                                offset={[-10, -8]}>
                                <Tag onClick={() => {
                                    onClick(item)
                                }} key={item.value} style={{ border: '1px solid', borderColor: '#00A2E9' }}>{item.label}</Tag>
                            </Badge> : <Tag onClick={() => {
                                onClick(item)
                            }} key={item.value} style={{ cursor: 'pointer' }}>{item.label}</Tag>
                    }
                })
            }
        </Space>
        {
            data && data.length >= 8 ?
                <Space size={0} style={{ float: 'right', width: '40px', margin: '2px' }}>
                    <span onClick={() => { setMore(!more) }}>
                        {more ? '收起' : '更多'}
                        {more ? <CaretUpOutlined /> : < CaretDownOutlined />}
                    </span>
                </Space> : <></>
        }
    </div >
}
export default ESearchSelect;