import { Alert, Button, Card, Col, DatePicker, Form, FormInstance, Input, message, Modal, Row, Select, Space, Table, Tag, TimePicker, Tooltip, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import moment from "moment";
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";
const { confirm } = Modal;

const SettleOrderList = () => {
    const formRef = useRef<FormInstance>(null);
    const [dataSource, setDataSource] = useState<any>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedVendor, setSelectedVendor] = useState<any>(null);
    const [selectedSales, setSelectedSales] = useState<any>(null);

    const [company, setCompany] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);

    const [vendor, setVendor] = useState<any>(null);
    const [vendorData, setVendorData] = useState<any>(null);

    const [sales, setSales] = useState<any>(null);
    const [salesData, setSalesData] = useState<any>([]);

    const refESearch = useRef<any>(null);

    const columns: any[] = [
        {
            key: 'yearOrderNO', title: '年保单号', dataIndex: 'yearOrderNO', fixed: 'left', ellipsis: true, width: 100,
            render: (text: string, record: any) => <a onClick={() => {
                EUtils.openWindow('/yearonline/yearorderedit', {
                    yearOrderID: record.yearOrderID,
                    viewMode: 2
                })
            }}>{text}</a>
        }
        , { key: 'salesName', title: '业务员', dataIndex: 'salesName', width: 100, ellipsis: true }
        , { key: 'salesRate', title: '提成比例', dataIndex: 'salesRate', width: 100, ellipsis: true }
        , {
            key: 'salesAmount', title: '提成金额', dataIndex: 'salesAmount', width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }, {
            key: 'salesSettleState', title: '提成状态', dataIndex: 'salesSettleState', width: 100, ellipsis: true,
            render: (text: string, record: any) => (
                record.salesSettleStateEnum == 0 ? <Tag color="red">{record.salesSettleState}</Tag> : <Tag color="green">{record.salesSettleState}</Tag>
            )
        }, {
            key: 'salesSettleBy', title: '结算人员', dataIndex: 'salesSettleBy', width: 100, ellipsis: true
        }, {
            key: 'salesSettleTime', title: '结算时间', dataIndex: 'salesSettleTime'
            , width: 80, ellipsis: true
            , render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , { key: 'vendorName', title: '供应商', dataIndex: 'vendorName', width: 100, ellipsis: true }
        , {
            key: 'vendorRate', title: '佣金比例', dataIndex: 'vendorRate', width: 100, ellipsis: true
        }
        , {
            key: 'vendorAmount', title: '佣金金额', dataIndex: 'vendorAmount', width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }, {
            key: 'vendorSettleState', title: '佣金状态', dataIndex: 'vendorSettleState', width: 100, ellipsis: true,
            render: (text: string, record: any) => (
                record.vendorSettleStateEnum == 0 ? <Tag color="red">{record.vendorSettleState}</Tag> : <Tag color="green">{record.vendorSettleState}</Tag>
            )
        }
        , {
            key: 'vendorSettleBy', title: '结算人员', dataIndex: 'vendorSettleBy', width: 100, ellipsis: true
        },
        {
            key: 'vendorSettleTime', title: '结算时间', dataIndex: 'vendorSettleTime'
            , width: 80, ellipsis: true
            , render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , {
            key: 'customerName', title: '投保客户', dataIndex: 'customerName', width: 100, ellipsis: true
        },
        { key: 'companyName', title: '保险公司', dataIndex: 'companyName', width: 100, ellipsis: true },
        {
            key: 'insuranceType', title: '保险险种', dataIndex: 'insuranceType', width: 100, ellipsis: true
        }
        , { key: 'insurancePlan', title: '保险方案', dataIndex: 'insurancePlan', width: 100, ellipsis: true }
        , {
            key: 'insureTime', title: '出单时间', dataIndex: 'insureTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }, {
            key: 'effectTime', title: '生效日期', dataIndex: 'effectTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }, {
            key: 'amount', title: '保费', dataIndex: 'amount', width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }
        , {
            key: 'profitAmount', title: '毛利', dataIndex: 'profitAmount'
            , width: 150, ellipsis: true,
            render: (text: string, record: any) => (
                text + '元'
            )
        }
        , {
            key: 'expireTime', title: '到期日期', dataIndex: 'expireTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }, { key: 'sourceType', title: '业务归属', dataIndex: 'sourceType', width: 100, ellipsis: true }
        , { key: 'insuranceNO', title: '保单号', dataIndex: 'insuranceNO', width: 100, ellipsis: true }
        , { key: 'remark', title: '备注', dataIndex: 'remark', width: 100, ellipsis: true }
        , { key: 'createBy', title: '创建人', dataIndex: 'createBy', width: 100, ellipsis: true }
        , {
            key: 'createTime', title: '创建时间', dataIndex: 'createTime', width: 100, ellipsis: true,
            render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }, {
            title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
            render(value: any, record: any, index: any) {
                var configs: IButtonConfig[] = [
                    {
                        name: '查看', onClick: () => {
                            EUtils.openWindow('/yearonline/yearorderedit', {
                                yearOrderID: record.yearOrderID,
                                viewMode: 2
                            })
                        },
                    },
                ];
                return EUtils.InitButtons(configs);
            }
        }
    ];

    useEffect(() => {
        loadCompany();
        loadVendor();
        loadSales();

        loadData();
    }, [])

    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(55, ''));

    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])


    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const loadVendor = () => {
        Server.Post(URL.Vendor.GetVendorOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setVendorData(response.data);
            }
        })
    }

    const loadSales = () => {
        Server.Post(URL.Sales.GetSalesOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setSalesData(response.data);
            }
        })
    }

    const loadData = () => {
        var params = {
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.YearOrderSettle.GetYearOrderSettleList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data ?? []);
                setSelectedRowKeys([]);
                setSelectedVendor(null);
                setSelectedSales(null);
            }
        });
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRowKeys(selectedRowKeys);
            var vendororders: any[] = [];
            var vendorRows = {
                count: 0,
                sumary: 0,
                orderCount: 0,
                orders: vendororders
            };
            var salesorders: any[] = [];
            var salesRows = {
                count: 0,
                orderCount: 0,
                sumary: 0,
                orders: salesorders
            };
            selectedRows.forEach((row: any) => {
                if (row.vendorSettleStateEnum == 0) {
                    vendorRows.sumary += row.vendorAmount;
                    vendorRows.orderCount++;
                    var exist = vendorRows.orders.filter(x => x.name == row.vendorName);
                    if (exist.length == 0) {
                        vendorRows.count++;
                        vendorRows.orders.push({
                            name: row.vendorName,
                            count: 1,
                            amount: row.vendorAmount
                        })
                    } else {
                        exist[0].count++;
                        exist[0].amount += row.vendorAmount;
                    }
                }
                if (row.salesSettleStateEnum == 0) {
                    salesRows.sumary += row.salesAmount;
                    salesRows.orderCount++;
                    var exist = salesRows.orders.filter(x => x.name == row.salesName);
                    if (exist.length == 0) {
                        salesRows.count++;
                        salesRows.orders.push({
                            name: row.salesName,
                            count: 1,
                            amount: row.salesAmount
                        })
                    } else {
                        exist[0].count++;
                        exist[0].amount += row.salesAmount;
                    }
                }
            });
            setSelectedVendor(vendorRows);
            setSelectedSales(salesRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: (record.vendorSettleStateEnum == 1 && record.salesSettleStateEnum == 1)
                || (record.vendorAmount == 0 && record.salesAmount == 0), // Column configuration not to be checked
        }),
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const searchConfig: ISearchConfig[] = [
        { filedName: '年报单号', filedID: 'YearOrderNO' },
        { filedName: '投保客户', filedID: 'CustomerName' },
        { filedName: '保险公司', filedID: 'CompanyName' },
        { filedName: '保险险种', filedID: 'insuranceType' },
        { filedName: '保险方案', filedID: 'insurancePlan' },
        { filedName: '供应商', filedID: 'VendorName' },
        { filedName: '业务员', filedID: 'SalesName' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', title: '佣金状态', filedID: 'VendorSettleStateEnum', data: [{ value: 0, label: '未结算' }, { value: 1, label: '已结算' }] },
        { type: 'select', title: '提成状态', filedID: 'SalesSettleStateEnum', data: [{ value: 0, label: '未结算' }, { value: 1, label: '已结算' }] },
        { type: 'select', title: '保险公司', filedID: 'CompanyID', dataUrl: URL.Company.GetCompanyOptions },
        { type: 'select', title: '供应商', filedID: 'VendorID', dataUrl: URL.Vendor.GetVendorOptions },
        { type: 'select', title: '业务员', filedID: 'SalesID', dataUrl: URL.Sales.GetSalesOptions },
        { type: 'dateTime', title: '出单时间', filedID: 'InsureTime', },
    ];

    return <div >
        <ESearch searchConfig={searchConfig} moreConfig={moreConfig} resize={resize} onLoad={loadData} ref={refESearch} />
        {/* <Card type="inner" size="small" bodyStyle={{ padding: '5px' }}>
            <Form ref={formRef} labelCol={{
                xs: { span: 24 },
                sm: { span: 6 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }} size='middle' >
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '5px' }} label={'模糊查询'}
                            name={'searchValue'} initialValue={''}>
                            <Input style={{ width: '100%' }} allowClear placeholder='请输入查询条件' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '5px' }} label={'保险公司'}
                            name={'company'} initialValue={company?.value} >
                            <Select defaultValue={company?.value}
                                value={company?.value}
                                allowClear
                                placeholder={'选择保险公司'}
                                labelInValue
                                onSelect={(e) => {
                                    setCompany(e);
                                }}
                                style={{ width: '100%' }}
                                options={companyData}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '5px' }} label={'供应商'}
                            name={'vendor'} initialValue={vendor?.value} >
                            <Select defaultValue={vendor?.value}
                                value={vendor?.value}
                                labelInValue
                                allowClear
                                placeholder={'选择供应商'}
                                onSelect={(e) => {
                                    setVendor(e);
                                }}
                                style={{ width: '100%' }}
                                options={vendorData}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '5px' }} label={'业务员'}
                            name={'sales'} initialValue={sales?.value} >
                            <Select defaultValue={sales?.value}
                                value={sales?.value}
                                labelInValue
                                allowClear
                                placeholder={'选择业务员'}
                                onSelect={(e) => {
                                    setSales(e);
                                }}
                                style={{ width: '100%' }}
                                options={salesData}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item labelCol={{ span: 3 }}
                            wrapperCol={{ span: 24 }}
                            style={{ marginBottom: '5px' }} label={'出单时间'} name={'insureTime'}
                            initialValue={[moment().add('months', -2), moment()]} >
                            <DatePicker.RangePicker allowClear={false} style={{ width: '100%' }}
                                format={'YYYY-MM-DD'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '5px' }} label={'佣金状态'}
                            name={'vendorSettleState'}
                            initialValue={{ value: '-1', label: '全部' }} >
                            <Select
                                style={{ width: '100%' }}
                                labelInValue
                                options={[
                                    { value: '-1', label: '全部' },
                                    { value: '0', label: '未结算' },
                                    { value: '1', label: '已结算' },
                                ]}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '5px' }} label={'提成状态'}
                            name={'salesSettleState'} initialValue={{ value: '-1', label: '全部' }} >
                            <Select
                                style={{ width: '100%' }}
                                labelInValue
                                options={[
                                    { value: '-1', label: '全部' },
                                    { value: '0', label: '未结算' },
                                    { value: '1', label: '已结算' },
                                ]}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption} />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ padding: '2px 0', textAlign: 'right' }}>
                    <Space style={{ float: 'right' }}>
                        <Button type="primary" onClick={loadData}>查询</Button>
                        <Button onClick={() => {
                            setCompany(null);
                            setVendor(null);
                            setSales(null);
                            formRef.current?.setFieldsValue({
                                searchValue: '',
                                company: null,
                                sales: null,
                                vendor: null,
                                vendorSettleState: { value: '-1', label: '全部' },
                                salesSettleState: { value: '-1', label: '全部' },
                                insureTime: [moment().add('months', -2), moment()]
                            });
                        }}>重置</Button>
                    </Space>
                </div>
            </Form>
        </Card> */}
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'nVendor', height: '45px', padding: '5px' }}>
                <Space>
                    <Alert
                        style={{ height: '30px' }}
                        banner type='info'
                        message="温馨提醒：佣金及提成全部结算或无结算金额的保单禁用选择!" />
                    {`总计 ${dataSource.length} 条`}
                    {selectedRowKeys.length > 0 ? `已选 ${selectedRowKeys.length} 条` : ''}
                </Space>
                <Space style={{ float: 'right' }}>
                    <Button disabled={!selectedVendor || selectedVendor.sumary == 0} type={'primary'} danger
                        onClick={() => {
                            confirm({
                                icon: <ExclamationCircleOutlined />,
                                title: '确认结算佣金？',
                                width: '600px',
                                content: <>
                                    <p>供应商合计：{selectedVendor.count}个,保单合计{selectedVendor.orderCount}笔，佣金总计{selectedVendor.sumary.toFixed(3)}元</p>
                                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <p>结算明细：</p>
                                        {(selectedVendor.orders).map((item: any) => {
                                            return <p>供应商：{item.name} 保单:{item.count}笔 佣金金额:{item.amount.toFixed(3)}元</p>
                                        })}
                                    </div>
                                </>,
                                onOk() {
                                    var params = {
                                        yearOrderIDs: selectedRowKeys
                                    }
                                    Server.Post(URL.YearOrderSettle.SettleVendor, params).then((response: any) => {
                                        if (response.isSuccess) {
                                            message.success('佣金结算成功');
                                            loadData();
                                            setSelectedRowKeys([])
                                            setSelectedVendor(null);
                                            setSelectedSales(null);
                                        }
                                    })
                                },
                                onCancel() {
                                },
                            });
                        }}>结算佣金 {selectedVendor && selectedVendor.sumary > 0 ? (selectedVendor.sumary.toFixed(3) + '元') : ''}</Button>
                    <Button disabled={!selectedSales || selectedSales.sumary == 0} danger onClick={() => {
                        confirm({
                            icon: <ExclamationCircleOutlined />,
                            width: '600px',
                            title: '确认结算提成？',
                            content: <>
                                <p>业务员合计：{selectedSales.count}个,保单合计{selectedSales.orderCount}笔，提成总计{selectedSales.sumary.toFixed(3)}元</p>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <p>结算明细：</p>
                                    {(selectedSales.orders).map((item: any) => {
                                        return <p>业务员:{item.name} 保单:{item.count}笔 提成金额:{item.amount.toFixed(3)}元</p>
                                    })}
                                </div>
                            </>,
                            onOk() {
                                var params = {
                                    yearOrderIDs: selectedRowKeys
                                }
                                Server.Post(URL.YearOrderSettle.SettleSales, params).then((response: any) => {
                                    if (response.isSuccess) {
                                        message.success('提成结算成功');
                                        loadData();
                                        setSelectedRowKeys([])
                                        setSelectedVendor(null);
                                        setSelectedSales(null);
                                    }
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>结算提成{selectedSales && selectedSales.sumary > 0 ? (selectedSales.sumary.toFixed(3) + '元') : ''}</Button>
                </Space>
            </div>
            <Table size="small" bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns}
                dataSource={dataSource}
                rowKey={'yearOrderID'}
                pagination={false}
                rowSelection={{
                    type: 'checkbox',
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_INVERT,
                        Table.SELECTION_NONE],
                    ...rowSelection,
                }}
                summary={(pageData) => {
                    var totalAmount = 0;
                    var totalSalesAmount = 0;
                    var totalVendorAmount = 0;
                    var totalProfitAmount = 0;
                    pageData.forEach(({ amount, salesAmount, vendorAmount, profitAmount }) => {
                        totalAmount += amount
                        totalSalesAmount += salesAmount;
                        totalVendorAmount += vendorAmount;
                        totalProfitAmount += profitAmount;
                    });
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1} >
                                <Typography.Text>{'合计：' + dataSource.length ?? 0}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={1} >
                                <Typography.Text>{totalSalesAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4} colSpan={5} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={5} colSpan={1} >
                                <Typography.Text>{totalVendorAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6} colSpan={9} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={7} colSpan={1} >
                                <Typography.Text>{totalAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8} colSpan={1} >
                                <Typography.Text>{totalProfitAmount.toFixed(2)}{'元'}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={9} colSpan={6} >
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={10} colSpan={2} >
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }}>
            </Table>
        </div>
    </div>
}
export default SettleOrderList;