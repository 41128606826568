import { Button, Input, Space, Table, Modal, message, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import EUtils, { IButtonConfig } from "../../../utils/EUtils";
import ESearch, { IMoreConfig, ISearchConfig } from "../../../components/esearch/ESearch";

const { confirm } = Modal;

const OnlineVendor = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 50 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});
    const [scrollY, setScrollY] = useState("");
    const resize = () => setScrollY(EUtils.getTableScroll(82, ''));
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        resize();
    }, [dataSource])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            ...page,
            options: refESearch.current?.getOptions()
        };
        Server.Post(URL.OnlineVendor.GetVendorList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    const columns: any[] = [{
        key: 'code', title: '供应商代码', dataIndex: 'code', fixed: 'left', width: 150,
        render: (text: any, record: any) => <a onClick={() => {
            setEditRecord({
                viewMode: 1,
                vendorID: record.vendorID
            });
            setIsOpen(true)
        }}>{text}</a>
    }
        , {
        title: '上线状态', dataIndex: 'isOnline', width: 80,
        render: (text: any) => {
            return text ? <Tag color="green">上线</Tag> : <Tag color="red">下线</Tag>;
        }
    },
    { title: '供应商名称', dataIndex: 'name', width: 150 },
    { title: '联系人', dataIndex: 'contact', width: 150 },
    { title: '电话', dataIndex: 'tel', width: 150 },
    { title: '地址', dataIndex: 'address', width: 150 },
    { title: '备注', dataIndex: 'remark', width: 150 },
    {
        title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
        render(value: any, record: any, index: any) {
            var configs: IButtonConfig[] = [
                {
                    name: '上线', onClick: () => {
                        showOnlineConfirm(record)
                    },
                    hidden: record.isOnline
                },
                {
                    name: '下线', onClick: () => {
                        showOnlineConfirm(record)
                    },
                    danger: true,
                    hidden: !record.isOnline
                },
            ];
            return EUtils.InitButtons(configs);
        }
    }];
    const showOnlineConfirm = (record: any) => {
        confirm({
            title: record.isOnline ? '下线供应商' : '下线供应商',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.name + ']，确认要' + (record.isOnline ? '下线' : '上线'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    vendorID: record.vendorID,
                    isOnline: !record.isOnline
                }
                Server.Post(URL.OnlineVendor.SetOnlineVendor, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isOnline ? '下线' : '上线') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };
    const refESearch = useRef<any>(null);
    const searchConfig: ISearchConfig[] = [
        { filedID: 'Code', filedName: '供应商编码' },
        { filedID: 'Name', filedName: '供应商名称' },
        { filedID: 'Contact', filedName: '联系人' },
        { filedID: 'Tel', filedName: '电话' },
        { filedID: 'Address', filedName: '地址' },
        { filedID: 'Remark', filedName: '备注' },
    ];
    const moreConfig: IMoreConfig[] = [
        { type: 'select', filedID: 'isOnline', title: '上线状态', data: [{ value: false, label: '下线' }, { value: true, label: '上线' }] }
    ];

    return <div>
        <ESearch searchConfig={searchConfig} moreConfig={moreConfig} onLoad={loadData} resize={resize} ref={refESearch} />
        <div>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}></span>
            </div>
            <Table size="small" bordered
                scroll={dataSource.length > 0 ? { x: 'max-content', y: scrollY } : { x: 'max-content' }}
                columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { margin: '5px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }}
                summary={(pageData) => {
                    return <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} >
                                <Typography.Text>合计:{dataSource.length}</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={6} align={'center'}>
                                <Typography.Text></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align={'center'}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                }} />
        </div>
    </div>
}
export default OnlineVendor;