import { Button, Form, FormInstance, InputNumber, message, Modal, Space, Table } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from "moment";
import React from "react";

const ConsumptionRecord = (props: any) => {
    const { ...rest } = props;
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);

    const columns: ColumnsType<any> = [
        { key: 'transactionNO', title: '交易单号', dataIndex: 'transactionNO', align: 'center' },
        {
            key: 'payTime', title: '支付时间', dataIndex: 'payTime', 
            render: (text, record) => (
                text ? moment(text).format('YYYY-MM-DD hh:mm:ss') : ''
            )
        },
        { key: 'payContent', title: '支付内容', dataIndex: 'payContent', align: 'center' },
        { key: 'amount', title: '账单金额（元）', dataIndex: 'amount', align: 'center' },
        { key: 'payAmount', title: '支付金额（元）', dataIndex: 'payAmount', align: 'center' },
        { key: 'payMode', title: '支付方式', dataIndex: 'payMode', align: 'center' },
        { key: 'balance', title: '账户余额', dataIndex: 'balance', align: 'center' }]

    useEffect(() => {
        if (rest.isOpen) {
            loadData();
        }
    }, [rest.isOpen])

    const loadData = () => {
        var params = {
            accountID: rest.accountID,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        }
        Server.Post(URL.Account.GetAccountConsumptionList, params, false).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    useEffect(() => {
        loadData();
    }, [page])

    const handleCancel = () => {
        rest.setIsOpen(false);
    }
    return <Modal title={"消费记录-" + rest.organizationName} width={1000} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button size='middle' onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: false,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
    </Modal>
}
export default ConsumptionRecord;