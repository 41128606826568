import { Tabs, TabsProps } from "antd";
import ByFunction from "./byfunction/ByFunction";
import ByUser from "./byuser/ByUser";

const SearchUser = () => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `按用户查询`,
            children: <ByUser />,
        },
        // {
        //     key: '2',
        //     label: `按权限查询`,
        //     children: <ByFunction />,
        // }
    ];

    const onChange = (key: string) => {
        console.log(key);
    };

    return <div className="page">
        <Tabs defaultActiveKey="1" size='small' items={items} onChange={onChange} />
    </div>
}
export default SearchUser;