import { Breadcrumb, Button, Card, Descriptions, Space, Tabs } from "antd/es";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils from "../../../utils/EUtils";
import RoleFunctionItem from "./RoleFunctionItem";

const RoleAuth = () => {
    const [roleID, setRoleID] = useState(null);
    const [role, setRole] = useState<any>(null);
    const [tenantAppData, setTenantAppData] = useState<any>([])
    const [activeKey, setActiveKey] = useState('1');

    useEffect(() => {
        var roleID = EUtils.getUrlParam('roleID');
        if (roleID) {
            setRoleID(roleID);
        }
    }, [])

    useEffect(() => {
        if (roleID) {
            loadUser();
        }
    }, [roleID])

    const loadUser = () => {
        var request = {
            roleID: roleID
        };
        Server.Post(URL.Role.GetRole, request).then((response: any) => {
            if (response.isSuccess) {
                setRole(response);
            }
        })
    }

    return <div >
        <div style={{ borderBottom: '1px solid #d9d9d9', height: '38px', verticalAlign: 'middle' }}>
            <span style={{ fontWeight: 'bold' }}>
                {'角色授权'}
            </span>
            <Space style={{ float: 'right' }}>
                <Button onClick={() => { window.history.back() }}>返回</Button>
            </Space>
        </div>
        <Card style={{ marginTop: '5px' }} type="inner" size="small" bodyStyle={{ padding: 0, margin: '0 5px' }}>
            <Descriptions column={2}>
                <Descriptions.Item label={'角色名称'}>{role?.roleName}</Descriptions.Item>
            </Descriptions>
        </Card>
        <Card style={{ marginTop: '5px' }} type="inner" size="small" bodyStyle={{ padding: 0, margin: '5px' }}>
            <Tabs size="small" items={[{
                label: '功能权限', key: '0',
                children: <RoleFunctionItem roleID={roleID} />
            }]} ></Tabs>
        </Card>
    </div>
}
export default RoleAuth;