import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import CustomerEdit from "../../masterdata/customer/CustomerEdit";
import SalesEdit from "../../masterdata/sales/SalesEdit";

const InquiryOrderEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;

    const [inquiryDate, setInquiryDate] = useState<any>(null);

    const [customer, setCustomer] = useState<any>(null);
    const [customerData, setCustomerData] = useState<any>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const [sales, setSales] = useState<any>(null);
    const [salesData, setSalesData] = useState<any>([]);

    const [isOpenSales, setIsOpenSales] = useState(false);
    const [editRecordSales, setEditRecordSales] = useState<any>({});

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getInquiryOrder();
                    break;
                case 2:
                    getInquiryOrder();
                    break;
            }
            loadCustomer();
            loadSales();
        }
    }, [rest.isOpen])

    const loadCustomer = () => {
        Server.Post(URL.Customer.GetCustomerOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCustomerData(response.data);
            }
        })
    }

    const loadSales = () => {
        Server.Post(URL.Sales.GetSalesOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setSalesData(response.data);
            }
        })
    }

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue({
                customer: { value: record.customerID, label: record.customerName },
                inquiryDate: record.inquiryDate,
                city: record.cityFullName,
                sales: { value: record.salesID, label: record.salesName },
                remark: record.remark,
            });
            setInquiryDate(moment(record.inquiryDate));
            setCustomer({ value: record.customerID, label: record.customerName });
            setSales({ value: record.salesID, label: record.salesName });
        }
        else {
            formRef.current?.resetFields();
            setInquiryDate(moment());
            setCustomer(null);
        }
    }

    const getInquiryOrder = () => {
        if (rest.inquiryOrderID) {
            var params = {
                inquiryOrderID: rest.inquiryOrderID
            };
            Server.Post(URL.InquiryOrder.GetInquiryOrder, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = {
                ...values,
                inquiryOrderID: props.inquiryOrderID,
                inquiryOrderNO: props.inquiryOrderNO ?? '',
                inquiryDate: moment(inquiryDate),
                inquiryMonth: moment(inquiryDate).format('M'),
                customerID: customer.value,
                customerName: customer.label,
                cityName: values.city ?? '',
                cityFullName: values.city ?? '',
                salesID: sales.value,
                salesName: sales.label,
                remark: values.remark ?? ''
            };

            Server.Post(URL.InquiryOrder.SaveInquiryOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "询价单"} width={600} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 10 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                flex:'110px'
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 20 }
            }}>
            <Form.Item hidden label={'询价单ID'} name={'inquiryOrderID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Row>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'投保客户'} name={'customer'}
                        initialValue={customer} rules={[{ required: true, message: '请选择投保客户' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Select defaultValue={customer?.value}
                                value={customer?.value}
                                labelInValue
                                placeholder={'选择投保客户'}
                                onSelect={(e) => {
                                    setCustomer(e);
                                    formRef.current?.setFieldValue('customer', e)
                                }}
                                style={{ width: '100%' }}
                                options={customerData}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption} />
                            <Button style={{ width: '50px', marginLeft: '5px' }}
                                onClick={() => {
                                    setEditRecord({
                                        viewMode: 0,
                                        customerID: null
                                    });
                                    setIsOpen(true)
                                }}><PlusOutlined /></Button>
                        </Space.Compact>
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'询价日期'} name={'inquiryDate'}
                        initialValue={moment()} rules={[{ required: true, message: '请选择询价日期' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <DatePicker style={{ width: '100%' }}
                                onChange={(value) => {
                                    setInquiryDate(value);
                                }}
                                defaultValue={moment()}
                                format="YYYY-MM-DD"
                                value={inquiryDate} />
                            <span style={{ width: '50px', margin: '5px' }}>
                                {(inquiryDate ? moment(inquiryDate).format('M') : moment().format('M')) + '月'}
                            </span>
                        </Space.Compact>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: '5px' }} label={'所在城市'} name={'city'}
                        initialValue={''} rules={[{ required: true, message: '请输入所在城市' }]}>
                        <Input placeholder={'输入所在城市'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        style={{ marginBottom: '5px' }} label='业务员' name={'sales'}
                        initialValue={sales}
                        rules={[{ required: true, message: '请选择业务员' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Select defaultValue={sales?.value}
                                value={sales?.value}
                                labelInValue
                                placeholder={'选择业务员'}
                                onSelect={(e) => {
                                    setSales(e);
                                    formRef.current?.setFieldValue('sales', e)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                style={{ width: '100%' }}
                                options={salesData} />
                            <Button style={{ width: '50px', marginLeft: '5px' }}
                                onClick={() => {
                                    setEditRecordSales({
                                        viewMode: 0,
                                        salesID: null
                                    });
                                    setIsOpenSales(true)
                                }}><PlusOutlined /></Button>
                        </Space.Compact>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        label={'备注'} name={'remark'} initialValue={''} >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CustomerEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadCustomer} {...editRecord} />
        <SalesEdit isOpen={isOpenSales} setIsOpen={() => setIsOpenSales(false)} loadData={loadSales} {...editRecordSales} />
    </Modal >
};
export default InquiryOrderEdit;
