import axios from 'axios';
import { Alert, message, Space, Spin } from 'antd';
import ReactDOM from 'react-dom';
import React from 'react';

// 配置请求头
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers.post['Content-Type'] = "application/json";
// 响应时间
axios.defaults.timeout = 100000;

// 获取缓存中的用户信息, 这是接口返回的信息。
let etokenKey = process.env.REACT_APP_TOKEN ?? '';
var eToken: any;
axios.interceptors.request.use((config: any) => {
    getUser();
    if (config.isLoading)
        showLoading();//显示加载动画
    if (eToken) {
        // 设置统一的请求header
        config.headers = {
            'EKnown.IMSToken': `${eToken}`,//授权(每次请求把token带给后台)
        }
    }
    return config;
},
    error => {
        hideLoading();//关闭加载动画
        return Promise.reject(error);
    })
//响应拦截器
axios.interceptors.response.use(
    response => {
        hideLoading();//关闭加载动画
        if (response.data.returnCode === '403') { // 登录失效
            localStorage.clear(); // 清除缓存
            setTimeout(() => {
                //让用户从新回到登录页面
                window.location.href = '/login';
            }, 100)
        }
        return response;
    },
    error => {
        hideLoading();//关闭加载动画
        return Promise.resolve(error.response);
    }
);

// 获取缓存中的用户信息, 这是接口返回的信息。
function getUser() {
    if (localStorage.getItem(etokenKey)) {
        eToken = localStorage.getItem(etokenKey);
    }
}

// 显示加载动画
function showLoading() {
    let dom = document.createElement('div')
    dom.setAttribute('id', 'loading')
    document.body.appendChild(dom)
    ReactDOM.render(<>
        <Alert
            type="info"
            banner
            showIcon={false}
            message={<>
                <Space size={'large'}>
                    <Spin size="large" />
                    <span>数据加载中,请耐心等待....</span>
                </Space>
            </>}
            description={<></>} />
    </>, dom)
}
// 隐藏加载动画
function hideLoading() {
    var loading = document.getElementById('loading');
    if (loading) {
        document.body.removeChild(loading)
    }
}

// 处理请求返回的数据
function checkStatus(response: any) {
    return new Promise((resolve) => {
        if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
            resolve(response.data);
        } else {
            message.success({
                content: '网络异常，请检查网络连接是否正常！',
                duration: 2
            });
        }
    });
}

export default {
    Post(url: string, params: object, isLoading = true) {
        var config = {
            method: "post",
            url,
            data: params,
            isLoading
        };
        return axios(config).then((response: any) => {
            return checkStatus(response);
        });
    }
}

