import { Button, Col, Input, Modal, Row, Space, Table, Tag, Tree } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";

const { Search } = Input;

const UserAuthRoleSelect = (props: any) => {
    const [treeData, setTreeData] = useState<any>([]);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [selectedUserBU, setSelectedUserBU] = useState<any>([]);

    const [dataSource, setDataSource] = useState<any>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [changeBURole, setChangeBURole] = useState<any>([]);

    const columns: ColumnsType<any> = [
        { title: '角色名称', dataIndex: 'roleName', key: 'roleName', align: 'left', width: 180, ellipsis: true },
    ]

    useEffect(() => {
        if (props.visible) {
            loadData();
            setChangeBURole([])
        }
    }, [props.visible])

    useEffect(() => {
        if (selectedUserBU) {
            loadRoleData();
        }
    }, [selectedUserBU])

    const loadRoleData = () => {
        var request = {};
        Server.Post(URL.Role.GetRoleList, request).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setSelectedRowKeys(selectedUserBU.roleIDs ?? []);
            }
        })
    }

    const onOK = () => {
        var keys = Object.keys(changeBURole);
        var userOrganizationRoles: any = []
        keys.map((key: any) => {
            userOrganizationRoles.push({
                organizationID: key,
                roleIDs: changeBURole[key]
            })
        })
        console.log(userOrganizationRoles);
        var request = {
            userID: props.userID,
            userOrganizationRoles: userOrganizationRoles
        }
        Server.Post(URL.User.AddUserOrganizationRole, request).then((response: any) => {
            if (response.isSuccess) {
                props.setVisible(false);
                props.reLoad();
            }
        })

    }

    const onCancel = () => {
        props.setVisible(false);
    }

    const loadData = () => {
        var request = {
            userID: props.userID
        };
        Server.Post(URL.User.GetUserOrganizationRoleList, request).then((response: any) => {
            if (response.isSuccess) {
                var treeData: any[] = [];
                (response.data ?? []).map((item: any, index: any) => {
                    var node = generateData(item);
                    treeData.push(node);
                })
                setTreeData(treeData);
                if (response.data && response.data.length > 0) {
                    setSelectedKeys([response.data[0].userOrganizationID])
                    setSelectedUserBU(response.data[0])
                } else {
                    setSelectedKeys(null)
                    setSelectedUserBU(null)
                }
            }
        })
    }

    const generateData = (item: any) => {
        var node: any = {
            title: item.levelName,
            key: item.userOrganizationID,
            record: item
        };
        return node;
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        changeBURole[selectedUserBU.organizationID] = newSelectedRowKeys;
        selectedUserBU.roleIDs = newSelectedRowKeys;
        var roleNames: any = []
        newSelectedRowKeys.map((item: any) => {
            var roleFilter = dataSource.filter((x: any) => x.roleID == item);
            if (roleFilter && roleFilter.length > 0) {
                roleNames.push(roleFilter[0].roleName);
            }
        })
        selectedUserBU.roleNames = roleNames;
        setSelectedUserBU(selectedUserBU);
        setSelectedRowKeys(newSelectedRowKeys);
        setChangeBURole(changeBURole);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return <Modal
        maskClosable={false}
        title={'分配角色'}
        width={900}
        visible={props.visible}
        onCancel={onCancel}
        footer={<>
            <Space wrap>
                <Button type="primary" onClick={onOK}>确定</Button>
                <Button onClick={onCancel}>取消</Button>
            </Space>
        </>}>
        <div style={{ border: '1px solid #e7e7eb', height: '400px' }}>
            <Row>
                <Col span={8}>
                    <div style={{ borderRight: '1px solid #e7e7eb', height: '400px' }}>
                        <div style={{ backgroundColor: '#fafafa', borderBottom: '1px solid #e7e7eb', padding: '5px' }}>
                            <span style={{ lineHeight: '32px', margin: '5px', fontWeight: 'bold' }}>任职组织</span>
                        </div>
                        <Tree
                            style={{ margin: '5px', overflowY: 'auto' }}
                            key={'organizationID'}
                            selectedKeys={selectedKeys}
                            onSelect={(selectedKeys: any, e: any) => {
                                if (selectedKeys.length > 0) {
                                    setSelectedUserBU(e.node.record);
                                    setSelectedKeys(selectedKeys);
                                }
                            }}
                            treeData={treeData} />
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ height: '100%', margin: '5px', width: 'calc(100% - 10px)' }}>
                        <div style={{
                            marginBottom: '5px',
                            border: '1px solid #e7e7eb',
                            minHeight: '30px',
                            width: '100%'
                        }}>
                            <Space style={{ padding: '5px', width: '100%' }} wrap>
                                {(selectedUserBU?.roleNames ?? []).map((items: any, index: number) => {
                                    return <Tag closable onClose={() => {
                                        var roleID = selectedUserBU.roleIDs[index];
                                        var filter = selectedRowKeys.filter((key: any) => key != roleID)
                                        setSelectedRowKeys(filter);
                                    }}>{items}</Tag>
                                })}
                            </Space>
                        </div>
                        <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                            <Table columns={columns} size={'small'}
                                rowKey={'roleID'}
                                bordered dataSource={dataSource}
                                rowSelection={{ ...rowSelection, checkStrictly: true }}
                                pagination={false}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </Modal>
}
export default UserAuthRoleSelect;